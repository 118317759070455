<template>
  <div>
    <GoogleMaps :uuid="uuid" @markerUpdated="updateForm" />
    <FormularioMaps :formData="formData" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import GoogleMaps from "@/components/Maps/GoogleMaps/GoogleMaps.vue";
import FormularioMaps from "@/components/Formulario/FormularioMaps.vue";

export default defineComponent({
  name: "GoogleMapsPage",
  components: {
    GoogleMaps,
    FormularioMaps,
  },
  setup() {
    const formData = ref({});
    const uuid = ref<string | null>(null);
    const route = useRoute();

    onMounted(() => {
      uuid.value = route.params.uuid as string;
    });

    const updateForm = (data) => {
      formData.value = data;
    };

    return {
      formData,
      updateForm,
      uuid,
    };
  },
});
</script>
