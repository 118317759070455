import control from "./apiService";
import LocalStorageValue from "./localStorageService";

export default class Quote {
    async ListClients() {
        const rol = await new LocalStorageValue().getRol();

        return await new control().fetchData(
            "/cotizador/cmc24/api/cmc_cotizador24/yetox/v1/listar-clientes",
            {
                "rol": rol[0].uuidRol,
            }
        );
    }

    async ListProducts() {
        return await new control().fetchData(
            "/cotizador/cmc24/api/cmc_cotizador24/yetox/v1/listar-productos",
        );
    }
    
    async ListPaymentMethods() {
        return await new control().fetchData(
            "/cotizador/cmc24/api/cmc_cotizador24/yetox/v1/listar-metodos-pago",
        );
    }
}




