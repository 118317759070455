<template>
    <DialogComponent v-model:visible="localVisible" :style="{ width: '35rem' }">
        <template #header>
            <div class="pt-20">
                <h5 class="card-subtitle fw-bold">Inspección</h5>
            </div>
        </template>
        <div class="row">


            <div class="mb-3 col-12">
                <label for="fechaInicio" class="form-label fw-medium">Creado por:</label>
                <input type="text" class="form-control" id="fechaInicio" v-model="inspeccionData.creador" readonly />
            </div>


            <div class="mb-3 col-6">
                <label for="fechaInicio" class="form-label fw-medium">Fecha Inicio:</label>
                <input type="text" class="form-control" id="fechaInicio" :value="formattedFecha" readonly />
            </div>

            <div class="mb-3 col-6">
                <label for="horaInicio" class="form-label fw-medium">Hora Inicio:</label>
                <input type="text" class="form-control" id="horaInicio" :value="formattedHora" readonly />
            </div>

            <div class="mb-3">
                <label for="razonSocial" class="form-label fw-medium">Cliente:</label>
                <input type="text" class="form-control" id="razonSocial" v-model="inspeccionData.cliente" readonly />
            </div>

            <div class="mb-3">
                <label for="localidad" class="form-label fw-medium">Localidad:</label>
                <input type="text" class="form-control" id="localidad" v-model="inspeccionData.localidad" readonly />
            </div>

            <div class="mb-3">
                <label for="giro" class="form-label fw-medium">Giro:</label>
                <input type="text" class="form-control" id="giro" v-model="inspeccionData.giro" />
            </div>

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Actividad:</label>
                <input type="text" class="form-control" id="cotizado" v-model="inspeccionData.actividad" readonly />
            </div>

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Segmento:</label>
                <input type="text" class="form-control" id="cotizado" v-model="inspeccionData.segmento" readonly />
            </div>

            <div class="mb-3">
                <label for="usuarios" class="form-label fw-medium">Usuarios:</label>
                <MultiSelect v-model="selectedUser" :options="usuarios" optionLabel="nombre" optionValue="uuid"
                    placeholder="Seleccione usuarios" filter :maxSelectedLabels="30" class="form-select-multi w-100" />
            </div>

            <div class="mb-3" v-if="inspeccionData.archivos?.length">
                <label class="form-label">Archivos adjuntos existentes</label>
                <ul class="list-group">
                    <li v-for="(file, index) in inspeccionData.archivos" :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center">
                        {{ file.nombre }}
                        <button type="button" class="btn btn-primary btn-sm" @click="openFile(file.path)">
                            Abrir
                        </button>
                    </li>
                </ul>
            </div>
            <div class="mb-3">
                <label for="eventFiles" class="form-label fw-medium">Adjuntar archivos:</label>
                <input type="file" class="form-control" id="eventFiles" multiple @change="handleFileUpload" />
            </div>
            <div class="mb-3" v-if="uploadedFiles.length">
                <label class="form-label">Archivos cargados</label>
                <ul class="list-group">
                    <li v-for="(file, index) in uploadedFiles" :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center">
                        {{ file.name }}
                        <div>
                            <button type="button" class="btn btn-danger btn-sm" @click="removeFile(index)">
                                Eliminar
                            </button>
                        </div>
                    </li>
                </ul>
            </div>



            <div class="mb-3">
                <label for="observacion" class="form-label fw-medium">Observación:</label>
                <textarea class="form-control" id="observacion" v-model="inspeccionData.observacion" rows="3"
                    readonly></textarea>
            </div>
            <div class="col-md-12 pt-20 text-end">
                <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">
                    Cerrar
                </button>
                <button type="button" class="btn btn-sm btn-primary text-center ms-2" @click="sendEdit">
                    Guardar Cambios
                </button>
            </div>
            <commentsSection :uuidRuteo="inspeccionData.uuid_ruteo" :visible="localVisible" />
        </div>

        <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    </DialogComponent>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, computed, onMounted } from "vue";
import { InspeccionData } from "@/interfaces/interfaces";
import { useToast } from "vue-toastification";
import createSocketService from "@/services/socketService";
import commentsSection from "./commentsSection.vue";

import MultiSelect from "primevue/multiselect";

export default defineComponent({
    name: "ModalContentEditInspeccion",
    components: { commentsSection, MultiSelect },
    props: {
        visible: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
        inspeccion: {
            type: Object as PropType<InspeccionData>,
            required: true,
        },
    },
    emits: ["close"],
    setup(props, { emit }) {
        const localVisible = ref(props.visible);
        const inspeccionData = ref({ ...props.inspeccion });
        const editInProgress = ref(false);
        const toast = useToast();
        const usuarios = ref([]);
        const selectedUser = ref<string[]>([]);
        const isLoading = ref(false);
        let socketService: any = null;
        const uploadedFiles = ref<File[]>([]);
        const filesPreview = ref<any[]>([]);


        const closeModal = () => {
            resetModalData();
            localVisible.value = false;
            emit("close");
        };


        const resetModalData = () => {
            uploadedFiles.value = [];
            filesPreview.value = [];
            inspeccionData.value = { ...props.inspeccion };
        };


        const openFile = (path: string) => {
            const baseUrl = "https://defaultappyetox.blob.core.windows.net/yetoxblob/";
            const fullUrl = `${baseUrl}${path}`;
            window.open(fullUrl, "_blank");
        };


        const handleFileUpload = (event: Event) => {
            const target = event.target as HTMLInputElement;
            if (!target.files) return;

            Array.from(target.files).forEach((file) => {
                if (!uploadedFiles.value.some((f) => f.name === file.name)) {
                    uploadedFiles.value.push(file);

                    const reader = new FileReader();
                    reader.onload = () => {
                        filesPreview.value.push({
                            tipo: file.type.startsWith("image/") ? "imagen" : "pdf",
                            nombre: file.name,
                            base64: reader.result as string,
                            contentType: file.type,
                        });
                    };
                    reader.readAsDataURL(file);
                }
            });

            target.value = "";
        };

        const removeFile = (index: number) => {
            uploadedFiles.value.splice(index, 1);
            filesPreview.value.splice(index, 1);
            toast.info("Archivo eliminado.");
        };


        const sendEdit = async () => {
            if (editInProgress.value) return;
            editInProgress.value = true;

            const payloadVenta = {
                uuid_ruteo: inspeccionData.value.uuid_ruteo,
                proyeccion: {
                    venta: inspeccionData.value.venta,
                },
                giro: inspeccionData.value.giro,
                souvenirPromocion: inspeccionData.value.souvenir_promocion,
                uuid_tipo_actividad: inspeccionData.value.uuid_tipo_actividad,
                fecha_fin: "",
                subir_archivo: filesPreview.value.map((archivo) => ({
                    base64: archivo.base64,
                    contentType: archivo.contentType,
                })),
                integrantes: selectedUser.value,


            };
            console.log(payloadVenta);

            try {
                socketService = await createSocketService();
                socketService.connect();
                isLoading.value = true;
                socketService.emit("edit-activity", payloadVenta);

                socketService.on("edit-activity", (response: any) => {
                    if (editInProgress.value) {
                        if (!response.error) {
                            toast.success("Tarea editada correctamente.");
                            resetModalData();
                            closeModal();
                        } else {
                            toast.error("Error al editar la tarea.");
                        }

                        editInProgress.value = false;
                        isLoading.value = false;
                    }
                });
            } catch (error) {
                toast.error("Error al procesar la edición.");
                console.error(error);
                editInProgress.value = false;
                isLoading.value = false;
            }
        };

        onMounted(async () => {
            try {
                socketService = await createSocketService();
                socketService.connect();
                socketService.emit("list-users");

                socketService.on("list-users", (response: any) => {
                    usuarios.value = response.response || [];

                    if (inspeccionData.value.integrantes?.length > 0) {
                        selectedUser.value = inspeccionData.value.integrantes[0].uuid;
                    } 
                });
            } catch (error) {
                toast.error("Error al cargar los usuarios.");
                console.error(error);
            }
        });

        watch(
            () => props.visible,
            (newVisible) => {
                localVisible.value = newVisible;
            },
            { immediate: true }
        );

        watch(
            () => props.inspeccion,
            (newData) => {
                inspeccionData.value = { ...newData };
                selectedUser.value = Array.isArray(newData.integrantes)
                    ? newData.integrantes.map((integrante) => integrante.uuid)
                    : [];

            },
            { immediate: true }
        );

        const formattedFecha = computed(() => {
            return inspeccionData.value.fecha_inicio.split("T")[0];
        });

        const formattedHora = computed(() => {
            return inspeccionData.value.fecha_inicio.split("T")[1].slice(0, 5);
        });

        return {
            localVisible,
            inspeccionData,
            closeModal,
            formattedFecha,
            formattedHora,
            sendEdit,
            isLoading,
            filesPreview,
            handleFileUpload,
            removeFile,
            uploadedFiles,
            openFile,
            usuarios,
            selectedUser,
        };
    },
});
</script>
