<template>
    <div class="pt-10 mb-3">
      <div class="input-group w-100">
        <label for="userDropdown" class="form-label fw-medium text-black">
          Ingrese el usuario que pertenece el archivo 
        </label>
        <Dropdown
          v-model="localSelectedUser"
          :options="userOptions"
          optionLabel="nombre"
          optionValue="uuid"
          placeholder="Seleccione un usuario"
          filter
          class="w-100"
          @change="updateUser"
        />
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, watch } from "vue";
  import Dropdown from "primevue/dropdown";
  import createSocketService from "@/services/socketService";
  
  export default defineComponent({
    name: "UserDropdown",
    components: { Dropdown },
    props: {
      modelValue: {
        type: String,
        required: true,
      },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
      const localSelectedUser = ref(props.modelValue);
      const userOptions = ref<Array<{ uuid: string; nombre: string }>>([]);
      let socketService: any = null;
  
      const fetchUsers = async () => {
        try {
          if (!socketService) {
            socketService = await createSocketService();
            socketService.connect();
          }
          socketService.emit("list-users");
          socketService.on("list-users", (response: any) => {
            userOptions.value = response.response || [];
          });
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      };
  
      const updateUser = (event: { value: string }) => {
        emit("update:modelValue", event.value); 
      };
  
      watch(
        () => props.modelValue,
        (newValue) => {
          localSelectedUser.value = newValue;
        }
      );
  
      onMounted(() => {
        fetchUsers();
      });
  
      return {
        localSelectedUser,
        userOptions,
        updateUser,
      };
    },
  });
  </script>
  
  <style scoped>
  .pt-10 {
    padding-top: 10px;
  }
  </style>
  