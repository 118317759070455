import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container py-5" }
const _hoisted_2 = {
  class: "card mx-auto shadow-sm",
  style: {"max-width":"700px"}
}
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "mb-4 text-center" }, "Configuración", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "card-title mb-3" }, "Lista de agente", -1)),
        _createElementVNode("form", null, [
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("label", {
              for: "accountSettings",
              class: "form-label"
            }, "Selecciona agentes asigandos", -1)),
            _createVNode(_component_MultiSelect, {
              modelValue: $setup.selectedPartner,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedPartner) = $event)),
                $setup.updateSelectedPartners
              ],
              options: $setup.data,
              optionLabel: "nombre",
              optionValue: "uuid",
              placeholder: "Seleccione usuarios",
              filter: true,
              multiple: true,
              class: "w-100"
            }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.saveSettings && $setup.saveSettings(...args)))
            }, "Guardar Cambios")
          ])
        ])
      ])
    ])
  ]))
}