import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "comments-container mb-3",
  ref: "commentsContainer"
}
const _hoisted_2 = {
  key: 0,
  class: "list-group"
}
const _hoisted_3 = {
  key: 1,
  class: "text-muted"
}
const _hoisted_4 = { class: "input-group" }
const _hoisted_5 = {
  key: 0,
  class: "spinner-border spinner-border-sm me-1",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label fw-medium" }, "Comentarios:", -1)),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.comments.length)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, (comment) => {
              return (_openBlock(), _createElementBlock("li", {
                key: comment.uuid,
                class: "list-group-item"
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("strong", null, _toDisplayString(comment.nombre), 1),
                  _createElementVNode("p", null, _toDisplayString(comment.comentario), 1)
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, "No hay comentarios disponibles."))
    ], 512),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        placeholder: "Escribe un comentario...",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newComment) = $event)),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.sendComment && _ctx.sendComment(...args)), ["enter"]))
      }, null, 544), [
        [_vModelText, _ctx.newComment]
      ]),
      _createElementVNode("button", {
        class: "btn btn-primary btn-sm d-flex align-items-center",
        type: "button",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sendComment && _ctx.sendComment(...args)))
      }, [
        (_ctx.isCommenting)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5))
          : _createCommentVNode("", true),
        (!_ctx.isCommenting)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Enviar"))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, "Enviando..."))
      ])
    ])
  ]))
}