import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-head box-shadow bg-white p-20 p-md-25 p-lg-30" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-md-3" }
const _hoisted_5 = { class: "input-group" }
const _hoisted_6 = { class: "col-md-3 mt-10" }
const _hoisted_7 = { class: "input-group" }
const _hoisted_8 = { class: "col-md-3 mt-10" }
const _hoisted_9 = { class: "input-group" }
const _hoisted_10 = { class: "col-md-3 mt-10" }
const _hoisted_11 = { class: "input-group" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "card mb-25 border-0 rounded-0 letter-spacing project-card h-100" }
const _hoisted_14 = { class: "card-body p-20 p-md-25 p-lg-30 d-flex flex-column justify-content-between" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-md-8" }
const _hoisted_17 = { class: "mb-0 fs-14 fs-md-16 fs-lg-20 fw-bold mt-8 mt-md-10" }
const _hoisted_18 = { class: "col-md-4 text-end" }
const _hoisted_19 = { class: "info ps-0 mb-15 mb-md-20 mb-lg-30 list-unstyled" }
const _hoisted_20 = {
  key: 0,
  class: "text-secondary fs-md-15 fs-md-16 fw-semibold"
}
const _hoisted_21 = { class: "text-secondary fs-md-15 fs-md-16 fw-semibold" }
const _hoisted_22 = { class: "text-secondary fs-md-15 fs-md-16 fw-semibold" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "text-left col-6" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "text-right col-6" }
const _hoisted_27 = { class: "text-secondary fs-md-15 fs-md-16 fw-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_PrimeCalendar = _resolveComponent("PrimeCalendar")!
  const _component_PrimeDropdown = _resolveComponent("PrimeDropdown")!
  const _component_ModalContentEdit = _resolveComponent("ModalContentEdit")!
  const _component_ModalContentEditEvent = _resolveComponent("ModalContentEditEvent")!
  const _component_ModalContentEditRuteo = _resolveComponent("ModalContentEditRuteo")!
  const _component_ModalContentEditVenta = _resolveComponent("ModalContentEditVenta")!
  const _component_ModalContentEditCobranza = _resolveComponent("ModalContentEditCobranza")!
  const _component_ModalContentEditVentCobra = _resolveComponent("ModalContentEditVentCobra")!
  const _component_ModalContentEditProspecccion = _resolveComponent("ModalContentEditProspecccion")!
  const _component_ModalContentEditVisitaSeguimiento = _resolveComponent("ModalContentEditVisitaSeguimiento")!
  const _component_ModalContentEditVisitaTecnica = _resolveComponent("ModalContentEditVisitaTecnica")!
  const _component_ModalContentEditVisitaReactivacion = _resolveComponent("ModalContentEditVisitaReactivacion")!
  const _component_ModalContentEditCapacitacion = _resolveComponent("ModalContentEditCapacitacion")!
  const _component_ModalContentEditEntregaProducto = _resolveComponent("ModalContentEditEntregaProducto")!
  const _component_ModalContentEditInspeccion = _resolveComponent("ModalContentEditInspeccion")!
  const _component_PrimePaginator = _resolveComponent("PrimePaginator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: 'Cargando...'
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_PrimeCalendar, {
                modelValue: _ctx.dates,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dates) = $event)),
                selectionMode: "range",
                placeholder: "Inserta un rango de fechas",
                class: "w-100 shadow-none text-black rounded-0 border-0",
                showIcon: true,
                appendTo: "body",
                dateFormat: "dd/mm/yy"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_PrimeDropdown, {
                modelValue: _ctx.selectedUser,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedUser) = $event)),
                options: _ctx.usuarios,
                optionLabel: "nombre",
                optionValue: "uusuario",
                placeholder: "Selecciona un usuario",
                checkmark: "",
                highlightOnSelect: false,
                showClear: true,
                class: "w-100"
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_PrimeDropdown, {
                modelValue: _ctx.selectedType,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedType) = $event)),
                options: _ctx.typeOptions,
                optionLabel: "name",
                placeholder: "Tipo de actividad",
                checkmark: "",
                highlightOnSelect: false,
                showClear: true,
                class: "w-100"
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[18] || (_cache[18] = _createElementVNode("span", { class: "input-group-text" }, [
                _createElementVNode("i", { class: "pi pi-search" })
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchTerm) = $event)),
                placeholder: "Buscar por titulo"
              }, null, 512), [
                [_vModelText, _ctx.searchTerm]
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredPaginatedTasks, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "col-md-6 col-xxl-6 col-xxxl-4 mb-4"
        }, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("h5", _hoisted_17, _toDisplayString(item.actividad) + " a " + _toDisplayString(item.cliente), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("span", {
                      class: "badge",
                      style: _normalizeStyle({ backgroundColor: item.color })
                    }, " - ", 4)
                  ])
                ]),
                _createElementVNode("ul", _hoisted_19, [
                  (item.giro)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_20, [
                        _cache[19] || (_cache[19] = _createElementVNode("span", { class: "text-muted fs-12 fs-md-13 text-uppercase fw-medium" }, " Descripción: ", -1)),
                        _createTextVNode(" " + _toDisplayString(item.giro), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("li", _hoisted_21, [
                    _cache[20] || (_cache[20] = _createElementVNode("span", { class: "text-muted fs-12 fs-md-13 text-uppercase fw-medium" }, " Responsable: ", -1)),
                    _createTextVNode(" " + _toDisplayString(item.nombre_creador), 1)
                  ]),
                  _createElementVNode("li", _hoisted_22, [
                    _cache[21] || (_cache[21] = _createElementVNode("span", { class: "text-muted fs-12 fs-md-13 text-uppercase fw-medium" }, " Fecha de inicio: ", -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.formatDate(item.fecha_inicio)), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-sm",
                    onClick: ($event: any) => (_ctx.handleCardClick(item))
                  }, " Ver más ", 8, _hoisted_25)
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("span", _hoisted_27, [
                    _createTextVNode(_toDisplayString(item.numero_comentario) + " ", 1),
                    _cache[22] || (_cache[22] = _createElementVNode("span", { class: "text-muted fs-12 fs-md-13 text-uppercase fw-medium" }, "comentario(s)", -1))
                  ])
                ])
              ])
            ])
          ])
        ]))
      }), 128))
    ]),
    _createVNode(_component_ModalContentEdit, {
      visible: _ctx.modalVisible,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalVisible) = $event)),
      task: _ctx.taskList,
      onClose: _ctx.closeModal
    }, null, 8, ["visible", "task", "onClose"]),
    _createVNode(_component_ModalContentEditEvent, {
      visible: _ctx.modalEventVisible,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalEventVisible) = $event)),
      event: _ctx.eventData,
      onClose: _ctx.closeEventModal
    }, null, 8, ["visible", "event", "onClose"]),
    _createVNode(_component_ModalContentEditRuteo, {
      visible: _ctx.modalRuteoVisible,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modalRuteoVisible) = $event)),
      ruteo: _ctx.ruteoData,
      onClose: _ctx.closeRuteoModal
    }, null, 8, ["visible", "ruteo", "onClose"]),
    _createVNode(_component_ModalContentEditVenta, {
      visible: _ctx.modalVentaVisible,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modalVentaVisible) = $event)),
      venta: _ctx.ventaData,
      onClose: _ctx.closeVentaModal
    }, null, 8, ["visible", "venta", "onClose"]),
    _createVNode(_component_ModalContentEditCobranza, {
      visible: _ctx.modalCobranzaVisible,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modalCobranzaVisible) = $event)),
      cobranza: _ctx.cobranzaData,
      onClose: _ctx.closeCobranzaModal
    }, null, 8, ["visible", "cobranza", "onClose"]),
    _createVNode(_component_ModalContentEditVentCobra, {
      visible: _ctx.modalVentCobraVisible,
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modalVentCobraVisible) = $event)),
      ventcobra: _ctx.ventacobraData,
      onClose: _ctx.closeVentCobraModal
    }, null, 8, ["visible", "ventcobra", "onClose"]),
    _createVNode(_component_ModalContentEditProspecccion, {
      visible: _ctx.modalProspeccionVisible,
      "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modalProspeccionVisible) = $event)),
      prospeccion: _ctx.prospeccionData,
      onClose: _ctx.closeProspeccionModal
    }, null, 8, ["visible", "prospeccion", "onClose"]),
    _createVNode(_component_ModalContentEditVisitaSeguimiento, {
      visible: _ctx.modalVisitaSeguimientoVisible,
      "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modalVisitaSeguimientoVisible) = $event)),
      visitaSeguimiento: _ctx.visitaSeguimientoData,
      onClose: _ctx.closeVisitaSeguimientoModal
    }, null, 8, ["visible", "visitaSeguimiento", "onClose"]),
    _createVNode(_component_ModalContentEditVisitaTecnica, {
      visible: _ctx.modalVisitaTecnicaVisible,
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.modalVisitaTecnicaVisible) = $event)),
      visitaTecnica: _ctx.visitaTecnicaData,
      onClose: _ctx.closeVisitaTecnicaModal
    }, null, 8, ["visible", "visitaTecnica", "onClose"]),
    _createVNode(_component_ModalContentEditVisitaReactivacion, {
      visible: _ctx.modalVisitaReactivacionVisible,
      "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.modalVisitaReactivacionVisible) = $event)),
      visitaReactivacion: _ctx.visitaReactivacionData,
      onClose: _ctx.closeVisitaReactivacionModal
    }, null, 8, ["visible", "visitaReactivacion", "onClose"]),
    _createVNode(_component_ModalContentEditCapacitacion, {
      visible: _ctx.modalCapacitacionVisible,
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.modalCapacitacionVisible) = $event)),
      capacitacion: _ctx.capacitacionData,
      onClose: _ctx.closeCapacitacionModal
    }, null, 8, ["visible", "capacitacion", "onClose"]),
    _createVNode(_component_ModalContentEditEntregaProducto, {
      visible: _ctx.modalEntregaProductoVisible,
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.modalEntregaProductoVisible) = $event)),
      entregaProducto: _ctx.entregaProductoData,
      onClose: _ctx.closeEntregaProductoModal
    }, null, 8, ["visible", "entregaProducto", "onClose"]),
    _createVNode(_component_ModalContentEditInspeccion, {
      visible: _ctx.modalInspeccionVisible,
      "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.modalInspeccionVisible) = $event)),
      inspeccion: _ctx.inspeccionData,
      onClose: _ctx.closeInspeccionModal
    }, null, 8, ["visible", "inspeccion", "onClose"]),
    (_ctx.filteredTasks.length > _ctx.rowsPerPage)
      ? (_openBlock(), _createBlock(_component_PrimePaginator, {
          key: 0,
          rows: _ctx.rowsPerPage,
          totalRecords: _ctx.filteredTasks.length,
          page: _ctx.currentPage,
          onPage: _cache[17] || (_cache[17] = ($event: any) => (_ctx.currentPage = $event.page))
        }, null, 8, ["rows", "totalRecords", "page"]))
      : _createCommentVNode("", true)
  ]))
}