import { io, Socket } from 'socket.io-client';
import loginService from './loginService';
import LocalStorageValue from "./localStorageService";
class SocketService {
  private socket!: Socket;
  private initialized: Promise<void>;

  constructor() {
    this.initialized = this.init();
  }

  // Método de inicialización asíncrona
  private async init() {
    await new loginService().isTokenExpired();

    const userKey = await new LocalStorageValue().getUserData();
    const token = await new LocalStorageValue().getToken();

    this.socket = io(process.env.VUE_APP_CMC_URL, {
      path: '/api/cmc/24/yetox/v1/real_time',
      auth: {
        apiKey: userKey,
        token: token,
      },
    });

    this.socket.on('connect', () => {
      console.log('Conectado a Socket.IO');
    });

    this.socket.on('connect_error', (error) => {
      console.error('Error de conexión:', error);
    });

    this.socket.on('disconnect', () => {
      console.log('Desconectado de Socket.IO');
    });
  }

  // Conectar al servidor (espera a que el socket esté inicializado)
  public async connect() {
    await this.initialized;
    if (this.socket) {
      this.socket.connect();
    }
  }

  // Escuchar un evento específico solo una vez (espera a que el socket esté inicializado)
public async once(event: string, callback: (data) => void) {
  await this.initialized;
  if (this.socket) {
    this.socket.once(event, callback);
  }
}


  // Emitir un evento (espera a que el socket esté inicializado)
  public async emit(event: string, data) {
    await this.initialized;
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  // Suscribirse a un evento (espera a que el socket esté inicializado)
  public async on(event: string, callback: (data) => void) {
    await this.initialized;
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }
}

let socketServiceInstance: SocketService | null = null;

const createSocketService = (): SocketService => {
  if (!socketServiceInstance) {
    socketServiceInstance = new SocketService();
  }
  return socketServiceInstance;
};

export default createSocketService;


