<template>
    <div class="container py-5">
        <h2 class="mb-4 text-center">Configuración</h2>
        <div class="card mx-auto shadow-sm" style="max-width: 700px;">
            <div class="card-body">
                <h5 class="card-title mb-3">Lista de agente</h5>
                <form>
                    <div class="mb-4">
                        <label for="accountSettings" class="form-label">Selecciona agentes asigandos</label>
                        <MultiSelect v-model="selectedPartner" :options="data" optionLabel="nombre" optionValue="uuid"
                            placeholder="Seleccione usuarios" :filter="true" :multiple="true"
                            @update:modelValue="updateSelectedPartners" class="w-100" />
                    </div>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary" @click="saveSettings">Guardar Cambios</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted } from 'vue';
import User from "@/services/userService";
import { useToast } from "vue-toastification";
import LocalStorageValue from "@/services/localStorageService";

export default {
    name: 'AccountSettings',
    setup() {
        const isLoading = ref(false);
        const data = ref<any[]>([]);
        const selectedPartner = ref("");
        const toast = useToast();
        const localStorageValue = new LocalStorageValue();

        const saveSettings = async () => {
            if (!selectedPartner.value || selectedPartner.value.length === 0) {
                toast.warning('Por favor seleccione al menos una opción antes de guardar.');
                return;
            }

            const response = await new User().saveUserAgentRelationship(selectedPartner.value, "");

            if (!response.error) {
                toast.success("Datos guardados correctamente");
            } else {
                toast.error("No se puedo guardar correctamente")
            }
        };

        const getAgents = async () => {
            try {
                const response = await new User().agentUserList();
                if (!response.error) {
                    data.value = response.datos
                }
            } catch (error) {
                console.log(error)
            }
        }

        const getSelectedAgents = async () => {
            try {
                const roles = await localStorageValue.getRol();
                const user = await localStorageValue.getUserData();

                const unicorol = Array.isArray(roles) && roles.length > 0 ? roles[0] : null;
                const data = {
                    uRol: unicorol?.uuidRol,
                    key: user,
                };

                const rspta = await new User().listUserPartnert(data);
                
                if (!rspta.error) {
                    selectedPartner.value = rspta.response.map((partner: any) => partner.uusuario);
                }
            } catch (error) {
                console.log(error)
            }
        }


        const updateSelectedPartners = (newPartherns) => {
            selectedPartner.value = newPartherns;
        };

        onMounted(async () => {
            try {
                isLoading.value = true;
                await getAgents();
                await getSelectedAgents();
            } catch (error) {
                console.log(error)
            } finally {
                isLoading.value = false;
            }
        })

        return {
            saveSettings,
            data,
            updateSelectedPartners,
            selectedPartner
        };
    },
};
</script>

<style>
/* Opcional: Ajustes para mejorar la presentación */
.card {
    border-radius: 10px;
}

.card-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.btn-primary {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.btn-primary:hover {
    background-color: #0b5ed7;
    border-color: #0a58ca;
}
</style>