<template>
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
            <h5 class="card-title fw-bold mb-15 mb-md-25">Registro Vehicular</h5>
            <div class="row">
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Numero:</label>
                    <InputText class="w-100" type="text" v-model="numero" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona un modelo:</label>
                    <Button type="button" label="Nuevo Marca" @click="visible = true"
                        class="btn btn-sm btn-primary"></Button>
                    <Button type="button" label="Nuevo Modelo" @click="visibleModelo = true"
                        class="btn btn-sm btn-secondary"></Button>
                    <Dropdown v-model="selectedModel" :options="modelos" optionLabel="modelo" optionValue="uuid"
                        placeholder="Seleccione un modelo" class="w-100" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Descripcion:</label>
                    <InputText class="w-100" type="text" v-model="descripcion" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Serie:</label>
                    <InputText class="w-100" type="text" v-model="serie" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Placas:</label>
                    <InputText class="w-100" type="text" v-model="placas" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Color:</label>
                    <InputText class="w-100" type="text" v-model="color" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Tarjeta de circulacion:</label>
                    <InputText class="w-100" type="text" v-model="circulacion" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Estado de Emision:</label>
                    <InputText class="w-100" type="text" v-model="emision" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Observaciones:</label>
                    <FloatLabel>
                        <Textarea v-model="observacion" rows="1" cols="30" class="w-100" />
                    </FloatLabel>
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona un propietario:</label>
                    <Dropdown v-model="selectedEmpresa" :options="empresas" optionLabel="empresa" optionValue="uuid"
                        placeholder="Selecciona un propietario" class="w-100" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona un ocupante:</label>
                    <Dropdown v-model="selectedOcupante" :options="empresas" optionLabel="empresa" optionValue="uuid"
                        placeholder="Selecciona un ocupante" class="w-100" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona un responsable:</label>
                    <Dropdown v-model="selectedResposable" :options="responsables" optionLabel="nombre"
                        optionValue="uuid_usuario" placeholder="Selecciona un responsable" class="w-100" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona una aseguradora:</label>
                    <Button type="button" label="Nueva Aseguradora" @click="visible = true"
                        class="btn btn-sm btn-primary"></Button>
                    <Dropdown v-model="selectedAseguradoras" :options="aseguradoras" optionLabel="aseguradora"
                        optionValue="uuid" placeholder="Selecciona una aseguradora" class="w-100" />
                </div>
                <div class="col-12 col-md-12 pt-3">
                    <button type="button" class="btn btn-md btn-primary me-2" @click="submitForm">Guardar</button>
                </div>
            </div>
        </div>
    </div>
    <Dialog v-model:visible="visible" modal header="Registra la nueva aseguradora" :style="{ width: '25rem' }">
        <div class="row">
            <label for="username" class="font-semibold w-30">Nombre</label>
            <div class="row">
                <InputText v-model="asegura" v class="pb-10" autocomplete="off" />
                <button type="button" @click="saveAse" class="btn btn-sm btn-primary ">Guardar</button>
            </div>
        </div>
    </Dialog>
    <Dialog v-model:visible="visible" modal header="Registra una nueva marca" :style="{ width: '25rem' }">
        <div class="row">
            <label for="username" class="font-semibold w-30">Nombre</label>
            <div class="row">
                <InputText v-model="marca" v class="pb-10" autocomplete="off" />
                <button type="button" @click="saveMarc" class="btn btn-sm btn-primary ">Guardar</button>
            </div>
        </div>
    </Dialog>
    <Dialog v-model:visible="visibleModelo" modal header="Registra un nuevo modelo" :style="{ width: '25rem' }">
        <div class="row mb-2">
            <label for="marca" class="font-semibold w-30">Selecciona una marca</label>
            <Dropdown v-model="selectedMarca" :options="marcas" optionLabel="marca" optionValue="uuid"
                placeholder="Seleccione una marca" class="w-100" />
        </div>
        <div class="row mb-2">
            <label for="modelo" class="font-semibold w-30">Nombre del Modelo</label>
            <InputText v-model="modelo" class="w-100" autocomplete="off" />
        </div>
        <button type="button" @click="saveModelo" class="btn btn-sm btn-primary mt-2">Guardar</button>
    </Dialog>

</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from "vue";
import Vehicles from "@/services/VehiclesService";
import { useToast } from "vue-toastification";
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import FloatLabel from 'primevue/floatlabel';
import Textarea from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';


export default defineComponent({
    name: "VehiculosRegis",
    components: { Dropdown, InputText, FloatLabel, Textarea, Dialog, Button },
    setup() {
        const visible = ref(false);
        const isLoading = ref<boolean>(false);
        const toast = useToast();
        const empresas = ref<any[]>([]);
        const numero = ref<string>("");
        const descripcion = ref<string>("");
        const serie = ref<string>("");
        const placas = ref<string>("");
        const circulacion = ref<string>("");
        const emision = ref<string>("");
        const color = ref<string>("");
        const observacion = ref<string>("");
        const selectedEmpresa = ref(null);
        const selectedOcupante = ref(null);
        const selectedAseguradoras = ref(null);
        const aseguradoras = ref<any[]>([]);
        const responsables = ref<any[]>([]);
        const selectedResposable = ref(null);
        const asegura = ref("");
        const marca = ref("");
        const modelos = ref([]);
        const marcas = ref([]);
        const selectedMarca = ref(null);
        const selectedModel = ref(null);
        const visibleMarca = ref(false);
        const visibleModelo = ref(false);
        const modelo = ref("");


        const getData = async () => {
            try {
                const response = await new Vehicles().ListVehicle();
                modelos.value = response.response;
            } catch (error) {
                toast.error("Error al cargar los modelos");
            }

            try {
                const response = await new Vehicles().ListEmpresa();
                empresas.value = response.response;
            } catch (error) {
                toast.error("Error al cargar los modelos");
            }

            try {
                const response = await new Vehicles().ListAseguradora();
                aseguradoras.value = response.response;
            } catch (error) {
                toast.error("Error al cargar las aseguradoras");
            }
            try {
                const dato = await new Vehicles().ListResponsable();
                responsables.value = dato.response;
                console.log("responsables", dato.value);
            } catch (error) {
                toast.error("Error al cargar los modelos");
            }
        };

        const saveAse = async () => {
            try {
                console.log("Valor de asegura:", asegura.value);
                const response = await new Vehicles().saveAsegu(asegura.value);
                console.log(asegura.value);

                if (!response.error) {
                    toast.success("aseguradora agregada con exito");
                    await getData();
                    asegura.value = "";
                } else {
                    toast.error("Error al agregar la aseguradora");
                }
            } catch (error) {
                console.error("imprirmir erro", error)
                toast.error("Ocurrió un error al intentar guardar la aseguradora");
            }
        }
        const saveMarc = async () => {
            isLoading.value = true;
            try {
                const response = await new Vehicles().saveMarca(marca.value);
                console.log(asegura.value);

                if (!response.error) {
                    toast.success("marca agregada con exito");
                    await getData();
                    await ListMarcas();
                    marca.value = "";
                    isLoading.value = false;
                } else {
                    toast.error("Error al agregar la marca");
                }
            } catch (error) {
                console.error("imprirmir erro", error)
                toast.error("Ocurrió un error al intentar guardar la aseguradora");
            }
        }
        const ListMarcas = async () => {
            try {
                const response = await new Vehicles().listMarc();
                if (!response.error) {
                    marcas.value = response.response;
                } else {
                    toast.error("Error al cargar las marcas");
                }
            } catch (error) {
                console.error("Error al cargar marcas:", error);
                toast.error("Error al cargar las marcas");
            }
        };

        const saveModelo = async () => {
            const data = {
                uuid_marca: selectedMarca.value,
                modelo: modelo.value
            };
            try {
                const response = await new Vehicles().saveModelo(data);
                if (!response.error) {
                    toast.success("Modelo agregado con éxito");
                    await getData();
                    modelo.value = "";
                    selectedMarca.value = null;
                    visibleModelo.value = false;
                } else {
                    toast.error("Error al agregar el modelo");
                }
            } catch (error) {
                console.error("Error al guardar modelo:", error);
                toast.error("Error al guardar el modelo");
            }
        };


        const submitForm = async () => {
            isLoading.value = true;
            try {
                const data = {
                    numero: numero.value,
                    uuid_modelo: selectedModel.value,
                    descripcion: descripcion.value,
                    serie: serie.value,
                    placas: placas.value,
                    color: color.value,
                    tarjeta_circulacion: circulacion.value,
                    emitida: emision.value,
                    observacion: observacion.value,
                    uuid_propietario: selectedEmpresa.value,
                    uuid_ocupante: selectedEmpresa.value,
                    uuid_aseguradora: selectedAseguradoras.value,
                    uuid_responsable: selectedResposable.value
                };
                console.log("Datos a enviar:", data);
                const response = await new Vehicles().SendVehicle(data);
                if (!response.error) {
                    toast.success("Usuario registrado correctamente");
                } else {
                    toast.error("Error en el registro del vehiculo.");
                }
            } catch (error) {
                console.error(error);
                toast.error("Error al guardar el formulario");
            } finally {
                isLoading.value = false;
            }
        };

        onMounted(async () => {
            await getData();
            await ListMarcas();
        });

        return {
            modelos,
            isLoading,
            submitForm,
            selectedModel,
            empresas,
            selectedEmpresa,
            selectedOcupante,
            selectedAseguradoras,
            aseguradoras,
            numero,
            descripcion,
            serie,
            placas,
            color,
            circulacion,
            emision,
            observacion,
            visible,
            selectedResposable,
            responsables,
            asegura,
            saveAse,
            marca,
            saveMarc,
            ListMarcas,
            saveModelo,
            visibleMarca,
            visibleModelo,
            selectedMarca,
            modelo,
            marcas,
        };
    },
});
</script>