import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-10 mb-3" }
const _hoisted_2 = { class: "input-group w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("label", {
        for: "userDropdown",
        class: "form-label fw-medium text-black"
      }, " Ingrese el usuario que pertenece el archivo ", -1)),
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.localSelectedUser,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localSelectedUser) = $event)),
        options: _ctx.userOptions,
        optionLabel: "nombre",
        optionValue: "uuid",
        placeholder: "Seleccione un usuario",
        filter: "",
        class: "w-100",
        onChange: _ctx.updateUser
      }, null, 8, ["modelValue", "options", "onChange"])
    ])
  ]))
}