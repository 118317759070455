<template>
    <DialogComponent v-model:visible="localVisible" :style="{ width: '35rem' }">
        <template #header>
            <div class="pt-20">
                <h5 class="card-subtitle fw-bold">Capacitación</h5>
            </div>
        </template>
        <div class="row">
            <div class="mb-3 col-12">
                <label for="fechaInicio" class="form-label fw-medium">Creado por:</label>
                <input type="text" class="form-control" id="fechaInicio" v-model="capacitacionData.creador" readonly />
            </div>

            <div class="mb-3 col-6">
                <label for="fechaInicio" class="form-label fw-medium">Fecha Inicio:</label>
                <input type="text" class="form-control" id="fechaInicio" :value="formattedFecha" readonly />
            </div>

            <div class="mb-3 col-6">
                <label for="horaInicio" class="form-label fw-medium">Hora Inicio:</label>
                <input type="text" class="form-control" id="horaInicio" :value="formattedHora" readonly />
            </div>

            <div class="mb-3">
                <label for="EndDate" class="form-label fw-medium">Fecha fin:</label>
                <input type="datetime-local" class="form-control" id="EndDate" v-model="capacitacionData.fecha_fin" />
            </div>


            <div class="mb-3">
                <label for="razonSocial" class="form-label fw-medium">Cliente:</label>
                <input type="text" class="form-control" id="razonSocial" v-model="capacitacionData.cliente" readonly />
            </div>

            <div class="mb-3">
                <label for="localidad" class="form-label fw-medium">Localidad:</label>
                <input type="text" class="form-control" id="localidad" v-model="capacitacionData.localidad" readonly />
            </div>

            <div class="mb-3">
                <label for="giro" class="form-label fw-medium">Giro:</label>
                <input type="text" class="form-control" id="giro" v-model="capacitacionData.giro" />
            </div>

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Actividad:</label>
                <input type="text" class="form-control" id="cotizado" v-model="capacitacionData.actividad" readonly />
            </div>

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Segmento:</label>
                <input type="text" class="form-control" id="cotizado" v-model="capacitacionData.segmento" readonly />
            </div>

            <div class="mb-3">
                <label for="usuarios" class="form-label fw-medium">Usuarios:</label>
                <MultiSelect v-model="selectedUser" :options="usuarios" optionLabel="nombre" optionValue="uuid"
                    placeholder="Seleccione usuarios" filter :maxSelectedLabels="30" class="form-select-multi w-100" />
            </div>


            <div class="mb-3" v-if="capacitacionData.archivos?.length">
                <label class="form-label">Archivos adjuntos existentes</label>
                <ul class="list-group">
                    <li v-for="(file, index) in capacitacionData.archivos" :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center">
                        {{ file.nombre }}
                        <button type="button" class="btn btn-primary btn-sm" @click="openFile(file.path)">
                            Abrir
                        </button>
                    </li>
                </ul>
            </div>


            <div class="mb-3">
                <label for="eventFiles" class="form-label fw-medium">Adjuntar archivos:</label>
                <input type="file" class="form-control" id="eventFiles" @change="handleFileUpload" />
            </div>
            <div class="mb-3" v-if="uploadedFiles.length">
                <label class="form-label">Archivos cargados</label>
                <ul class="list-group">
                    <li v-for="(file, index) in uploadedFiles" :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center">
                        {{ file.name }}
                        <div>
                            <button type="button" class="btn btn-danger btn-sm" @click="removeFile(index)">
                                Eliminar
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="mb-3">
                <label for="observacion" class="form-label fw-medium">Observación:</label>
                <textarea class="form-control" id="observacion" v-model="capacitacionData.observacion" rows="3"
                    readonly></textarea>
            </div>

            <div class="col-md-12 pt-20 text-end">
                <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">
                    Cerrar
                </button>
                <button type="button" class="btn btn-sm btn-primary text-center ms-2" @click="sendEdit">
                    Guardar Cambios
                </button>
            </div>

            <!-- Zona de Comentarios -->
            <div class="">
                <label class="form-label fw-medium">Comentarios:</label>
                <div class="comments-container mb-3" ref="commentsContainer">
                    <ul v-if="comments.length" class="list-group">
                        <li v-for="comment in comments" :key="comment.uuid" class="list-group-item">
                            <div>
                                <strong>{{ comment.nombre }}</strong>
                                <p>{{ comment.comentario }}</p>
                            </div>
                        </li>
                    </ul>
                    <p v-else class="text-muted">No hay comentarios disponibles.</p>
                </div>
            </div>


            <div class="input-group ">
                <input type="text" class="form-control" placeholder="Escribe un comentario..." v-model="newComment"
                    @keyup.enter="sendComment" />
                <button class="btn btn-primary btn-sm" type="button" @click="sendComment"
                    :disabled="isCommenting || !newComment.trim()">
                    Enviar
                </button>
            </div>
        </div>

        <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    </DialogComponent>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, computed, nextTick, onMounted } from "vue";
import { CapacitacionData } from "@/interfaces/interfaces";
import { useToast } from "vue-toastification";
import createSocketService from "@/services/socketService";
import { capacitacionData } from "./ConstReactivos";
import MultiSelect from "primevue/multiselect";

export default defineComponent({
    name: "ModalContentEditCapacitacion",
    components: { MultiSelect },
    props: {
        visible: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
        capacitacion: {
            type: Object as PropType<CapacitacionData>,
            required: true,
        },
    },
    emits: ["close"],
    setup(props, { emit }) {
        const localVisible = ref(props.visible);
        const capacitacionData = ref({ ...props.capacitacion });
        const editInProgress = ref(false);
        const isLoading = ref(false);
        const toast = useToast();
        let socketService: any = null;
        const uploadedFiles = ref<File[]>([]);
        const filesPreview = ref<any[]>([]);
        const comments = ref([]);
        const newComment = ref("");
        const isCommenting = ref(false);
        const commentsContainer = ref<HTMLElement | null>(null);
        const usuarios = ref([]);
        const selectedUser = ref<string[]>([]);

        const scrollToBottom = () => {
            if (commentsContainer.value) {
                commentsContainer.value.scrollTop = commentsContainer.value.scrollHeight;
            }
        };
        let isCommentAddListenerRegistered = false;


        const loadComments = async () => {
            try {
                socketService = await createSocketService();
                socketService.connect();
                const payload = { uuid_ruteo: capacitacionData.value.uuid_ruteo };
                console.log(payload);

                socketService.emit("list-comments-task", payload);

                socketService.on("list-comments-task", (response) => {
                    if (!response.error) {
                        comments.value = response.response || [];
                        nextTick(() => scrollToBottom());
                    } else {
                        toast.error("Error al cargar comentarios.");
                    }
                });
            } catch (error) {
                console.error(error);
                toast.error("Error al conectar con el servicio.");
            }
            nextTick(() => scrollToBottom());
           
        };


        const sendComment = async () => {
            if (!newComment.value.trim()) return;
            isCommenting.value = true;

            try {
                const payload = {
                    uuid_ruteo: capacitacionData.value.uuid_ruteo,
                    comentario: newComment.value.trim(),
                };

                // Emitir el evento
                socketService.emit("comment-add", payload);
                console.log(payload);

                // Verificar si el listener ya está registrado
                if (!isCommentAddListenerRegistered) {
                    isCommentAddListenerRegistered = true; // Marcar el listener como registrado

                    socketService.on("comment-add", (response) => {
                        if (!response.error) {
                            toast.success(response.message);
                            newComment.value = "";
                            nextTick(() => scrollToBottom());
                        } else {
                            toast.error("Error al agregar comentario.");
                        }
                        isCommenting.value = false;
                    });
                }
            } catch (error) {
                console.error(error);
                toast.error("Error al enviar el comentario.");
                isCommenting.value = false;
            }
        };

        onMounted(async () => {
            try {
                socketService = await createSocketService();
                socketService.connect();
                socketService.emit("list-users");

                socketService.on("list-users", (response: any) => {
                    usuarios.value = response.response || [];

                    if (capacitacionData.value.integrantes?.length > 0) {
                        selectedUser.value = capacitacionData.value.integrantes[0].uuid;
                    }
                });
            } catch (error) {
                toast.error("Error al cargar los usuarios.");
                console.error(error);
            }
        });


        const closeModal = () => {
            resetModalData();
            localVisible.value = false;
            emit("close");
        };
        const resetModalData = () => {
            uploadedFiles.value = [];
            filesPreview.value = [];
            capacitacionData.value = { ...props.capacitacion };
        };

        const formatToBackendDate = (datetime: string): string => {
            const date = new Date(datetime);
            if (isNaN(date.getTime())) return "";
            const isoString = date.toISOString();
            return isoString.replace("T", " ").slice(0, -1);
        };

        const formatToDatetimeLocal = (isoString: string): string => {
            if (!isoString) return "";
            const date = new Date(isoString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        };
        const handleFileUpload = (event: Event) => {
            const target = event.target as HTMLInputElement;
            if (!target.files) return;

            Array.from(target.files).forEach((file) => {
                if (!uploadedFiles.value.some((f) => f.name === file.name)) {
                    uploadedFiles.value.push(file);

                    const reader = new FileReader();
                    reader.onload = () => {
                        filesPreview.value.push({
                            tipo: file.type.startsWith("image/") ? "imagen" : "pdf",
                            nombre: file.name,
                            base64: reader.result as string,
                            contentType: file.type,
                        });
                    };
                    reader.readAsDataURL(file);
                }
            });

            target.value = "";
        };

        const removeFile = (index: number) => {
            uploadedFiles.value.splice(index, 1);
            filesPreview.value.splice(index, 1);
            toast.info("Archivo eliminado.");
        };

        const openFile = (path: string) => {
            const baseUrl = "https://defaultappyetox.blob.core.windows.net/yetoxblob/";
            const fullUrl = `${baseUrl}${path}`;
            window.open(fullUrl, "_blank");
        };



        const sendEdit = async () => {
            if (editInProgress.value) return;
            editInProgress.value = true;

            const payloadVenta = {
                uuid_ruteo: capacitacionData.value.uuid_ruteo,
                proyeccion: {
                    venta: capacitacionData.value.venta,
                },
                giro: capacitacionData.value.giro,
                souvenirPromocion: capacitacionData.value.souvenir_promocion,
                uuid_tipo_actividad: capacitacionData.value.uuid_tipo_actividad,
                fecha_fin: capacitacionData.value.fecha_fin
                    ? formatToBackendDate(capacitacionData.value.fecha_fin)
                    : null,
                subir_archivo: filesPreview.value.map((archivo) => ({
                    base64: archivo.base64,
                    contentType: archivo.contentType,
                })),
                integrantes: selectedUser.value,
            };
            console.log(payloadVenta);

            try {
                socketService = await createSocketService();
                socketService.connect();
                isLoading.value = true;
                socketService.emit("edit-activity", payloadVenta);

                socketService.on("edit-activity", (response: any) => {
                    if (editInProgress.value) {
                        if (!response.error) {
                            toast.success("Tarea editada correctamente.");
                            resetModalData();
                            closeModal();
                        } else {
                            toast.error("Error al editar la tarea.");
                        }

                        editInProgress.value = false;
                        isLoading.value = false;
                    }
                });
            } catch (error) {
                toast.error("Error al procesar la edición.");
                console.error(error);
                editInProgress.value = false;
                isLoading.value = false;
            }
        };

        watch(
            () => props.visible,
            (newVisible) => {
                localVisible.value = newVisible;
                nextTick(() => scrollToBottom());
                loadComments();
            },
            { immediate: true }
        );

        watch(
            () => props.capacitacion,
            (newData) => {
                capacitacionData.value = { ...newData };
                capacitacionData.value.fecha_fin = newData.fecha_fin
                    ? formatToDatetimeLocal(newData.fecha_fin)
                    : "";
                selectedUser.value = Array.isArray(newData.integrantes)
                    ? newData.integrantes.map((integrante) => integrante.uuid)
                    : [];

            },
            { immediate: true }
        );

        const formattedFecha = computed(() => {
            return capacitacionData.value.fecha_inicio.split("T")[0];
        });

        const formattedHora = computed(() => {
            return capacitacionData.value.fecha_inicio.split("T")[1].slice(0, 5);
        });

        return {
            localVisible,
            capacitacionData,
            closeModal,
            formattedFecha,
            formattedHora,
            sendEdit,
            isLoading,
            filesPreview,
            handleFileUpload,
            removeFile,
            uploadedFiles,
            openFile,
            comments,
            newComment,
            sendComment,
            isCommenting,
            commentsContainer,
            usuarios,
            selectedUser,
        };
    },
});
</script>
<style>
.comments-container {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 1px;
    border-radius: 5px;
}

.comments-container .list-group-item {
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
}
</style>
