import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25" }
const _hoisted_3 = { class: "col-md-6 d-flex justify-content-center" }
const _hoisted_4 = { class: "input-group" }
const _hoisted_5 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_6 = { class: "search mb-3" }
const _hoisted_7 = { class: "table-responsive" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_PrimeCalendar = _resolveComponent("PrimeCalendar")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: 'Cargando...'
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "text-left col-md-3" }, "Facturas", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_PrimeCalendar, {
              modelValue: _ctx.dates,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dates) = $event)),
              selectionMode: "range",
              placeholder: "Inserta un rango de fechas",
              class: "w-100 shadow-none text-black rounded-0 border-0",
              showIcon: true,
              appendTo: "body",
              dateFormat: "dd/mm/yy"
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_InputText, {
            modelValue: _ctx.searchTerm,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchTerm) = $event)),
            placeholder: "Buscar por folio, cliente o monto",
            class: "w-100"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_DataTable, {
            value: _ctx.filteredInventory,
            showGridlines: "",
            tableStyle: "min-width: 50rem",
            paginator: "",
            rows: 10,
            rowsPerPageOptions: [5, 10, 20, 50]
          }, {
            footer: _withCtx(() => [
              _createTextVNode(" Total " + _toDisplayString(_ctx.inventoryData ? _ctx.inventoryData.length : 0), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                class: "column-table",
                header: "Fecha",
                style: {"width":"10%"}
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(slotProps.data.fechatimbre
                                ? _ctx.formatFecha(slotProps.data.fechatimbre)
                                : "Sin fecha"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                class: "column-table",
                sortable: "",
                field: "factura",
                header: "Factura",
                style: {"width":"10%"}
              }),
              _createVNode(_component_Column, {
                class: "column-table",
                sortable: "",
                field: "razon_social",
                header: "Cliente",
                style: {"width":"20%"}
              }),
              _createVNode(_component_Column, {
                class: "column-table",
                sortable: "",
                field: "nombreagente",
                header: "Agente",
                style: {"width":"20%"}
              }),
              _createVNode(_component_Column, {
                class: "column-table",
                sortable: "",
                field: "totalfactura",
                header: "Total",
                style: {"width":"20%"}
              }),
              _createVNode(_component_Column, {
                class: "column-table",
                sortable: "",
                field: "forma_pago",
                header: "Forma de pago",
                style: {"width":"20%"}
              }),
              _createVNode(_component_Column, {
                class: "column-table",
                sortable: "",
                field: "folio_rep",
                header: "REP",
                style: {"width":"20%"}
              }),
              _createVNode(_component_Column, {
                class: "column-table",
                field: "observacion",
                header: "Observación",
                style: {"width":"20%"}
              })
            ]),
            _: 1
          }, 8, ["value"])
        ])
      ]),
      _createVNode(_component_ConfirmDialog)
    ])
  ], 64))
}