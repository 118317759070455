<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing p-4">
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-25">Actualizar información</h5>
      <div class="row">

        <div class="col-12 col-md-6 mb-3 ">
          <label for="taskTags" class="form-label fw-medium">Nombre del establecimiento:</label>
          <InputText class="w-100 column-table" type="text" v-model="form.nombre_establecimiento" />
        </div>

        <div class="col-md-6 col-md-6 mb-3">
          <div class="input-group has-validation">
            <label for="segmentoDropdown" class="form-label fw-medium text-black">
              Nivel de cliente (*)
            </label>
            <Dropdown v-model="selectedNivelCliente" :options="nivelesCliente" optionLabel="descripcion"
              placeholder="Seleccione un nivel del cliente" filter class="w-100 column-table" />
          </div>
        </div>

        <div class="col-md-6 col-md-6 mb-3">
          <div class="input-group has-validation">
            <label for="segmentoDropdown" class="form-label fw-medium text-black">
              Agente (*)
            </label>
            <Dropdown v-model="selectedAgente" :options="agentes" optionLabel="nombre_agente_cq"
              placeholder="Seleccione un agente" filter class="w-100" />
          </div>
        </div>

        <div class="col-12 col-md-6 mb-3" v-show="visible">
          <label for="taskTags" class="form-label fw-medium">Razón social: (*)</label>
          <Dropdown v-model="selectedCompany" :options="company" optionLabel="razon_social"
            placeholder="Selecciona un razón social" filter class="w-100" />
        </div>


        <div class="col-12 col-md-6 mb-3" v-show="visible">
          <label for="taskTags" class="form-label fw-medium">Frecuencia de compra</label>
          <Dropdown v-model="selectedFrecuenciaCompra" :options="frecuenciasCompra" optionLabel="descripcion"
            placeholder="Selecciona frecuencia de compra" filter class="w-100"
            @update:model-value="onNivelClienteChange" />
        </div>

        <div class="col-12 col-md-6 mb-3" v-show="visible">
          <label for="taskTags" class="form-label fw-medium">Forma de pago</label>
          <Dropdown v-model="selectedFormaPago" :options="formasPago" optionLabel="nombre"
            placeholder="Selecciona forma de pago" filter class="w-100" @update:model-value="onFormaPagoChange" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Correo electrónico</label>
          <InputText class="w-100 column-table" type="text" v-model="form.correo_electronico" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Teléfono</label>
          <InputText class="w-100 column-table" type="text" v-model="form.telefono" />
        </div>


        <div class="col-12 col-md-6 mb-3" v-show="visible">
          <label for="taskTags" class="form-label fw-medium">RFC</label>
          <InputText class="w-100 column-table" type="text" v-model="form.rfc" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Giro comercial</label>
          <InputText class="w-100 column-table" type="text" v-model="form.giro_comercial" />
        </div>


        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Tipo de Segmento</label>
          <Dropdown v-model="selectedClasificacionCliente" :options="clasificacionesCliente" optionLabel="nombre"
            placeholder="Segmento" filter class="w-100" @update:model-value="onTipoSegmentoChange" />

        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Nombre de contacto</label>
          <InputText class="w-100 column-table" type="text" v-model="form.contacto_nombre" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Teléfono de contacto</label>
          <InputText class="w-100" type="text" v-model="form.contacto_telefono" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Calle 1</label>
          <InputText class="w-100 column-table" type="text" v-model="form.calle1" />
        </div>


        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Calle 2</label>
          <InputText class="w-100 column-table" type="text" v-model="form.calle2" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Número interior</label>
          <InputText class="w-100 column-table" type="text" v-model="form.numero_int" />
        </div>


        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Número exterior</label>
          <InputText class="w-100 column-table" type="text" v-model="form.numero_ext" />
        </div>


        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Colonia</label>
          <InputText class="w-100 column-table" type="text" v-model="form.colonia" />
        </div>


        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Código Postal</label>
          <InputText class="w-100 column-table" type="text" v-model="form.cp" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Estado</label>
          <InputText class="w-100 column-table" type="text" v-model="form.estado" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Municipio</label>
          <InputText class="w-100 column-table" type="text" v-model="form.municipio" />
        </div>


        <div class="col-12 col-md-12 mb-3">
          <button @click="submitForm" type="button" class="btn btn-primary PT-4 text-center">
            Actualizar
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from "vue";
import { useToast } from "vue-toastification";
import control from "@/services/mapService";
// import Multiselect from "vue-multiselect";
import Dropdown from "primevue/dropdown";
import InputText from 'primevue/inputtext';
import Actualizar from '@/services/actualizarService';
import { CompanyNameItem } from "@/interfaces/interfaces";

export default defineComponent({
  name: "FormularioMaps",
  components: {
    // Multiselect, 
    Dropdown,
    InputText
  },
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const form = ref({
      nombre_establecimiento: "",
      vendedor: "",
      razon_social: "",
      telefono: "",
      correo_electronico: "",
      rfc: "",
      giro_comercial: "",
      contacto_nombre: "",
      contacto_telefono: "",
      longitud: "",
      latitud: "",
      calle1: "",
      calle2: "",
      numero_ext: "",
      numero_int: "",
      colonia: "",
      uniclave: "",
      cp: "",
      estado: "",
      municipio: "",
      uuid_vendedor: "",
      uuid_nivel_cliente: "",
      uuid_clasificacion_cliente: "",
      uuid_forma_pago: "",
      uuid_estado: "",
      uuid_frecuencia_compra: "",
      uuidrazon: "",
    });

    const frecuenciasCompra = ref<Array<{ uuid: string; descripcion: string }>>([]);
    const selectedFrecuenciaCompra = ref<{ uuid: string; descripcion: string } | null>(null);
    const nivelesCliente = ref<Array<{ uuid: string; descripcion: string }>>([]);
    const selectedNivelCliente = ref<{ uuid: string; descripcion: string } | null>(null);
    const selectedAgente = ref<{ uuid: string; nombre: string } | null>(null);
    const clasificacionesCliente = ref<Array<{ uuid: string; nombre: string }>>([]);
    const agentes = ref<Array<{ uuid: string; nombre: string }>>([]);
    const selectedClasificacionCliente = ref<{ uuid: string; nombre: string } | null>(null);
    const formasPago = ref<Array<{ uuid: string; nombre: string }>>([]);
    const selectedFormaPago = ref<{ uuid: string; nombre: string } | null>(null);
    const selectedCompany = ref<CompanyNameItem | null>(null);
    const states = ref<Array<{ uuid: string; nombre: string }>>([]);
    const geocoder = ref<google.maps.Geocoder | null>(null);
    const isLoading = ref(false);
    const toast = useToast();
    const visible = ref(false);
    const company = ref([]);



    const getCompanyName = async () => {
      try {
        const rspta = await new Actualizar().ListCompanyName();
        if (!rspta.error && Array.isArray(rspta.response)) {
          company.value = rspta.response;
        } else {
          console.error("La respuesta no contiene datos válidos:", rspta);
          company.value = [];
        }
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      }
    };

    const getNivelesCliente = async () => {
      try {
        const response = await new control().ListLevelClient();
        nivelesCliente.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar los niveles de cliente.");
      }
    };


    const getStateList = async () => {
      try {
        const response = await new control().stateList();
        states.value = response.datos;
      } catch (error) {
        console.error("Error al obtener los estados:", error);
        toast.error("Error al cargar la lista de estados");
      }
    };

    const getStateFromCoordinates = async (lat: number, lng: number) => {
      if (!geocoder.value) {
        geocoder.value = new google.maps.Geocoder();
      }

      geocoder.value.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results && results[0]) {
          const addressComponents = results[0].address_components;
          const stateComponent = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          );

          if (stateComponent) {
            const stateName = stateComponent.long_name;
            form.value.estado = stateName;

            const foundState = states.value.find(
              (state) => state.nombre === stateName
            );

            if (foundState) {
              form.value.uuid_estado = foundState.uuid;
            }
          }
        } else {
          toast.error("No se pudo obtener la información del estado.");
        }
      });
    };

    const onNivelClienteChange = (newValue) => {
      form.value.uuid_frecuencia_compra = newValue.uuid;
    };

    const onFormaPagoChange = (newValue) => {
      form.value.uuid_forma_pago = newValue.uuid;
    };

    const onTipoSegmentoChange = (newValue) => {
      form.value.uuid_clasificacion_cliente = newValue.uuid;
    };

    const visibleFields = async (uuid) => {
      const visibleUUIDs = [
        '48aaf084-1730-49e4-a628-2a7bb056c2d5',
        'd2f9f31a-d665-40a6-b02b-e8b60aa2c3a9',
      ];

      const hiddenUUIDs = ['6ed7a912-cf0b-4872-ad1c-1fa7b9d36ce7'];

      if (visibleUUIDs.includes(uuid)) {
        visible.value = true;
      } else if (hiddenUUIDs.includes(uuid)) {
        visible.value = false;
      }
    }



    const getAgente = async () => {
      try {
        const response = await new control().agentList();
        agentes.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar las clasificaciones de cliente.");
      }
    };

    const getClasificacionesCliente = async () => {
      try {
        const response = await new control().ListClasifiClient();
        clasificacionesCliente.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar las clasificaciones de cliente.");
      }
    };

    const getFormasPago = async () => {
      try {
        const response = await new control().formaPagos();
        formasPago.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar las formas de pago.");
      }
    };

    const getFrecuenciasCompra = async () => {
      try {
        const response = await new control().frecuenciaCompra();
        frecuenciasCompra.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar las frecuencias de compra.");
      }
    };


    const submitForm = async () => {
      try {
        isLoading.value = true;

        const payload = {
          ...form.value
        };

        const response = await new control().updateClient(payload);
        if (!response.error) {
          toast.success("Información actualizada correctamente");
        } else {
          toast.error(`Error al actualizar la información`);
        }
      } catch (error) {
        toast.error(`Ocurrió un error al enviar el formulario`);
      } finally {
        isLoading.value = false;
      }
    };

    watch(
      () => props.formData,
      (newFormData, oldFormData) => {
        if (JSON.stringify(newFormData) !== JSON.stringify(oldFormData)) {
          form.value = {
            ...form.value,
            ...newFormData,
          };
        }
      },
      { deep: true, immediate: true }
    );


    watch(
      () => [form.value.latitud, form.value.longitud],
      async ([newLat, newLong]) => {
        if (newLat && newLong) {
          const lat = typeof newLat === 'string' ? parseFloat(newLat) : newLat;
          const lng = typeof newLong === 'string' ? parseFloat(newLong) : newLong;

          if (!isNaN(lat) && !isNaN(lng)) {
            await getStateFromCoordinates(lat, lng);
          }
        }
      },
      { immediate: true }
    );


    watch(
      () => [form.value.latitud, form.value.longitud],
      async ([newLat, newLong]) => {
        if (newLat && newLong) {
          await getStateList();
          const foundState = states.value.find(
            (state) => state.nombre === form.value.estado
          );
          form.value.uuid_estado = foundState ? foundState.uuid : "";
        }
      },
      { immediate: true }
    );

    watch(
      () => [clasificacionesCliente.value, formasPago.value, frecuenciasCompra.value, agentes.value, company.value, nivelesCliente.value],
      ([newClasificaciones, newFormasPago, newFrecuencias, newAgentes, newCompany, newNiveles]) => {

        if (newClasificaciones.length > 0 && props.formData.uuid_clasificacion_cliente) {
          const clasificacionesTyped = newClasificaciones as Array<{ uuid: string; nombre: string }>;

          selectedClasificacionCliente.value =
            clasificacionesTyped.find((clasificacion) => clasificacion.uuid === props.formData.uuid_clasificacion_cliente) || null;

        }


        if (newFormasPago.length > 0 && props.formData.uuid_forma_pago) {
          const formasPagoTyped = newFormasPago as Array<{ uuid: string; nombre: string }>;
          selectedFormaPago.value = formasPagoTyped.find(
            (pago) => pago.uuid === props.formData.uuid_forma_pago
          ) || null;
        }

        if (newFrecuencias.length > 0 && props.formData.uuid_frecuencia_compra) {
          const frecuenciasTyped = newFrecuencias as Array<{ uuid: string; descripcion: string }>;

          selectedFrecuenciaCompra.value = frecuenciasTyped.find(
            (frecuencia) => frecuencia.uuid === props.formData.uuid_frecuencia_compra
          ) || null;
        }

        if (newCompany.length > 0 && props.formData.uuidrazon) {
          const frecuenciasTyped = newCompany as Array<CompanyNameItem>;
          selectedCompany.value = frecuenciasTyped.find(
            (frecuencia) => frecuencia.uuid === props.formData.uuidrazon
          ) || null;
        }


        if (newNiveles.length > 0 && props.formData.uuid_nivel_cliente) {
          const nivelesTyped = newNiveles as Array<{ uuid: string; descripcion: string }>;

          selectedNivelCliente.value = nivelesTyped.find(
            (nivel) => nivel.uuid === props.formData.uuid_nivel_cliente
          ) || null;

          visibleFields(selectedNivelCliente.value?.uuid);
        }

        if (newAgentes.length > 0 && props.formData.uuid_vendedor) {
          const agentesTyped = newAgentes as Array<{ uuid: string; nombre: string }>;
          selectedAgente.value = agentesTyped.find(
            (agente) => agente.uuid === props.formData.uuid_vendedor
          ) || null;
        }
      },
      { immediate: true }
    );

    watch(
      () => selectedNivelCliente.value,
      async (newValue) => {
        if (newValue) {
          form.value.uuid_nivel_cliente = newValue.uuid;

          await visibleFields(newValue.uuid);
        }
      },
      { immediate: true }
    );



    onMounted(async () => {
      try {
        isLoading.value = true;
        await getNivelesCliente();
        await getAgente();
        await getClasificacionesCliente();
        await getFormasPago();
        await getFrecuenciasCompra();
        await getCompanyName();
        if (!geocoder.value) {
          geocoder.value = new google.maps.Geocoder();
        }
      } catch (error) {
        console.log("ocurrio un error al cargar el listado", error)
      } finally {
        isLoading.value = false;
      }


    });

    return {
      form,
      submitForm,
      nivelesCliente,
      agentes,
      clasificacionesCliente,
      formasPago,
      frecuenciasCompra,
      selectedAgente,
      selectedNivelCliente,
      selectedClasificacionCliente,
      selectedFormaPago,
      selectedFrecuenciaCompra,
      isLoading,
      visible,
      company,
      onNivelClienteChange,
      onFormaPagoChange,
      onTipoSegmentoChange,
      selectedCompany

    };
  }
});
</script>
