<template>
  <DialogComponent v-model:visible="localVisible" :style="{ width: '37rem' }">
    <template #header>
      <div class="col-md-6 pt-20">
        <h5 class="card-subtitle fw-bold">Eventos</h5>
      </div>
    </template>

    <div>
      <div class="tabs">
        <button class="text-muted tab" :class="{ active: activeTab === 'archivo' }"
          @click="activeTab = 'archivo'">Cargar
          Archivo
        </button>
        <!-- <button class="text-muted tab" :class="{ active: activeTab === 'nuevoTab' }" @click="activeTab = 'nuevoTab'">
          agregar evento
        </button> -->
        <!-- <button class="text-muted tab" :class="{ active: activeTab === 'tareas' }" @click="activeTab = 'tareas'">Tareas
        </button>
        <button class="text-muted tab" :class="{ active: activeTab === 'eventos' }" @click="activeTab = 'eventos'">Eventos
        </button> -->
      </div>

      <div class="tab-content" v-show="activeTab === 'tareas'">
        <div class="row">
          <div class="mb-3">
            <label for="taskTitle" class="form-label fw-medium">Título de la tarea:</label>
            <input type="text" class="form-control shadow-none" id="taskTitle" v-model="newEvent.title" />
          </div>

          <div class="mb-3">
            <label for="taskDescription" class="form-label fw-medium">Descripción:</label>
            <textarea class="form-control" id="taskDescription" v-model="newEvent.description" rows="3"></textarea>
          </div>

          <div class="mb-3">
            <label for="taskStartTime" class="form-label fw-medium">Fecha y Hora de inicio:</label>
            <input type="datetime-local" class="form-control" id="taskStartTime" v-model="newEvent.startTime" />
          </div>

          <div class="mb-3">
            <label for="taskEndTime" class="form-label fw-medium">Fecha y Hora de finalización:</label>
            <input type="datetime-local" class="form-control" id="taskEndTime" v-model="newEvent.endTime" />
          </div>

          <div class="mb-3">
            <label class="form-label fw-medium">Progreso de actividades:</label>
            <div class="progress mb-3">
              <div class="progress-bar" role="progressbar" :style="{ width: `${progress}%` }">
                {{ progress.toFixed(0) }}%
              </div>
            </div>

            <label class="form-label fw-medium">Checklist de actividades:</label>
            <ul class="list-group">
              <li v-for="(item, index) in newActivity.checklist" :key="index" class="list-group-item">
                <div class="d-flex align-items-start">
                  <input type="checkbox" v-model="item.check" />
                  <textarea class="form-control ms-2 flex-grow-1" v-model="item.nombre"
                    placeholder="Escribe una tarea..." rows="2"></textarea>
                  <button class="btn btn-danger btn-sm ms-2" @click="removeChecklistItem(index)">Eliminar</button>
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <div class="flex-grow-1 me-1">
                    <label for="startDate" class="form-label">Fecha inicio:</label>
                    <input type="datetime-local" class="form-control " v-model="item.fecha_inicio" />
                  </div>
                  <div class="flex-grow-1 ms-1">
                    <label for="EndDate" class="form-label">Fecha fin:</label>
                    <input type="datetime-local" class="form-control" v-model="item.fecha_fin" />
                  </div>
                </div>
              </li>
            </ul>

            <div class="d-flex justify-content-between mt-2">
              <!-- <button class="btn btn-primary btn-sm" @click="addChecklistItem" :disabled="!canAddTask">Agregar</button> -->
              <!-- <button class="btn btn-success btn-sm" @click="saveChecklist" :disabled="!canSaveTask">Guardar</button> -->

              <button class="btn btn-primary btn-sm" @click="addChecklistItem">Agregar</button>


            </div>
          </div>
          <div class="mb-3">
            <label for="taskPartners" class="form-label fw-medium">Usuarios:</label>
            <MultiSelect v-model="selectedPartner" :options="partners" optionLabel="nombre" optionValue="uuid"
              placeholder="Seleccione usuarios" :filter="true" :multiple="true"
              @update:modelValue="updateSelectedPartners" class="w-100" />
          </div>

          <div class="mb-3">
            <label for="taskTags" class="form-label fw-medium">Tags:</label>
            <button class="btn btn-success btn-sm px-2 py-1 mx-2" @click="openTagModal">
              crear tag
            </button>
            <MultiSelect v-model="selectedTag" :options="tags" optionLabel="tag" optionValue="uuid"
              placeholder="Seleccione tags" :filter="true" :multiple="true" @update:modelValue="updateSelectedTag"
              class="w-100" />
          </div>

          <div class="mb-3">
            <label for="taskPriority" class="form-label fw-medium">Prioridad:</label>
            <Dropdown v-model="selectedPriority" :options="priorities" optionLabel="prioridad" optionValue="uuid"
              placeholder="Seleccione una prioridad" class="w-100" />
          </div>

          <div class="mb-3">
            <label for="taskPriority" class="form-label fw-medium">Adjuntar archivos:</label>
            <input type="file" class="form-control" id="eventFiles" multiple @change="handleFileUpload" />
          </div>

          <div class="mb-3" v-if="uploadedFiles.length">
            <label for="taskPriority" class="form-label fw-medium">Archivos cargados:</label>
            <ul class="list-group">
              <li v-for="(file, index) in uploadedFiles" :key="index"
                class="list-group-item d-flex justify-content-between align-items-center">
                {{ file.contentType }}
                <button type="button" class="btn btn-danger btn-sm" @click="removeFile(index)">
                  Eliminar
                </button>
              </li>
            </ul>
          </div>

          <div class="col-md-12 pt-20">
            <button type="button" class="btn btn-sm btn-primary text-center" @click="validarCamposYGuardarTarea">Guardar
              Tarea</button>
            <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">Cerrar</button>
          </div>
        </div>
      </div>

      <div class="tab-content " v-show="activeTab === 'eventos'">
        <!-- Contenido de Eventos -->
        <div class="row">
          <div class="mb-3">
            <label for="taskTitle" class="form-label fw-medium">Título del evento:</label>
            <input type="text" class="form-control shadow-none" id="taskTitle" v-model="newEvent.title" />
          </div>

          <div class="mb-3">
            <label for="taskDescription" class="form-label fw-medium">Descripción:</label>
            <textarea class="form-control" id="taskDescription" v-model="newEvent.description" rows="3"></textarea>
          </div>

          <div class="mb-3">
            <label for="taskPartners" class="form-label fw-medium">Usuarios:</label>
            <MultiSelect v-model="selectedPartner" :options="partners" optionLabel="nombre" optionValue="uuid"
              placeholder="Selecciona un usuario" :filter="true" :multiple="true"
              @update:modelValue="updateSelectedPartners" class="w-100" />
          </div>


          <div class="mb-3">
            <label for="taskStartTime" class="form-label fw-medium">Fecha y Hora de inicio:</label>
            <input type="datetime-local" class="form-control" id="taskStartTime" v-model="newEvent.startTime" />
          </div>

          <div class="mb-3">
            <label for="taskEndTime" class="form-label fw-medium">Fecha y Hora de finalización:</label>
            <input type="datetime-local" class="form-control" id="taskEndTime" v-model="newEvent.endTime" />
          </div>

          <div class="mb-3">
            <label for="taskTitle" class="form-label fw-medium">Ubicación:</label>
            <input type="text" class="form-control shadow-none" id="taskTitle" v-model="newEvent.ubicacion" />
          </div>

          <div class="mb-3">
            <label for="taskTitle" class="form-label fw-medium">Link (Si aplica):</label>
            <input type="text" class="form-control shadow-none" id="taskTitle" placeholder="Ingrese link de reunion"
              v-model="newEvent.link" />
          </div>

          <div class="mb-3">
            <label for="taskTags" class="form-label fw-medium">Tags:</label>
            <button class="btn btn-success btn-sm px-2 py-1 mx-2" @click="openTagModal">
              crear tag
            </button>
            <MultiSelect v-model="selectedTag" :options="tags" optionLabel="tag" optionValue="uuid"
              placeholder="Seleccione tags" :filter="true" :multiple="true" @update:modelValue="updateSelectedTag"
              class="w-100" />
          </div>

          <div class="mb-3">
            <label for="taskPriority" class="form-label fw-medium">Prioridad:</label>
            <Dropdown v-model="selectedPriority" :options="priorities" optionLabel="prioridad" optionValue="uuid"
              placeholder="Seleccione una prioridad" class="w-100" />
          </div>
          <div class="mb-3" v-if="uploadedFiles.length">
            <label for="taskPriority" class="form-label fw-medium">Archivos cargados:</label>
            <ul class="list-group">
              <li v-for="(file, index) in uploadedFiles" :key="index"
                class="list-group-item d-flex justify-content-between align-items-center">
                {{ file.contentType }}
                <button type="button" class="btn btn-danger btn-sm" @click="removeFile(index)">
                  Eliminar
                </button>
              </li>
            </ul>
          </div>

          <div class="col-md-12 pt-20">
            <button type="button" class="btn btn-sm btn-primary text-center" @click="guardarEvento">Guardar
              evento</button>
            <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">Cerrar</button>
          </div>
        </div>
      </div>

      <div class="tab-content" v-show="activeTab === 'archivo'">
        <!-- Contenido de Eventos -->
        <div class="row">
          <userDropdown v-model="selectedUser" :userOptions="user" />
          <div class="mb-3">
            <label for="taskTitle" class="form-label fw-medium">Ingresar archivo:</label>
            <FileUpload ref="fileUploadRef" mode="basic" name="documento"
              accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              :maxFileSize="1000000" :auto="false" chooseLabel="Explorar" @select="handleFileSelect"
              style="width: 100%" />
          </div>


          <div class="col-md-12 pt-20">
            <button type="button" class="btn btn-sm btn-primary text-center" @click="sendFile">Guardar</button>
            <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">Cerrar</button>
          </div>
        </div>
      </div>


      <div class="tab-content" v-show="activeTab === 'nuevoTab'">
        <DiferentEvent />
      </div>
    </div>
    <TagModal :visible="isTagModalVisible" @close="closeTagModal" />
  </DialogComponent>
  <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch, computed, onMounted } from 'vue';
import Dropdown from "primevue/dropdown";
import moment from 'moment';
import createSocketService from "@/services/socketService";
import { UploadedFile, Activity } from "@/interfaces/interfaces";
import TagModal from "./TagModal.vue";
import { useToast } from "vue-toastification";
import FileUpload from "primevue/fileupload";
import Agent from "@/services/agentsService";
import userDropdown from './userDropdown.vue';
import DiferentEvent from './DiferentEvent.vue';
import LocalStorageValue from "@/services/localStorageService";
import User from "@/services/userService";


export default defineComponent({
  name: 'ModalContent',
  components: {
    Dropdown,
    TagModal,
    FileUpload,
    userDropdown,
    DiferentEvent
  },
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    partners: {
      type: Array as PropType<any[]>,
      required: true,
    },
    tags: {
      type: Array as PropType<any[]>,
      required: true,
    },
    priorities: {
      type: Array as PropType<any[]>,
      required: true,
    }
  },
  emits: ['close', 'guardarTarea', 'edit-task'],
  setup(props, { emit }) {
    const localVisible = ref(props.visible);
    const newEvent = ref({
      title: "",
      description: "",
      startTime: "",
      endTime: "",
      ubicacion: "",
      link: "",
    });

    const selectedPartner = ref("");
    const selectedTag = ref("");
    const selectedPriority = ref("");
    const uploadedFiles = ref<UploadedFile[]>([]);
    const activeTab = ref('archivo');
    const isAdding = ref(true);
    const isSaving = ref(false);
    const isLoading = ref(false);
    let socketService: any = null;
    const toast = useToast();
    const selectedFileBase64 = ref<string | null>(null);
    const selectedUser = ref("");
    const user = ref([]);
    const localStorageValue = new LocalStorageValue();


    const getAsesorPartner = async () => {
      try {
        const roles = await localStorageValue.getRol();
        const user = await localStorageValue.getUserData();

        const unicoRol = Array.isArray(roles) && roles.length > 0 ? roles[0] : null;
        const data = {
          uRol: unicoRol?.uuidRol,
          key: user,
        };

        console.log("Rol y usuario impreso:", data);
        return data;

      } catch (error) {
        console.error("Error en getAsesorPartner:", error);
        throw error;
      }
    };



    const newActivity = ref<Activity>({
      title: '',
      description: '',
      startTime: '',
      endTime: '',
      checklist: []
    });

    const isTagModalVisible = ref(false);

    const openTagModal = () => {
      isTagModalVisible.value = true;
    };

    const closeTagModal = () => {
      isTagModalVisible.value = false;
    };

    const formatDate = (item, key) => {
      item[key] = moment(item[key]).format('YYYY-MM-DD HH:mm:ss.SSSSSS');
    };

    watch(
      () => props.visible,
      (newValue) => {
        localVisible.value = newValue;
      }
    );
    watch(localVisible, (newValue) => {
      if (!newValue) {
        localVisible.value = false;
        emit('close');
      }
    });

    const closeModal = () => {
      localVisible.value = false;
      emit('close');
    };

    // Computed property para calcular el progreso
    const progress = computed(() => {
      const totalItems = newActivity.value.checklist.length;
      const completedItems = newActivity.value.checklist.filter(item => item.check).length;

      return totalItems > 0 ? (completedItems / totalItems) * 100 : 0;
    });


    // const checklistHasText = computed(() => {
    //   return newActivity.value.checklist.some(item => item.nombre.trim() !== '');
    // });

    const canAddTask = computed(() => isAdding.value);

    const canSaveTask = computed(() => isSaving.value);

    const addChecklistItem = () => {
      newActivity.value.checklist.push({
        uuid: '',
        nombre: '',
        check: false,
        fecha_inicio: moment(new Date()).format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
        fecha_fin: moment(new Date()).format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
      });

    };

    // Función para guardar el checklist
    const saveChecklist = () => {
      // const formattedChecklist = newActivity.value.checklist.map(item => ({
      //   uuid_tarea: "",
      //   nombre: item.text, 
      //   fecha_inicio: moment(item.startDate).format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
      //   fecha_fin: moment(item.endDate).format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
      //   check: item.completed,
      // }));

      // console.log(formattedChecklist);

      isSaving.value = false;
      isAdding.value = true;
    };

    // Función para eliminar un elemento del checklist
    const removeChecklistItem = (index: number) => {
      newActivity.value.checklist.splice(index, 1);
    };

    const handleFileUpload = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onload = () => {
            if (typeof reader.result === 'string') {
              uploadedFiles.value.push({
                base64: reader.result,
                contentType: files[i].type,
                name: files[i].name
              });
            }
          };
        }
      }
    };


    const removeFile = (index: number) => {
      uploadedFiles.value.splice(index, 1);
    };

    const clearForm = () => {
      newEvent.value.title = "";
      newEvent.value.description = "";
      newEvent.value.startTime = "";
      newEvent.value.endTime = "";
      selectedPartner.value = "";
      selectedTag.value = "";
      selectedPriority.value = "";
    };

    const updateSelectedPartners = (newPartherns) => {
      selectedPartner.value = newPartherns;
    };

    const updateSelectedTag = (newTags) => {
      selectedTag.value = newTags;
    };

    const validarCamposYGuardarTarea = () => {
      if (!newEvent.value.title) {
        toast.error("El título de la tarea es obligatorio.");
        return;
      }
      if (!newEvent.value.startTime) {
        toast.error("La fecha de inicio es obligatoria.");
        return;
      }
      if (!selectedPriority.value) {
        toast.error("La prioridad es obligatoria.");
        return;
      }

      guardarTarea();
    };

    const guardarTarea = () => {
      isLoading.value = true;
      const formattedStartDate = moment(newEvent.value.startTime).format('YYYY-MM-DD HH:mm:ss.SSSSSS');
      const formattedEndDate = moment(newEvent.value.endTime).isValid()
        ? moment(newEvent.value.endTime).format('YYYY-MM-DD HH:mm:ss.SSSSSS')
        : '';

      const payload = {
        titulo: newEvent.value.title,
        mensaje: newEvent.value.description,
        arrayUsuario: selectedPartner.value,
        fecha_inicio: formattedStartDate,
        fecha_fin: formattedEndDate,
        uuidPrioridad: selectedPriority.value,
        tags: selectedTag.value,
        archivos: uploadedFiles.value,
        actividades: newActivity.value.checklist.map(item => ({
          nombre: item.nombre,
          fecha_inicio: moment(item.fecha_inicio).format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
          fecha_fin: moment(item.fecha_fin).isValid() ? moment(item.fecha_fin).format('YYYY-MM-DD HH:mm:ss.SSSSSS') : '',
          check: item.check
        })),
      };
      socketService.emit('save-task-notification', payload);

    };

    const guardarEvento = () => {
      isLoading.value = true;
      const formattedStartDate = moment(newEvent.value.startTime).format('YYYY-MM-DD HH:mm:ss.SSSSSS');

      const formattedEndDate = moment(newEvent.value.endTime).isValid()
        ? moment(newEvent.value.endTime).format('YYYY-MM-DD HH:mm:ss.SSSSSS')
        : '';

      const payload = {
        nombre: newEvent.value.title,
        descripcion: newEvent.value.description,
        arrayUsuario: selectedPartner.value,
        fecha_inicio: formattedStartDate,
        fecha_fin: formattedEndDate,
        ubicacion: newEvent.value.ubicacion,
        link: newEvent.value.link,
        uuidPrioridad: selectedPriority.value,
        tags: selectedTag.value,
        archivos: uploadedFiles.value,
      };

      console.log("payload de evento", payload);

      socketService.emit('save-event-notification', payload);
    };


    const handleFileSelect = (event) => {
      const file = event.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = () => {
        selectedFileBase64.value = reader.result as string;
      };
      reader.onerror = () => {
        toast.error("Hubo un error al leer el archivo.");
      };
      reader.readAsDataURL(file);
    };


    const sendFile = async () => {
      if (!selectedUser.value) {
        toast.error("Por favor selecciona un usuario antes de guardar.");
        return;
      }

      if (!selectedFileBase64.value) {
        toast.error("Por favor selecciona un archivo antes de guardar.");
        return;
      }

      const rolData = await getAsesorPartner();
      if (!rolData || !rolData.uRol || !rolData.key) {
        toast.error("Error al obtener el rol del asesor.");
        return;
      }

      const payload = {
        uuid: selectedUser.value,
        base64: selectedFileBase64.value,
        uuid_rol: rolData.uRol,
        uuid_usuario: rolData.key
      };

      console.log("payload que voy a mandar", payload);


      try {
        isLoading.value = true;

        const response = await new Agent().sendFile64(payload);
        console.log("response a tarea", response);

        if (!response.error) {
          toast.success("Archivo enviado correctamente.");
          emit("close");
        } else {
          toast.error(response.message || "Error desconocido, contactar a soporte.");
        }
      } catch (error) {
        toast.error("Error al enviar el archivo.");
      } finally {
        isLoading.value = false;
      }
    };


    watch(newActivity.value.checklist, (newChecklist) => {
      if (newChecklist.some(item => item.nombre.trim() !== '')) {
        isSaving.value = true;
        isAdding.value = false;
      } else {
        isSaving.value = false;
        isAdding.value = true;
      }
    });
    let activo = false;

    onMounted(async () => {
      try {
        if (!socketService) {
          socketService = await createSocketService();
          socketService.connect();
        }

        if (!activo) {
          socketService.on('save-task-notification', (response) => {
            if (!response.error) {
              clearForm();
              closeModal();
              toast.success("Tarea enviada correctamente");
            } else {
              toast.error("Error al enviar la tarea");
            }
            isLoading.value = false;
          });
          socketService.on('save-event-notification', (response) => {
            if (!response.error) {
              clearForm();
              closeModal();
              toast.success("Evento enviado correctamente");
            } else {
              toast.error("Error al enviar el evento");
            }
            isLoading.value = false;
          });

          activo = true;
        }
      } catch (error) {
        console.log(error);
      }
    });

    return {
      localVisible,
      closeModal,
      newEvent,
      selectedPartner,
      uploadedFiles,
      handleFileUpload,
      removeFile,
      selectedPriority,
      guardarTarea,
      selectedTag,
      updateSelectedPartners,
      updateSelectedTag,
      activeTab,
      addChecklistItem,
      removeChecklistItem,
      newActivity,
      saveChecklist,
      canAddTask,
      canSaveTask,
      progress,
      guardarEvento,
      formatDate,
      isTagModalVisible,
      openTagModal,
      closeTagModal,
      isLoading,
      validarCamposYGuardarTarea,
      handleFileSelect,
      sendFile,
      user,
      selectedUser,
      getAsesorPartner
    };
  },
});
</script>

<style scoped>
.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.tab {
  padding: 10px 15px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.tab.active {
  border-bottom: 2px solid #007bff;
  font-weight: bold;
}

.tab-content {
  padding: 15px 0;
}
</style>