<template>
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
            <h4 class="card-title fw-bold mb-15 mb-md-25 text-center">
                REVISIÓN E INSPECCIÓN VEHICULAR
            </h4>
            <div class="row">
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">FECHA:</label>
                    <Calendar v-model="date" class="w-100" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona una empresa:</label>
                    <Dropdown v-model="selectedEmpresa" :options="empresas" optionLabel="empresa" optionValue="uuid"
                        placeholder="Selecciona una empresa" class="w-100" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">KILOMETRAJE INICIAL:</label>
                    <InputText class="w-100" type="text" v-model="kilinicial" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Kilometraje Final:</label>
                    <InputText class="w-100" type="text" v-model="kilfinal" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskVe" class="form-label fw-medium">N° ECONÓMICO:</label>
                    <Dropdown v-model="neconomico" :options="vehiculos" optionLabel="numero"
                        :optionValue="vehicle => ({ uuid: vehicle.uuid, numero: vehicle.numero })"
                        placeholder="Selecciona un vehiculo" class="w-100" />

                </div>

                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">ÁREA:</label>
                    <InputText class="w-100" type="text" v-model="area" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">MODELO:</label>
                    <InputText class="w-100" type="text" v-model="modelo" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">CONDUCTOR:</label>
                    <InputText class="w-100" type="text" v-model="conductor" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">PLACAS:</label>
                    <InputText class="w-100" type="text" v-model="placas" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">N° DE LICENCIA:</label>
                    <InputText class="w-100" type="text" v-model="licencia" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">TIPO DE LICENCIA:</label>
                    <InputText class="w-100" type="text" v-model="tlicencia" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <label for="taskTags" class="form-label fw-medium">VENCIMIENTO:</label>
                    <Calendar v-model="vencimiento" class="w-100" />
                </div>
                <h5 class="card-title fw-bold mb-15 mb-md-25 text-center">
                    DOCUMENTOS DEL VEHICULO
                </h5>
                <div class="col-12 col-md-3 mb-3">
                    <label for="taskTags" class="form-label fw-medium">VIGENCIA DE PÓLIZA:</label>
                    <Calendar v-model="vigenpoliza" class="w-100" />
                </div>
                <div class="col-12 col-md-3">
                    <div class="d-flex flex-column text-center mb-3">
                        <label for="taskTags" class="fw-medium mb-2">TARJETA DE CIRCULACIÓN:</label>
                        <div class="d-flex justify-content-center">
                            <div class="form-check me-3">
                                <input v-model="uuidsel" class="form-check-input shadow-none" type="radio"
                                    id="flexRadioDefault1" :value="1" />
                                <label class="form-check-label fw-medium" for="flexRadioDefault1">
                                    Sí
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model="uuidsel" class="form-check-input shadow-none" type="radio"
                                    id="flexRadioDefault2" :value="0" />
                                <label class="form-check-label fw-medium" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="d-flex flex-column text-center mb-3">
                        <label for="taskTags" class="fw-medium mb-2">ENGOMADO DE PLACAS:</label>
                        <div class="d-flex justify-content-center">
                            <div class="form-check me-3">
                                <input v-model="uuidsel1" class="form-check-input shadow-none" type="radio"
                                    id="flexRadioDefault1" :value="1" />
                                <label class="form-check-label fw-medium" for="flexRadioDefault1">
                                    Sí
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model="uuidsel1" class="form-check-input shadow-none" type="radio"
                                    id="flexRadioDefault2" :value="0" />
                                <label class="form-check-label fw-medium" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="d-flex flex-column text-center mb-3">
                        <label for="taskTags" class="fw-medium mb-2">BITACORA VEHICULAR:</label>
                        <div class="d-flex justify-content-center">
                            <div class="form-check me-3">
                                <input v-model="uuidsel2" class="form-check-input shadow-none" type="radio"
                                    id="flexRadioDefault1" :value="1" />
                                <label class="form-check-label fw-medium" for="flexRadioDefault1">
                                    Sí
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model="uuidsel2" class="form-check-input shadow-none" type="radio"
                                    id="flexRadioDefault2" :value="0" />
                                <label class="form-check-label fw-medium" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 class="card-title fw-bold mb-15 mb-md-25 text-center">
                    ACCESORIOS, HERRAMIENTA Y MOTOR
                </h5>
                <h6 class="card-title fw-bold mb-15 mb-md-25 text-center">
                    marque en cada casilla indicando las condiciones en que se encuentra
                    los descrito a contianuación
                </h6>
                <div class="col-md-6">
                    <table class="table table-bordered text-center">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">Descripción de Interiores</th>
                                <th scope="col">Cumple</th>
                                <th scope="col">No Cumple</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in inspectionRows" :key="index">
                                <td>{{ row.label }}</td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="1" />
                                </td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="0" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="table table-bordered text-center">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">Descripción de Exteriores</th>
                                <th scope="col">Cumple</th>
                                <th scope="col">No Cumple</th>
                                <th scope="col">N/A</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in exteriorInspectionRows" :key="`exterior-${index}`">
                                <td>{{ row.label }}</td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="1" />
                                </td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="0" />
                                </td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="2" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-6">
                    <table class="table table-bordered text-center">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">Motor</th>
                                <th scope="col">Bueno</th>
                                <th scope="col">Regular</th>
                                <th scope="col">Malo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in motorInspectionRows" :key="`motor-${index}`">
                                <td>{{ row.label }}</td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="1" />
                                </td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="0" />
                                </td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="2" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="table table-bordered text-center">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">Descripción</th>
                                <th scope="col">Cumple</th>
                                <th scope="col">No Cumple</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in toolsInspectionRows" :key="`tools-${index}`">
                                <td>{{ row.label }}</td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="1" />
                                </td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="0" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <h5 class="card-title fw-bold mb-15 mb-md-25 text-center">
                        ESTADO FÍSICO DE LAS LLANTAS
                    </h5>
                    <table class="table table-bordered text-center">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">Posición</th>
                                <th scope="col">Bueno</th>
                                <th scope="col">Regular</th>
                                <th scope="col">Malo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in tiresInspectionRows" :key="`tires-${index}`">
                                <td>{{ row.label }}</td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="1" />
                                </td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="0" />
                                </td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="2" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-12 col-md-4 mb-3">
                        <label for="taskTags" class="form-label fw-medium">Porcentaje vida util llantas:</label>
                        <InputText class="w-100" type="text" v-model="porcllantas" />
                    </div>
                </div>
                <div class="col-md-6">
                    <h5 class="card-title fw-bold mb-15 mb-md-25 text-center">
                        Estado Físico de Carrocería y Limpieza Interiores
                    </h5>
                    <table class="table table-bordered text-center">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">Parte</th>
                                <th scope="col">Bueno</th>
                                <th scope="col">Regular</th>
                                <th scope="col">Malo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in bodyInspectionRows" :key="`body-${index}`">
                                <td>{{ row.label }}</td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="1" />
                                </td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="0" />
                                </td>
                                <td>
                                    <input v-model="row.cumpleValue" class="form-check-input shadow-none" type="radio"
                                        :value="2" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-4 mb-4">
                    <label class="form-label fw-bold">Control de acceso</label>
                    <div class="d-flex align-items-center">
                        <div class="form-check me-3">
                            <input v-model="controlAcceso" class="form-check-input shadow-none" type="radio"
                                id="controlAccesoSi" :value="1" />
                            <label class="form-check-label fw-medium" for="controlAccesoSi">Sí</label>
                        </div>
                        <div class="me-3" v-if="controlAcceso === 1">
                            <InputText v-model="numeroControl" class="form-control" type="text" placeholder="No°" />
                        </div>
                        <div class="form-check">
                            <input v-model="controlAcceso" class="form-check-input shadow-none" type="radio"
                                id="controlAccesoNo" :value="0" />
                            <label class="form-check-label fw-medium" for="controlAccesoNo">No</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <label class="form-label fw-bold">COMBUSTIBLE</label>
                </div>
                <div class="col-md-4 mb-4 position-relative">
                    <div class="canvas-container">
                        <div class="image-container">
                            <img src="../../assets/images/ENTRA.png" alt="Combustible" class="fuel-image" />
                        </div>
                        <canvas ref="fuelCanvas" class="drawing-canvas-fuel" @mousedown="startDrawingFuel"
                            @mousemove="drawFuel" @mouseup="stopDrawingFuel"></canvas>
                    </div>
                </div>


                <div class="col-12 mb-3">
                    <table class="table table-bordered text-center">
                        <tbody>
                            <tr>
                                <td scope="col" rowspan="2" class="text-center fw-bold align-middle">
                                    DAÑOS
                                </td>
                                <td scope="col" class="text-center fw-bold align-middle">
                                    Ninguno
                                </td>
                                <td scope="col">
                                    <input v-model="danioSeleccionado" class="form-check-input shadow-none" type="radio"
                                        :value="0" />
                                </td>
                                <td scope="col" rowspan="2" class="text-center fw-bold align-middle">
                                    Indique cualquier daño observado en un círculo o en la
                                    ubicación correspondiente en el diagrama
                                </td>
                            </tr>
                            <tr>
                                <td scope="col" class="text-center fw-bold align-middle">
                                    Segun lo indicado
                                </td>
                                <td scope="col">
                                    <input v-model="danioSeleccionado" class="form-check-input shadow-none" type="radio"
                                        :value="1" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12 position-relative">
                    <div class="canvas-container">
                        <img src="../../assets/images/carrosbien.png" alt="carro lado derecho" class="car-image" />
                        <canvas ref="canvas" class="drawing-canvas" @mousedown="startDrawing" @mousemove="draw"
                            @mouseup="stopDrawingAndSave" :style="{
                                pointerEvents: danioSeleccionado === 1 ? 'auto' : 'none',
                            }">
                        </canvas>
                    </div>
                </div>

                <div class="col-12 mb-3">
                    <label class="form-label fw-bold">Observaciones, cualquier anomalía o defecto que no se encuentre en
                        listado favor de anotarlo</label>
                    <Textarea v-model="observacionesGenerales" class="form-control" rows="4"
                        placeholder="Escribe tus observaciones aquí..." />
                </div>
                <div class="col-12 mb-3">
                    <label class="form-label fw-bold">Observaciones de quien recibe</label>
                    <Textarea v-model="comentariosAdicionales" class="form-control" rows="4"
                        placeholder="Escribe comentarios adicionales aquí..." />
                </div>

                <div class="col-12 col-md-12 pt-3">
                    <button type="button" class="btn btn-md btn-primary me-2" @click="submitForm">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from "vue";
import Vehicles from "@/services/VehiclesService";
import { useToast } from "vue-toastification";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import Dropdown from 'primevue/dropdown';
import {
    inspectionRows,
    exteriorInspectionRows,
    motorInspectionRows,
    toolsInspectionRows,
    tiresInspectionRows,
    bodyInspectionRows,
} from "@/components/Vehiculos/inspectionData";

export default defineComponent({
    name: "InspeccionVehi",
    components: { InputText, Calendar, Textarea, Dropdown },
    setup() {
        const canvas = ref<HTMLCanvasElement | null>(null);
        const context = ref<CanvasRenderingContext2D | null>(null);
        const isDrawing = ref(false);
        const isLoading = ref<boolean>(false);
        const toast = useToast();
        const kilinicial = ref<string>("");
        const kilfinal = ref<string>("");
        const date = ref();
        const neconomico = ref<{ uuid: string; numero: string } | null>(null);
        const area = ref("");
        const modelo = ref("");
        const conductor = ref("");
        const selectedEmpresa = ref(null);
        const selectedVehicle = ref(null);
        const empresas = ref([]);
        const vehiculos = ref([]);
        const placas = ref("");
        const licencia = ref("");
        const tlicencia = ref("");
        const vencimiento = ref();
        const vigenpoliza = ref();
        const tarjetaCirculacion = ref("");
        const otroDocumento = ref("");
        const uuidsel = ref("");
        const uuidsel1 = ref("");
        const uuidsel2 = ref("");
        const cintsegur = ref("");
        const porcllantas = ref("");
        const controlAcceso = ref<number | null>(null);
        const numeroControl = ref<string>("");
        const observacionesGenerales = ref<string>("");
        const comentariosAdicionales = ref<string>("");
        const danioSeleccionado = ref<number>(0);
        const imageBase64 = ref<string>("");
        const imageContentType = ref<string>("");
        const startPoint = ref<{ x: number; y: number } | null>(null);
        const fuelCanvas = ref<HTMLCanvasElement | null>(null);
        const fuelContext = ref<CanvasRenderingContext2D | null>(null);
        const startFuelPoint = ref<{ x: number; y: number } | null>(null);
        const imageBase64Fuel = ref<string>("");
        const imageContentTypeFuel = ref<string>("");

        const CargarEmpresa = async () => {
            try {
                const responseEmpresas = await new Vehicles().ListEmpresa();
                empresas.value = responseEmpresas.response;
                console.log(empresas.value);
            } catch (error) {
                toast.error("Error al cargar las empresas");
            }
        }

        const CargarVehiculos = async () => {
            try {
                const responseVehicles = await new Vehicles().listVehicles();
                vehiculos.value = responseVehicles.response.map(vehicle => ({
                    uuid: vehicle.uuid,      // Asegúrate de que esta propiedad exista
                    numero: vehicle.numero    // Asegúrate de que esta propiedad exista
                }));
                console.log(vehiculos.value);
            } catch (error) {
                toast.error("Error al cargar las empresas");
            }
        }

        const drawLine = (event) => {
            if (!context.value || !canvas.value || !startPoint.value) return;

            const rect = canvas.value.getBoundingClientRect();
            const endPoint = {
                x: event.clientX - rect.left,
                y: event.clientY - rect.top,
            };

            context.value.clearRect(0, 0, canvas.value.width, canvas.value.height);

            context.value.beginPath();
            context.value.moveTo(startPoint.value.x, startPoint.value.y);
            context.value.lineTo(endPoint.x, endPoint.y);
            context.value.stroke();
            context.value.closePath();
        };

        const stopDrawing = () => {
            startPoint.value = null;
        };

        const startDrawingFuel = (event) => {
            if (!fuelContext.value || !fuelCanvas.value) return;
            const rect = fuelCanvas.value.getBoundingClientRect();
            startFuelPoint.value = {
                x: event.clientX - rect.left,
                y: event.clientY - rect.top,
            };
            fuelContext.value.beginPath();
        };

        const drawFuel = (event) => {
            if (!startFuelPoint.value || !fuelContext.value || !fuelCanvas.value) return;
            const rect = fuelCanvas.value.getBoundingClientRect();
            fuelContext.value.lineTo(event.clientX - rect.left, event.clientY - rect.top);
            fuelContext.value.stroke();
        };

        const stopDrawingFuel = () => {
            if (!fuelContext.value || !startFuelPoint.value) return;
            fuelContext.value.closePath();
            startFuelPoint.value = null;
            saveFuelImage();
        };


        const startDrawing = (event) => {
            if (!context.value || !canvas.value) return;
            isDrawing.value = true;
            const rect = canvas.value.getBoundingClientRect();
            context.value.beginPath();
            context.value.moveTo(event.clientX - rect.left, event.clientY - rect.top);
        };

        const draw = (event) => {
            if (!isDrawing.value || !context.value || !canvas.value) return;
            const rect = canvas.value.getBoundingClientRect();
            context.value.lineTo(event.clientX - rect.left, event.clientY - rect.top);
            context.value.stroke();
        };

        const stopDrawingAndSave = () => {
            isDrawing.value = false;
            if (context.value) context.value.closePath();

            if (danioSeleccionado.value === 1) {
                saveImage();
            }
        };

        const saveImage = () => {
            if (!canvas.value || danioSeleccionado.value === 0) return;

            const tempCanvas = document.createElement("canvas");
            const tempContext = tempCanvas.getContext("2d");
            const imgElement = document.querySelector(".car-image") as HTMLImageElement;

            if (imgElement && tempContext) {
                tempCanvas.width = canvas.value.width;
                tempCanvas.height = canvas.value.height;
                tempContext.drawImage(imgElement, 0, 0, tempCanvas.width, tempCanvas.height);
                tempContext.drawImage(canvas.value, 0, 0);

                const dataUrl = tempCanvas.toDataURL();
                const base64Data = dataUrl.split(",")[1];
                const mimeType = dataUrl.match(/^data:(.*?);base64/)?.[1] || "image/png";

                imageBase64.value = base64Data;
                imageContentType.value = mimeType;
            }
        };

        const saveFuelImage = () => {
            if (!fuelCanvas.value) return;
            const tempCanvas = document.createElement("canvas");
            const tempContext = tempCanvas.getContext("2d");
            const imgElement = document.querySelector(".fuel-image") as HTMLImageElement;
            if (imgElement && tempContext) {
                tempCanvas.width = fuelCanvas.value.width;
                tempCanvas.height = fuelCanvas.value.height;
                tempContext.drawImage(imgElement, 0, 0, tempCanvas.width, tempCanvas.height);
                tempContext.drawImage(fuelCanvas.value, 0, 0);
                const dataUrl = tempCanvas.toDataURL();
                imageBase64Fuel.value = dataUrl.split(",")[1];
                imageContentTypeFuel.value = dataUrl.match(/^data:(.*?);base64/)?.[1] || "image/png";
            }
        };

        const formatDate = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, "0");
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const year = d.getFullYear();
            return `${day}/${month}/${year}`;
        };

        const submitForm = async () => {
            isLoading.value = true;
            try {

                saveFuelImage();
                const inspectionData = inspectionRows.value.reduce((acc, row) => {
                    acc[row.modelValue] = row.cumpleValue;
                    return acc;
                }, {});

                const exteriorData = exteriorInspectionRows.value.reduce((acc, row) => {
                    acc[row.modelValue] = row.cumpleValue;
                    return acc;
                }, {});

                const motorData = motorInspectionRows.value.reduce((acc, row) => {
                    acc[row.modelValue] = row.cumpleValue;
                    return acc;
                }, {});

                const toolsData = toolsInspectionRows.value.reduce((acc, row) => {
                    acc[row.modelValue] = row.cumpleValue;
                    return acc;
                }, {});
                const tiresData = tiresInspectionRows.value.reduce((acc, row) => {
                    acc[row.modelValue] = row.cumpleValue;
                    return acc;
                }, {});
                const bodyData = bodyInspectionRows.value.reduce((acc, row) => {
                    acc[row.modelValue] = row.cumpleValue;
                    return acc;
                }, {});

                if (danioSeleccionado.value === 0) {
                    const imgElement = document.querySelector(
                        ".car-image"
                    ) as HTMLImageElement;
                    const tempCanvas = document.createElement("canvas");
                    const tempContext = tempCanvas.getContext("2d");

                    if (imgElement && tempContext) {
                        tempCanvas.width = imgElement.width;
                        tempCanvas.height = imgElement.height;
                        tempContext.drawImage(imgElement, 0, 0);

                        const dataUrl = tempCanvas.toDataURL();
                        const base64Data = dataUrl.split(",")[1];
                        const mimeType =
                            dataUrl.match(/^data:(.*?);base64/)?.[1] || "image/png";

                        imageBase64.value = base64Data;
                        imageContentType.value = mimeType;
                    }
                } else {
                    saveImage();
                }

                const data = {
                    "uuid_empresa": selectedEmpresa.value,
                    "uuid_vehiculo": neconomico.value ? neconomico.value.uuid : null,
                    data: {
                        "35b7f67d-f127-4976-8ca8-eb483ff507ec": formatDate(date.value),
                        "d80d9680-5421-4d53-a0f9-929793b172c7": kilinicial.value,
                        "e4b2bdb6-fc6b-4655-9bf9-c0e5da1170a6": kilfinal.value,
                        "93aaeedd-ecd0-49c6-a05d-2808ad8a8f41": neconomico.value ? neconomico.value.numero : null,
                        "e948cb9b-b76a-46f0-8d60-c1b1dbc20350": area.value,
                        "8c08c6a3-bbf8-4d93-8a32-4cfc3521b83d": modelo.value,
                        "cc73ced5-67bb-4e86-8d32-2a0e46160504": conductor.value,
                        "01e85e25-e6aa-4e67-a402-d7bafb339f42": placas.value,
                        "0ebce95b-e31c-47cf-94be-9788cd012438": licencia.value,
                        "ba11233f-23a8-43bc-bf15-c34ee647d20c": tlicencia.value,
                        "090c0997-c95a-4fbd-aeec-5638540a78ae": formatDate(vencimiento.value),
                        "151d0d48-695f-4e5b-8a52-dc6b6d0af092": uuidsel.value,
                        "d6223728-ff76-4cf9-92eb-6814cb283d09": uuidsel1.value,
                        "f4ab8ca8-2dfb-495c-b7b7-0b54e67d7b68": formatDate(vigenpoliza.value),
                        "b7ce708d-08a8-4167-8034-be4b79ee77ee": uuidsel2.value,
                        "4790a792-390c-48de-8a25-2503ec98efde": porcllantas.value,
                        "6c006721-3ebf-4e9b-bfcb-0faf9606d121":
                            controlAcceso.value === 1 ? numeroControl.value : 0,
                        "ecdb9759-3ea1-4c1a-9b8a-0c8534518e95":
                            observacionesGenerales.value,
                        "e98ec26d-b5bd-41d5-9bdd-55b24e4e083a": {
                            contentType: imageContentTypeFuel.value,
                            base64: imageBase64Fuel.value,
                        }, // combustible
                        "a6620cee-8d45-4520-b052-e518fc85be42": danioSeleccionado.value, // Daño segun lo indicado niguno
                        "1ce72e1c-ffb1-49c4-964e-1890d206e3ad": {
                            contentType: imageContentType.value,
                            base64: imageBase64.value,
                        }, // Daño imagen
                        "153b164b-11e4-4ef0-a7a8-7fbf74e61478":
                            comentariosAdicionales.value,
                        ...inspectionData,
                        ...exteriorData,
                        ...motorData,
                        ...toolsData,
                        ...tiresData,
                        ...bodyData,
                    },
                };
                console.log("Datos a enviar:", data);
                const response = await new Vehicles().sendInforme(data);
                if (!response.error) {
                    toast.success("Vehiculo registrado correctamente");
                } else {
                    toast.error("Error en el registro del vehiculo.");
                }
            } catch (error) {
                console.error(error);
                toast.error("Error al guardar el formulario");
            } finally {
                isLoading.value = false;
            }
        };
        onMounted(() => {
            CargarEmpresa();
            CargarVehiculos();

            saveFuelImage();
            const carImgElement = document.querySelector(
                ".car-image"
            ) as HTMLImageElement;

            carImgElement?.addEventListener("load", () => {
                if (canvas.value && carImgElement) {
                    canvas.value.width = carImgElement.clientWidth;
                    canvas.value.height = carImgElement.clientHeight;
                }

                const ctx = canvas.value?.getContext("2d");
                if (ctx) {
                    context.value = ctx as CanvasRenderingContext2D;
                    context.value.strokeStyle = "rgba(255, 0, 0, 0.17)"; // color rojo semitransparente
                    context.value.lineWidth = 6; // grosor del pincel para la imagen del carro
                    context.value.globalAlpha = 0.1; // transparencia general del trazo// desenfoque para el efecto de sombra
                    context.value.shadowColor = "rgba(255, 0, 0, 0.5)"; // sombra roja semitransparente
                }
            });
            const fuelImgElement = document.querySelector(".fuel-image") as HTMLImageElement;
            fuelImgElement?.addEventListener("load", () => {
                if (fuelCanvas.value) {
                    fuelCanvas.value.width = fuelImgElement.clientWidth;
                    fuelCanvas.value.height = fuelImgElement.clientHeight;
                }
                const fuelCtx = fuelCanvas.value?.getContext("2d");
                if (fuelCtx) {
                    fuelContext.value = fuelCtx;
                    fuelContext.value.strokeStyle = "rgba(0, 0, 255, 0.8)";
                    fuelContext.value.lineWidth = 2;
                }
            });
        });

        return {
            isLoading,
            submitForm,
            kilinicial,
            date,
            kilfinal,
            neconomico,
            area,
            modelo,
            conductor,
            placas,
            licencia,
            tlicencia,
            vencimiento,
            vigenpoliza,
            tarjetaCirculacion,
            otroDocumento,
            uuidsel,
            uuidsel1,
            uuidsel2,
            cintsegur,
            inspectionRows,
            exteriorInspectionRows,
            motorInspectionRows,
            toolsInspectionRows,
            tiresInspectionRows,
            bodyInspectionRows,
            canvas,
            startDrawing,
            draw,
            stopDrawingAndSave,
            saveImage,
            porcllantas,
            controlAcceso,
            numeroControl,
            observacionesGenerales,
            comentariosAdicionales,
            danioSeleccionado,
            imageBase64,
            imageContentType,
            fuelCanvas,
            startDrawingFuel,
            drawFuel,
            stopDrawingFuel,
            saveFuelImage,
            drawLine,
            stopDrawing,
            selectedEmpresa,
            selectedVehicle,
            empresas,
            vehiculos
        };
    },
});
</script>
<style scoped>
.canvas-container {
    position: relative;
    display: inline-block;
}

.car-image {
    width: 100%;
    display: block;
}

.drawing-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    cursor: crosshair;
}

.position-relative {
    position: relative;
}

.fuel-image {
    width: 100%;

    display: block;
}

.drawing-canvas-fuel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    cursor: crosshair;
}
</style>