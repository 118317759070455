import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "card mb-15 border-0 rounded-0 bg-white stats-box" }
const _hoisted_3 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "title ms-15" }
const _hoisted_6 = { class: "d-block mb-7 fs-16 text-uppercase fw-black fw-medium text-lg-start span-title warehouse-name" }
const _hoisted_7 = { class: "fw-black mb-8 lh-1 h4-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warehouseList, (warehouse) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-xl-6 col-lg-6 col-xxxl-6 col-sm-6",
          key: warehouse.almacen
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "icon position-relative rounded-circle text-center text-primary" }, [
                  _createElementVNode("i", { class: "flaticon-sterile-box" })
                ], -1)),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(warehouse.almacen), 1),
                  _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.formatLiters(Number(warehouse.sum_general_litros)) || 0) + " Lts ", 1)
                ])
              ])
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}