<template>
  <div class="row">
    <div class="col-md-9 ">
      <div class="card mb-25 border-0 rounded-0 bg-white calendar-card">
        <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
          <div id="calendar" class="card-events"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <TodaysSchedule />
    </div>

    <ModalContentEdit v-model:visible="modalVisible" :task="taskList" @close="closeModal" />
    <ModalContentEditEvent v-model:visible="modalEventVisible" :event="eventData" @close="closeEventModal" />
    <ModalContentEditRuteo v-model:visible="modalRuteoVisible" :ruteo="ruteoData" @close="closeRuteoModal" />
    <ModalContentEditVenta v-model:visible="modalVentaVisible" :venta="ventaData" @close="closeVentaModal" />
    <ModalContentEditCobranza v-model:visible="modalCobranzaVisible" :cobranza="cobranzaData"
      @close="closeCobranzaModal" />
    <ModalContentEditVentCobra v-model:visible="modalVentCobraVisible" :ventcobra="ventacobraData"
      @close="closeVentCobraModal" />
    <ModalContentEditProspecccion v-model:visible="modalProspeccionVisible" :prospeccion="prospeccionData"
      @close="closeProspeccionModal" />
    <ModalContentEditVisitaSeguimiento v-model:visible="modalVisitaSeguimientoVisible"
      :visitaSeguimiento="visitaSeguimientoData" @close="closeVisitaSeguimientoModal" />
    <ModalContentEditVisitaTecnica v-model:visible="modalVisitaTecnicaVisible" :visitaTecnica="visitaTecnicaData"
      @close="closeVisitaTecnicaModal" />
    <ModalContentEditVisitaReactivacion v-model:visible="modalVisitaReactivacionVisible"
      :visitaReactivacion="visitaReactivacionData" @close="closeVisitaReactivacionModal" />
    <ModalContentEditCapacitacion v-model:visible="modalCapacitacionVisible" :capacitacion="capacitacionData"
      @close="closeCapacitacionModal" />
    <ModalContentEditEntregaProducto v-model:visible="modalEntregaProductoVisible"
      :entregaProducto="entregaProductoData" @close="closeEntregaProductoModal" />
    <ModalContentEditInspeccion v-model:visible="modalInspeccionVisible" :inspeccion="inspeccionData"
      @close="closeInspeccionModal" />


    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import TodaysSchedule from "./TodaysSchedule.vue";
import createSocketService from "@/services/socketService";
import { Unidad, Tag, taskListResponse } from "@/interfaces/interfaces";
import esLocale from "@fullcalendar/core/locales/es";
import { useToast } from "vue-toastification";
import ModalContentEdit from "./ModalContentEdit.vue";
import moment from "moment";
import ModalContentEditEvent from "./ModalContentEditEvent.vue";
import ModalContentEditRuteo from "./ModalContentEditRuteo.vue";
import ModalContentEditVenta from "./ModalContentEditVenta.vue";
import {
  ruteoData, ventaData, eventData, cobranzaData, ventacobraData, prospeccionData,
  visitaSeguimientoData, visitaTecnicaData, visitaReactivacionData, capacitacionData,
  entregaProductoData, inspeccionData
} from "./ConstReactivos";
import ModalContentEditCobranza from "./ModalContentEditCobranza.vue";
import ModalContentEditVentCobra from "./ModalContentEditVentCobra.vue";
import ModalContentEditProspecccion from "./ModalContentEditProspecccion.vue";
import ModalContentEditVisitaSeguimiento from "./ModalContentEditVisitaSeguimiento.vue";
import ModalContentEditVisitaTecnica from "./ModalContentEditVisitaTecnica.vue";
import ModalContentEditVisitaReactivacion from "./ModalContentEditVisitaReactivacion.vue";
import ModalContentEditCapacitacion from "./ModalContentEditCapacitacion.vue";
import ModalContentEditEntregaProducto from "./ModalContentEditEntregaProducto.vue";
import ModalContentEditInspeccion from "./ModalContentEditInspeccion.vue";
import LocalStorageValue from "@/services/localStorageService";
import User from "@/services/userService";


export default defineComponent({
  components: {
    TodaysSchedule,
    ModalContentEdit,
    ModalContentEditEvent,
    ModalContentEditRuteo,
    ModalContentEditVenta,
    ModalContentEditCobranza,
    ModalContentEditVentCobra,
    ModalContentEditProspecccion,
    ModalContentEditVisitaSeguimiento,
    ModalContentEditVisitaTecnica,
    ModalContentEditVisitaReactivacion,
    ModalContentEditCapacitacion,
    ModalContentEditEntregaProducto,
    ModalContentEditInspeccion
  },
  setup() {
    const toast = useToast();
    let socketService: any = null;
    const selectedTask = ref(null);
    const newComment = ref("");
    const newEvent = ref({
      title: "",
      description: "",
      start: "",
      startTime: "",
      end: "",
      endTime: "",
    });

    const selectedPartner = ref("");
    const selectedTag = ref("");
    const partners = ref<Unidad[]>([]);
    const tags = ref<Tag[]>([]);
    const selectedPriority = ref("");
    const priorities = ref<Unidad[]>([]);
    const taskList = ref<taskListResponse[]>([]);

    const modalVisible = ref(false);
    const modalEventVisible = ref(false);
    const modalRuteoVisible = ref(false);
    const modalVentaVisible = ref(false);
    const modalCobranzaVisible = ref(false);
    const modalVentCobraVisible = ref(false);
    const modalProspeccionVisible = ref(false);
    const modalVisitaSeguimientoVisible = ref(false);
    const modalVisitaTecnicaVisible = ref(false);
    const modalVisitaReactivacionVisible = ref(false);
    const modalCapacitacionVisible = ref(false);
    const modalEntregaProductoVisible = ref(false);
    const modalInspeccionVisible = ref(false);
    const localStorageValue = new LocalStorageValue();

    const isLoading = ref(false);

    const getAsedorPartner = async () => {
      try {
        const roles = await localStorageValue.getRol();
        const user = await localStorageValue.getUserData();
        const unicoRol = Array.isArray(roles) && roles.length > 0 ? roles[0] : null;
        const data = {
          uRol: unicoRol?.uuidRol,
          key: user,
        };

        if (user) {
          socketService.emit("join-room", { room: user });
        } else {
          console.warn("No se encontró un usuario válido para emitir el evento join-room.");
        }
      } catch (error) {
        console.error("Error en getAsesorPartner:", error);
      }
    };



    const openInspeccionModal = async (inspeccionDataProp) => {
      isLoading.value = true;
      inspeccionData.value = { ...inspeccionDataProp };
      modalInspeccionVisible.value = false;

      await nextTick();
      modalInspeccionVisible.value = true;
      isLoading.value = false;
    };

    const closeInspeccionModal = () => {
      modalInspeccionVisible.value = false;
    };




    const openEntregaProductoModal = async (entregaProductoDataProp) => {
      isLoading.value = true;
      entregaProductoData.value = { ...entregaProductoDataProp };
      modalEntregaProductoVisible.value = false;

      await nextTick();
      modalEntregaProductoVisible.value = true;
      isLoading.value = false;
    };

    const closeEntregaProductoModal = () => {
      modalEntregaProductoVisible.value = false;
    };


    const openCapacitacionModal = async (capacitacionDataProp) => {
      isLoading.value = true;
      capacitacionData.value = { ...capacitacionDataProp };
      modalCapacitacionVisible.value = false;

      await nextTick();
      modalCapacitacionVisible.value = true;
      isLoading.value = false;
    };

    const closeCapacitacionModal = () => {
      modalCapacitacionVisible.value = false;
    };


    const openVisitaReactivacionModal = async (reactivacionDataProp) => {
      isLoading.value = true;
      visitaReactivacionData.value = { ...reactivacionDataProp };
      modalVisitaReactivacionVisible.value = false;

      await nextTick();
      modalVisitaReactivacionVisible.value = true;
      isLoading.value = false;
    };

    const closeVisitaReactivacionModal = () => {
      modalVisitaReactivacionVisible.value = false;
    };

    const openVisitaSeguimientoModal = async (seguimientoDataProp) => {
      isLoading.value = true;
      visitaSeguimientoData.value = { ...seguimientoDataProp };
      console.log("esto manda al abrir", visitaSeguimientoData);

      console.log("El modal se esta abreiendo"
      );

      modalVisitaSeguimientoVisible.value = false;

      await nextTick();
      modalVisitaSeguimientoVisible.value = true;
      isLoading.value = false;
    };

    const closeVisitaSeguimientoModal = () => {
      modalVisitaSeguimientoVisible.value = false;
    };


    const openVisitaTecnicaModal = async (tecnicaDataProp) => {
      isLoading.value = true;
      visitaTecnicaData.value = { ...tecnicaDataProp };
      modalVisitaTecnicaVisible.value = false;

      await nextTick();
      modalVisitaTecnicaVisible.value = true;
      isLoading.value = false;
    };

    const closeVisitaTecnicaModal = () => {
      modalVisitaTecnicaVisible.value = false;
    };



    const openVentaModal = async (ventaDataProp) => {
      isLoading.value = true;
      ventaData.value = { ...ventaDataProp };
      modalVentaVisible.value = false;

      await nextTick();
      modalVentaVisible.value = true;
      isLoading.value = false;
    };

    const closeVentaModal = () => {
      modalVentaVisible.value = false;
    };

    const openCobranzaModal = async (cobranzaDataProp) => {
      isLoading.value = true;
      cobranzaData.value = { ...cobranzaDataProp };
      modalCobranzaVisible.value = false;

      await nextTick();
      modalCobranzaVisible.value = true;
      isLoading.value = false;
    };

    const closeCobranzaModal = () => {
      modalCobranzaVisible.value = false;
    };

    const openProspeccionModal = async (prospeccionDataProp) => {
      isLoading.value = true;
      prospeccionData.value = { ...prospeccionDataProp };
      modalProspeccionVisible.value = false;

      await nextTick();
      modalProspeccionVisible.value = true;
      isLoading.value = false;
    };

    const closeProspeccionModal = () => {
      modalProspeccionVisible.value = false;
    };

    const openVentCobraModal = async (ventcobraDataProp) => {
      isLoading.value = true;
      ventacobraData.value = { ...ventcobraDataProp };
      modalVentCobraVisible.value = false;

      await nextTick();
      modalVentCobraVisible.value = true;
      isLoading.value = false;
    };

    const closeVentCobraModal = () => {
      modalVentCobraVisible.value = false;
    };

    const openRuteoModal = async (ruteoDataProp) => {
      isLoading.value = true;
      ruteoData.value = { ...ruteoDataProp };
      modalRuteoVisible.value = false;

      await nextTick();
      modalRuteoVisible.value = true;
      isLoading.value = false;
    };

    const closeRuteoModal = () => {
      modalRuteoVisible.value = false;
    };

    const openModal = async (taskData) => {
      try {
        isLoading.value = true;
        if (taskData) {

          taskList.value = taskData;
          modalVisible.value = false;

          await nextTick();
          modalVisible.value = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false
      }

    };

    const closeModal = () => {
      modalVisible.value = false;
    };

    const openEventModal = async (eventDataProp) => {
      isLoading.value = true;
      eventData.value = { ...eventDataProp };
      modalEventVisible.value = false;

      await nextTick();
      modalEventVisible.value = true;
      isLoading.value = false;

    };

    const closeEventModal = () => {
      modalEventVisible.value = false;
    };

    onMounted(async () => {
      socketService = await createSocketService();
      socketService.connect();

      await getAsedorPartner();


      const calendarEl = document.getElementById("calendar");
      if (calendarEl) {
        const calendar = new Calendar(calendarEl, {
          plugins: [dayGridPlugin, timeGridPlugin],
          timeZone: "UTC",
          headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          },
          locale: "es",
          locales: [esLocale],
          initialDate: new Date(),
          navLinks: true,
          editable: true,
          dayMaxEventRows: 5,
          events: [],
          datesSet: function (info) {
            const currentViewDate = new Date(info.start);
            currentViewDate.setMonth(currentViewDate.getMonth() + 1);
            const firstDayOfMonth = new Date(
              currentViewDate.getFullYear(),
              currentViewDate.getMonth(),
              1
            );
            const lastDayOfMonth = new Date(
              currentViewDate.getFullYear(),
              currentViewDate.getMonth() + 1,
              0
            );

            lastDayOfMonth.setHours(23, 59, 59, 999);

            const formattedStartDate = moment(firstDayOfMonth).format(
              "YYYY-MM-DD HH:mm:ss.SSSSSS"
            );
            const formattedEndDate = moment(lastDayOfMonth).format(
              "YYYY-MM-DD HH:mm:ss.SSSSSS"
            );

            const fechadata = {
              fecha_inicio: formattedStartDate,
              fecha_fin: formattedEndDate,
            };

            isLoading.value = true;

            socketService.emit("list-task-user", fechadata);

            socketService.on("list-task-user", (response: any) => {
              try {
                if (!response.error) {
                  const tasks = response.response;

                  const events = tasks.map((task: any) => {
                    const startDate = moment.utc(task.fecha_inicio).toDate();

                    const endDate = task.fecha_fin ? new Date(task.fecha_fin) : startDate;

                    const title = task.actividad || task.nombre_establecimiento || task.cliente || "Sin título";

                    return {
                      title,
                      start: startDate.toISOString(),
                      end: endDate.toISOString(),
                      uuid_tarea: task.uuid_tarea,
                      uuid_evento: task.uuid_evento,
                      uuid_ruteo: task.uuid_ruteo,
                      backgroundColor: task.color,
                      description: task.descripcion,
                    };
                  });

                  calendar
                    .getEventSources()
                    .forEach((source) => source.remove());
                  calendar.addEventSource(events);
                  calendar.render();
                }
              } catch (error) {
                console.log(error);
                toast.error("Error al cargar las tareas");
              }
              isLoading.value = false;

            });
          },

          eventClick: function (info) {
            info.jsEvent.preventDefault();
            // const activityTypeMapping = {
            //   "ab122d2e-7bef-42b4-9dc7-14a956b035cd": "venta",
            //   "3458472d-4878-4840-ad96-e0b648886ca1": "cobranza",
            //   "aafaeef5-0ecf-42a8-acf8-48b86de5059b": "venta y cobranza",
            //   "fc928301-0905-4e22-8fdf-75fbe4c4e0f1": "visita seguimiento",
            //   "5370e430-3b61-4108-9647-c9bf1fac4400": "visita reactivacion",
            //   "f9d743ff-319a-4099-9529-030f396dce12": "toma de muestras",
            //   "52bf1cad-9f6e-48be-bc86-3d1a8bb69f3b": "inspeccion",
            //   "e37b128a-1a17-4ed1-9d56-b09e5cdf3e7c": "capacitacion",
            //   "40927a64-135b-43ea-b74b-8ec2818778dc": "entrega producto",
            //   "28bd80c5-82de-4f2e-ba0b-378101962b43": "visita tecnica",
            //   "4d977788-d541-40df-8e02-8d4cf598a25b": "prospeccion"
            // };

            const openModalByActivityType = async (taskData) => {
              isLoading.value = true;
              // const activityType = activityTypeMapping[taskData.uuid_tipo_actividad];

              switch (taskData.uuid_tipo_actividad) {
                case "ab122d2e-7bef-42b4-9dc7-14a956b035cd":
                  await openVentaModal(taskData);
                  break;
                case "3458472d-4878-4840-ad96-e0b648886ca1":
                  await openCobranzaModal(taskData);
                  break;
                case "aafaeef5-0ecf-42a8-acf8-48b86de5059b":
                  await openVentCobraModal(taskData);
                  break;
                case "fc928301-0905-4e22-8fdf-75fbe4c4e0f1":
                  await openVisitaSeguimientoModal(taskData);
                  break;
                case "5370e430-3b61-4108-9647-c9bf1fac4400":
                  await openVisitaReactivacionModal(taskData);
                  break;
                case "f9d743ff-319a-4099-9529-030f396dce12":
                  await openRuteoModal(taskData);
                  break;
                case "52bf1cad-9f6e-48be-bc86-3d1a8bb69f3b":
                  await openInspeccionModal(taskData);
                  break;
                case "e37b128a-1a17-4ed1-9d56-b09e5cdf3e7c":
                  await openCapacitacionModal(taskData);
                  break;
                case "40927a64-135b-43ea-b74b-8ec2818778dc":
                  await openEntregaProductoModal(taskData);
                  break;
                case "28bd80c5-82de-4f2e-ba0b-378101962b43":
                  await openVisitaTecnicaModal(taskData);
                  break;
                case "4d977788-d541-40df-8e02-8d4cf598a25b":
                  await openProspeccionModal(taskData);
                  break;
                default:
                  toast.warning("No hay un modal definido para esta actividad");
                  break;
              }

              isLoading.value = false;
            };

            const uuidRuteo = info.event.extendedProps.uuid_ruteo || "";
            isLoading.value = true;

            socketService.emit("task-user", { uuid_ruteo: uuidRuteo });

            socketService.once("task-user", async (response) => {
              try {
                if (!response.error) {
                  await openModalByActivityType(response.response);
                } else {
                  toast.error("Error al obtener datos de la tarea o evento o ruteo");
                }
              } catch (error) {
                console.log("Error al manejar la respuesta:", error);
              } finally {
                isLoading.value = false;
              }
            });


            setTimeout(() => {
              const modal = document.querySelector('.fc-more-popover') as HTMLElement;
              if (modal) {
                modal.style.display = 'none';
              }
            }, 100);
          },



          eventContent: function (info) {
            let containerEl = document.createElement('div');
            const event = info.event as any;

            containerEl.style.backgroundColor = event.backgroundColor || event.color || '#3788d8';
            containerEl.style.border = 'none';
            containerEl.style.color = 'black';
            containerEl.style.padding = '2px 4px';
            containerEl.style.margin = '0';
            containerEl.style.overflow = 'hidden';
            containerEl.style.whiteSpace = 'nowrap';
            containerEl.style.textOverflow = 'ellipsis';
            containerEl.style.maxWidth = '100%';
            containerEl.style.borderRadius = '0';
            let titleEl = document.createElement('span');
            titleEl.innerText = info.event.title;
            titleEl.style.overflow = 'hidden';
            titleEl.style.whiteSpace = 'nowrap';
            titleEl.style.textOverflow = 'ellipsis';

            containerEl.appendChild(titleEl);

            return { domNodes: [containerEl] };
          },


        });
        calendar.render();
      }
    });

    return {
      selectedTask,
      newComment,
      newEvent,
      selectedPartner,
      selectedTag,
      partners,
      tags,
      selectedPriority,
      priorities,
      taskList,
      openModal,
      modalVisible,
      closeModal,
      modalEventVisible,
      eventData,
      closeEventModal,
      isLoading,
      modalRuteoVisible,
      ruteoData,
      closeRuteoModal,
      modalVentaVisible,
      closeVentaModal,
      openVentaModal,
      ventaData,
      closeCobranzaModal,
      openCobranzaModal,
      modalCobranzaVisible,
      cobranzaData,
      openVentCobraModal,
      closeVentCobraModal,
      modalVentCobraVisible,
      ventacobraData,
      closeProspeccionModal,
      prospeccionData,
      modalProspeccionVisible,
      modalVisitaSeguimientoVisible,
      visitaSeguimientoData,
      openVisitaSeguimientoModal,
      closeVisitaSeguimientoModal,
      modalVisitaTecnicaVisible,
      visitaTecnicaData,
      openVisitaTecnicaModal,
      closeVisitaTecnicaModal,
      modalVisitaReactivacionVisible,
      visitaReactivacionData,
      openVisitaReactivacionModal,
      closeVisitaReactivacionModal,
      modalCapacitacionVisible,
      capacitacionData,
      openCapacitacionModal,
      closeCapacitacionModal,
      modalEntregaProductoVisible,
      entregaProductoData,
      openEntregaProductoModal,
      closeEntregaProductoModal,
      modalInspeccionVisible,
      inspeccionData,
      openInspeccionModal,
      closeInspeccionModal,
    };
  },
});
</script>

<style>
.fc-more-popover {
  max-height: 300px;
  overflow-y: auto;
}

.card-events {
  height: 730px !important;
  overflow: hidden;
}
</style>
