<template>
  <div class="card mb-25 border-0 rounded-0 bg-white expected-earnings-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <span class="d-block mb-6 fs-15 text-uppercase fw-medium fw-black">
        CONTADO
      </span>
      <h4 class="fw-black mb-12 lh-1">{{ formatLiters(sum_general_litros) || 0 }} Lts</h4>
      <span class="fw-bold text-success text-badge d-inline-block">
        <!-- {{ percentageToGoal.toFixed(2) }}%
        <i class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"></i> -->
      </span>
      <ul class="list ps-0 mb-0 list-unstyled mt-15">
        <li class="text-muted position-relative fw-medium fs-15">
          N. Facturas - <span class="text-black fw-bold">{{ cantidad_facturas || 0 }}</span>
        </li>
        <!-- <li class="text-muted position-relative fw-medium">
          Total facturación - <span class="text-black fw-bold">{{ formatCurrency(sum_general_total) || 0 }}</span>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";

export default defineComponent({
  name: "ExpectedEarnings",
  props: {
    dataArray: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const sum_general_total = ref(0);
    const cantidad_facturas = ref(0);
    const sum_general_litros = ref(0);
    const GOAL = 5000;

    const showData = async (data) => {
      if (data && Array.isArray(data.seccionado)) {
        const seccionadoPUE = data.seccionado.filter(item => item.tipo === "PPD")[0];

        if (seccionadoPUE) {
          sum_general_litros.value = seccionadoPUE.total_litros || 0;
          cantidad_facturas.value = seccionadoPUE.cantidad_facturas || 0;
          sum_general_total.value = seccionadoPUE.total_facturas || 0;
        } else {
          sum_general_litros.value = 0;
          cantidad_facturas.value = 0;
          sum_general_total.value = 0;
        }
      }
    }


    const percentageToGoal = computed(() => {
      const remainingLiters = GOAL - sum_general_litros.value;
      return (remainingLiters > 0) ? (remainingLiters / GOAL) * 100 : 0;
    });

    const formatCurrency = (amount: number): string => {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(amount);
    };


    const formatLiters = (value) => {
      if (isNaN(value)) return "0"; // Manejo de valores no numéricos
      return new Intl.NumberFormat("es-MX", {
        minimumFractionDigits: 1, // Siempre muestra un decimal
        maximumFractionDigits: 1, // No muestra más de un decimal
      }).format(value);
    };


    watch(
      () => props.dataArray,
      async (newData) => {
        await showData(newData);
      },
      { immediate: true }
    );


    return {
      sum_general_litros,
      cantidad_facturas,
      sum_general_total,
      percentageToGoal,
      formatCurrency,
      formatLiters
    }
  }
});
</script>
