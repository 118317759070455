<template>
  <div class="card mb-25 border-0 rounded-0 bg-white sales-pos-location-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div class="mb-15 mb-sm-0 d-sm-flex align-items-center justify-content-between">
        <div class="title">
          <h5 class="card-title fw-black mb-0">Top 5 Agentes</h5>
          <h6 class="card-title fw-black mb-10">{{ formatLiters(totalSales) }} Lts</h6>
        </div>
      </div>
      <div id="agentSalesChart" class="chart">
        <apexchart type="bar" height="375" :options="agentSalesChart" :series="sales"></apexchart>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "AgentSales",
  props: {
    dataAgent: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const sales = ref<{ name: string; data: number[] }[]>([]);
    const totalSales = ref(0);

    const agentSalesChart = ref({
      chart: {
        type: "bar",
        height: 375,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      colors: ["#6560F0"],
      xaxis: {
        categories: [] as string[],
        labels: {
          style: {
            colors: "#9C9AB6",
            fontSize: "16px",
            fontWeight: "bold",
          },
        },
      },
      yaxis: {
        title: {
          text: "",
        },
        labels: {
          formatter: (val: number) => val + "L",
        },
      },
      tooltip: {
        y: {
          formatter: (val: number) => val + "L",
        },
      },
    });

    const showData = async (data) => {
      if (data) {
        // Mapeo de datos de los agentes
        const salesData = data.map((agent: any) => ({
          name: agent.nombreAgente,
          total: parseFloat(agent.sum_general_litros) || 0,
        }));

        // Calcula el total de ventas de todos los agentes
        totalSales.value = salesData
          .reduce((acc: number, agent: any) => acc + agent.total, 0);


        const topSalesData = salesData
          .sort((a: any, b: any) => b.total - a.total)
          .slice(0, 5);

        sales.value = [
          {
            name: "Litro total",
            data: topSalesData.map((agent: any) => agent.total),
          },
        ];

        // Asigna los nombres de los mejores 5 agentes a `categories` en el eje x
        agentSalesChart.value = {
          ...agentSalesChart.value,
          xaxis: {
            ...agentSalesChart.value.xaxis,
            categories: topSalesData.map((agent: any) => {
              if (!agent.name) {
                return "";
              }
              const nameParts = agent.name.split(" ");
              return nameParts.slice(0, 2).join(" ");
            })

          },
        };
      }
    };

    const formatLiters = (value) => {
      if (isNaN(value)) return "0"; // Manejo de valores no numéricos
      return new Intl.NumberFormat("es-MX", {
        minimumFractionDigits: 1, // Siempre muestra un decimal
        maximumFractionDigits: 1, // No muestra más de un decimal
      }).format(value);
    };


    watch(
      () => props.dataAgent,
      async (newData) => {
        await showData(newData);
      },
      { immediate: true }
    );

    return {
      sales,
      totalSales,
      agentSalesChart,
      formatLiters
    };
  },
});
</script>
