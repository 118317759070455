import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing p-4" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_3 = { class: "search mb-3" }
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_InputText, {
          modelValue: _ctx.searchTerm,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
          placeholder: "Buscar por código o razón social",
          class: "w-100"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DataTable, {
          ref: "dt",
          tableStyle: "min-width: 50rem",
          value: _ctx.filteredInventory,
          paginator: true,
          rowsPerPageOptions: [5, 10, 20, 50],
          rows: 10,
          showGridlines: true,
          responsiveLayout: "scroll",
          filters: _ctx.filters,
          filterDisplay: 'row'
        }, {
          footer: _withCtx(() => [
            _createTextVNode(" Total " + _toDisplayString(_ctx.company ? _ctx.company.length : 0) + " Productos. ", 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              class: "column-table",
              field: "codigo_cliente",
              header: "Código",
              filter: true,
              style: {"width":"10%"},
              showFilterMenu: false
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "razon_social",
              header: "Razón social",
              filter: true,
              style: {"width":"50%"},
              showFilterMenu: false
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_InputText, {
                  modelValue: _ctx.filters['razon_social'].value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters['razon_social'].value) = $event)),
                  placeholder: "Buscar nombre",
                  class: "p-column-filter column-table"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "rfc",
              header: "RFC",
              filter: true,
              style: {"width":"10%"},
              showFilterMenu: false
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_InputText, {
                  modelValue: _ctx.filters['rfc'].value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters['rfc'].value) = $event)),
                  placeholder: "Buscar",
                  class: "p-column-filter column-table"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "agente",
              header: "Agente",
              filter: true,
              style: {"width":"10%"},
              showFilterMenu: false
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.filters['agente'].value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters['agente'].value) = $event)),
                  options: _ctx.uniqueAgents,
                  optionLabel: "label",
                  optionValue: "value",
                  placeholder: "Seleccionar agente",
                  class: "p-column-filter column-table",
                  filter: true,
                  showClear: true
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "cantidad",
              header: "Cantidad",
              sortable: "",
              filter: true,
              style: {"width":"10%"},
              showFilterMenu: false
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.filters['cantidad'].value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters['cantidad'].value) = $event)),
                  options: _ctx.singleAmount,
                  optionLabel: "label",
                  optionValue: "value",
                  placeholder: "Cantidad",
                  class: "p-column-filter column-table",
                  filter: true,
                  showClear: true
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              header: "Opciones",
              style: {"width":"10%"}
            }, {
              body: _withCtx((slotProps) => [
                _createVNode(_component_Button, {
                  icon: "pi pi-plus-circle",
                  label: "Ver",
                  onClick: ($event: any) => (_ctx.seeDetails(slotProps.data.uuid)),
                  severity: "primary",
                  size: "small"
                }, null, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "filters"])
      ]),
      _createVNode(_component_DialogComponent, {
        header: "Lista de clientes",
        visible: _ctx.displayDetailsDialog,
        "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.displayDetailsDialog) = $event)),
        modal: true,
        closable: true,
        style: { width: '50vw' }
      }, {
        default: _withCtx(() => [
          (_ctx.clientesData)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_MultiSelect, {
                  modelValue: _ctx.selectedClient,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedClient) = $event)),
                  options: _ctx.clientesData,
                  optionLabel: _ctx.getLabel,
                  optionValue: "uuid",
                  placeholder: "Seleccione clientes",
                  filter: true,
                  multiple: true,
                  class: "w-100"
                }, null, 8, ["modelValue", "options", "optionLabel"]),
                _createElementVNode("div", _hoisted_5, [
                  _cache[8] || (_cache[8] = _createElementVNode("h5", null, "Clientes Seleccionados", -1)),
                  (_ctx.selectedClient && _ctx.selectedClient.length > 0)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedClient, (client, index) => {
                          return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(_ctx.getClientName(client)), 1))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_7, "No hay clientes seleccionados."))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    type: "button",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.saveCustomerData())),
                    class: "btn btn-sm btn-primary"
                  }, "Guardar")
                ])
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}