<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />
      <div class="d-sm-flex align-items-center">
        <h5 class="text-center">Listado de clientes</h5>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="search mb-3">
        <InputText v-model="searchTerm" placeholder="Buscar por código de cliente o razón social" class="w-100" />
      </div>

      <div class="table-responsive">
        <DataTable :value="filteredClients" showGridlines tableStyle="min-width: 50rem" paginator :rows="10"
          :rowsPerPageOptions="[5, 10, 20, 50]" :filters="filters" :filterDisplay="'row'">

          <Column class="column-table" header="Opciones" style="width: 20%">
            <template #body="slotProps">
              <div class="d-flex justify-content-start">
                <Button v-if="canEdit" icon="pi pi-pencil" class="p-button-sm p-button-warning me-1"
                  @click="editClient(slotProps.data.uuid)" />
                <Button icon="pi pi-eye" class="p-button-sm p-button-info me-1"
                  @click="seeDetails(slotProps.data.uuid)" />
                <Button icon="pi pi-trash" class="p-button-sm p-button-danger me-1"
                  @click="openConfirmDialog(slotProps.data)" />
              </div>
            </template>
          </Column>

          <Column class="column-table" field="codigo_cliente" header="Código Cliente" style="width: 20%">

            <template #filter>
              <InputText v-model="filters['codigo_cliente'].value" placeholder="Buscar nombre"
                class="p-column-filter column-table" />
            </template>
          </Column>

          <Column class="column-table" sortable field="nombre_establecimiento" header="Establecimiento"
            style="width: 35%">
            <template #filter>
              <InputText v-model="filters['nombre_establecimiento'].value" placeholder="Buscar nombre"
                class="p-column-filter column-table" />
            </template>
          </Column>

          <Column class="column-table" sortable field="razon_social" header="Razón Social" style="width: 55%">
            <template #filter>
              <InputText v-model="filters['razon_social'].value" placeholder="Buscar nombre"
                class="p-column-filter column-table" />
            </template>
          </Column>

          <Column class="column-table" field="nombre_agente_cq" header="Agente" :filter="true" style="width: 20%"
            :showFilterMenu="false">
            <template #filter>
              <Dropdown v-model="filters['nombre_agente_cq'].value" :options="uniqueAgents" optionLabel="label"
                optionValue="value" placeholder="Seleccionar agente" class="p-column-filter column-table" :filter="true"
                :showClear="true" />
            </template>
          </Column>

          <Column class="column-table" sortable field="uniclave" header="Uniclave" style="width: 30%"></Column>

          <Column header="Estatus" style="width: 20%" class="column-table">
            <template #body="slotProps">
              <span :class="{
                'text-success': slotProps.data.status === 1,
                'text-danger': slotProps.data.status === 0,
              }">
                {{ slotProps.data.status === 1 ? "Activo" : "Inactivo" }}
              </span>
            </template>
          </Column>
          <template #footer>
            Total {{ filteredClients ? filteredClients.length : 0 }} Clientes.
          </template>
        </DataTable>
      </div>
    </div>

    <DialogComponent v-model:visible="confirmDialogVisible" :modal="true" :closable="false" :style="{ width: '30vw' }">
      <p>
        ¿Estás seguro de que deseas eliminar al cliente
        <strong>{{ selectedClient?.razon_social }}</strong>?
      </p>
      <div class="d-flex justify-content-end">
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="cancelDelete" />
        <Button label="Sí" icon="pi pi-check" class="p-button-danger" @click="confirmDelete" />
      </div>
    </DialogComponent>

    <DialogComponent v-model:visible="clientInfoDialogVisible" :modal="true" :closable="true"
      :style="{ width: '50vw' }">
      <template v-if="selectedClientData">
        <h4>Información del Cliente</h4>
        <p><strong>uniclave:</strong> {{ selectedClientData.uniclave }}</p>
        <p>
          <strong>Razón Social:</strong> {{ selectedClientData.razon_social }}
        </p>
        <p><strong>Vendedor:</strong> {{ selectedClientData.vendedor }}</p>
        <p><strong>Teléfono:</strong> {{ selectedClientData.telefono }}</p>
        <p><strong>RFC:</strong> {{ selectedClientData.rfc }}</p>
        <p><strong>Calle 1:</strong> {{ selectedClientData.calle1 }}</p>
        <p>
          <strong>Número exterior:</strong> {{ selectedClientData.numero_ext }}
        </p>
        <p><strong>Colonia:</strong> {{ selectedClientData.colonia }}</p>
        <p><strong>Municipio:</strong> {{ selectedClientData.municipio }}</p>
        <p><strong>Estado:</strong> {{ selectedClientData.estado }}</p>
      </template>
    </DialogComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { useToast } from "vue-toastification";
import Actualizar from "@/services/actualizarService";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { clientesItem, customerData } from "@/interfaces/interfaces";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useRouter } from "vue-router";
import LocalStorageValue from "@/services/localStorageService";
import Dropdown from "primevue/dropdown";

export default defineComponent({
  name: "ListadoClientes",
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    Dropdown
  },
  setup() {
    const isLoading = ref(true);
    const toast = useToast();
    const clientesData = ref<clientesItem[]>([]);
    const searchTerm = ref("");
    const confirmDialogVisible = ref(false);
    const selectedClient = ref<clientesItem | null>(null);
    const router = useRouter();
    const clientInfoDialogVisible = ref(false);
    const selectedClientData = ref<customerData | null>(null);
    const canEdit = ref(false);
    const uuidToVerify = "79f08102-8d73-4bed-9185-f9a95b57d127";

    const filters = ref({
      codigo_cliente: { value: null, matchMode: "contains" },
      nombre_establecimiento: { value: null, matchMode: "contains" },
      razon_social: { value: null, matchMode: "contains" },
      rfc: { value: null, matchMode: "contains" },
      nombre_agente_cq: { value: null, matchMode: "equals" },
    });

    const getClientesData = async () => {
      try {
        isLoading.value = true;
        const response = await new Actualizar().ListClientData();
        if (!response.error) {
          clientesData.value = response.response;

          console.log(clientesData)
        }
      } catch (error) {
        toast.error("Ocurrió un error al obtener la lista de clientes");
      } finally {
        isLoading.value = false;
      }
    };

    const filteredClients = computed(() => {
      if (!searchTerm.value) {
        return clientesData.value;
      }

      return clientesData.value.filter((client) => {
        const codigoClienteMatch = client.codigo_cliente
          ? client.codigo_cliente.toString().toLowerCase().includes(searchTerm.value.toLowerCase())
          : false;

        const razonSocialMatch = client.razon_social
          ? client.razon_social.toLowerCase().includes(searchTerm.value.toLowerCase())
          : false;

        const nombreEstablecimientoMatch = client.nombre_establecimiento
          ? client.nombre_establecimiento.toLowerCase().includes(searchTerm.value.toLowerCase())
          : false;

        return codigoClienteMatch || razonSocialMatch || nombreEstablecimientoMatch;
      });

    });



    const uniqueAgents = computed(() => {
      const agents = clientesData.value.map((item) => item.nombre_agente_cq);
      const unique = [...new Set(agents)];
      return unique.map((agent) => ({ label: agent, value: agent }));
      // return 5;
    });


    const infoClientes = async (uuid: string) => {
      try {
        const response = await new Actualizar().infoClientes({ uuid });
        if (!response.error) {
          selectedClientData.value = response.datos;
          clientInfoDialogVisible.value = true;
        } else {
          toast.error("Error al obtener la información del cliente");
        }
      } catch (error) {
        toast.error("Ocurrió un error al obtener los datos del cliente");
      }
    };

    const seeDetails = (uuid: string) => {
      infoClientes(uuid);
    };

    const editClient = (uuid: string) => {
      router.push({ name: "GoogleMapsPage", params: { uuid } });
    };

    const openConfirmDialog = (client) => {
      selectedClient.value = client;
      confirmDialogVisible.value = true;
    };

    const cancelDelete = () => {
      confirmDialogVisible.value = false;
      selectedClient.value = null;
    };

    const confirmDelete = async () => {
      if (selectedClient.value && selectedClient.value.uuid) {
        try {
          const data = { uuid: selectedClient.value.uuid };
          const response = await new Actualizar().DeleteClient(data);

          if (!response.error) {
            toast.success(
              `Cliente ${selectedClient.value.razon_social} eliminado exitosamente`
            );
            clientesData.value = clientesData.value.filter(
              (c) => c.uuid !== selectedClient.value?.uuid
            );
          } else {
            toast.error("Error al eliminar el cliente");
          }
        } catch (error) {
          toast.error("Ocurrió un error al eliminar el cliente");
        } finally {
          confirmDialogVisible.value = false;
          selectedClient.value = null;
        }
      } else {
        toast.error("Cliente inválido, no se puede eliminar.");
      }
    };

    async function checkPermissions() {
      const verify = await new LocalStorageValue().getpermissions();
      canEdit.value = verify.some(
        (permission) => permission.uuidPermiso === uuidToVerify
      );
    }

    onMounted(async () => {
      await checkPermissions();
      await getClientesData();
    });

    return {
      isLoading,
      searchTerm,
      filteredClients,
      confirmDialogVisible,
      selectedClient,
      cancelDelete,
      confirmDelete,
      seeDetails,
      editClient,
      openConfirmDialog,
      clientInfoDialogVisible,
      selectedClientData,
      canEdit,
      checkPermissions,
      filters,
      uniqueAgents
    };
  },
});
</script>

<style>
.text-success {
  color: green;
}

.text-danger {
  color: red;
}
</style>
