import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white welcome-support-desk-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 pt-xl-100 pb-xl-100 ps-xl-100 ps-xl-40 letter-spacing" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-lg-8 col-md-8" }
const _hoisted_5 = { class: "content date" }
const _hoisted_6 = { class: "row list justify-content-center" }
const _hoisted_7 = { class: "col-lg-4 col-sm-4" }
const _hoisted_8 = { class: "p-15 ind bg-f2f1f9 stats-box" }
const _hoisted_9 = { class: "mb-0 fw-black text-primary fs-24" }
const _hoisted_10 = { class: "col-lg-4 col-sm-4" }
const _hoisted_11 = { class: "p-15 ind bg-faf7f7 stats-box" }
const _hoisted_12 = { class: "mb-0 fw-black text-info" }
const _hoisted_13 = { class: "col-lg-4 col-sm-4" }
const _hoisted_14 = { class: "p-15 ind bg-ecf3f2 stats-box" }
const _hoisted_15 = {
  key: 1,
  class: "fw-bold ms-11 span-sub text-success"
}
const _hoisted_16 = { class: "col-lg-4 col-md-4 text-center mt-15 mt-md-0" }
const _hoisted_17 = { class: "card-body title-alm p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_18 = { class: "d-block mb-7 fs-15 text-uppercase fw-medium fw-black" }
const _hoisted_19 = { class: "fw-black mb-8 lh-1 h4-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("span", {
              class: "d-block fw-bold text-primary mb-5 fs-5",
              id: "current-date"
            }, null, -1)),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-black-emphasis text-info fs-md-18 fs-lg-20" }, " Indicadores de ventas mensuales en tiempo real ", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "d-block mb-6 fw-medium text-black-emphasis fs-14 text-uppercase" }, " Litros vendidos ", -1)),
                  _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.formatLiters(_ctx.sum_general_litros) || 0) + " Lts", 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase" }, " Número de facturas ", -1)),
                  _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.sum_general_fact || 0), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase" }, " Progreso restante ", -1)),
                  (_ctx.global_faltante > 0)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(["fw-bold ms-11 fs-20 span-sub", {
                    'text-danger': _ctx.faltante >= 75,
                    'text-warning': _ctx.faltante < 75 && _ctx.faltante > 25,
                    'text-success': _ctx.faltante <= 25
                  }])
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.global_faltante.toFixed(1)) + "% ", 1),
                        _cache[2] || (_cache[2] = _createElementVNode("i", { class: "flaticon-down-arrow fs-12 lh-1 position-relative top-1" }, null, -1))
                      ], 2))
                    : (_openBlock(), _createElementBlock("span", _hoisted_15, " Meta cumplida "))
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.nombre_almacen || "Villahermosa"), 1),
            _createElementVNode("h4", _hoisted_19, _toDisplayString(_ctx.formatLiters(_ctx.alm_sum_general_litros) || 0) + " Lts ", 1),
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "fw-medium text-dark-emphasis span-sub" }, null, -1))
          ])
        ])
      ])
    ])
  ]))
}