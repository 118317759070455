import control from "./apiService";
import LocalStorageValue from "./localStorageService";

export default class User {
  async registerUser(data) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/registrar-usuario",
      data
    );
  }
  async ListUsers() {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-usuarios",
    );
  }

  async infoUser(uuid) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/info-usuario",
      uuid
    );
  }

  async updateUser(datosActualizados) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/actualizar-usuario",
      datosActualizados
    );
  }

  async deleteUser(data) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/desactivar-usuario",
      data
    );
  }

  async activeUser(data) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/activar-usuario",
      data
    );
  }

  async agentUserList() {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-usuarios",
      {
        "agent": true
      }
    );

  }

  async saveUserAgentRelationship(uuid, uuidUser) {
    if (!uuidUser) {
      const idUser = await new LocalStorageValue().getUserData();
      uuidUser = idUser

    }

    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/guardar-agente-partner",
      {
        "uuidPartner": uuidUser,
        "uuidAgentes": uuid
      }
    );
  }

  async partnerList() {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-partners",
    );
  }


  async listUserPartnert(data) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-partner-agentes",
      data
    );

  }
}
