<template>
  <div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-xxxl-6 col-sm-6" v-for="warehouse in warehouseList" :key="warehouse.almacen">
        <div class="card mb-15 border-0 rounded-0 bg-white stats-box">
          <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
            <div class="d-flex align-items-center">
              <div class="icon position-relative rounded-circle text-center text-primary">
                <i class="flaticon-sterile-box"></i>
              </div>
              <div class="title ms-15">
              <span
                class="d-block mb-7 fs-15 text-uppercase fw-black fw-medium text-lg-start span-title warehouse-name">
                {{ warehouse.almacen }}
              </span>
              <h4 class="fw-black mb-8 lh-1 h4-price">
                {{ formatLiters(warehouse.sum_general_litros) || 0 }} Lts
              </h4>
              <span class="fw-medium text-dark-emphasis span-sub"></span>
              <span v-if="!warehouse.metaCumplida" class="fw-bold ms-11 span-sub" :class="{
                'text-danger': warehouse.percentRemaining >= 75,
                'text-warning': warehouse.percentRemaining < 75 && warehouse.percentRemaining > 25,
                'text-success': warehouse.percentRemaining <= 25
              }">
                {{ warehouse.percentRemaining.toFixed(1) }}%
                <i class="flaticon-down-arrow fs-12 lh-1 position-relative top-1"></i>
              </span>
              <span v-else class="fw-bold ms-11 span-sub text-success">
                Meta cumplida
              </span>
            </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { warehouseIndicators } from "@/interfaces/interfaces";
export default defineComponent({
  name: "StatsBoxes",
  props: {
    dataWarehouse: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const warehouseList = ref<warehouseIndicators[]>([]);
    const metasLitros = {
      "ALM VILLAHERMOSA": 2354.5,
      "ALM VERACRUZ": 1572.5,
      "ALM POZA RICA": 1572.5,
      "ALM COATZACOALCOS": 1173,
      "ALM GUADALAJARA": 425,
      "ALM OAXACA": 1020,
      "ALM MERIDA": 425,
    };

    const porcentajeTotal = ref(0);

    const showData = async (data) => {
      warehouseList.value = data
        .filter(item => item.resultado && item.resultado.almacen !== "ALM VILLAHERMOSA")
        .map(item => {
          const { codigo, almacen, sum_general_litros } = item.resultado;

          const metaLitros = metasLitros[almacen] || 8000;
          const porcentajeMeta = sum_general_litros ? parseFloat(((sum_general_litros / metaLitros) * 100).toFixed(1)) : 0;
          const porcentajeFaltante = parseFloat((100 - porcentajeMeta).toFixed(1));
          const metaCumplida = porcentajeMeta >= 100;

          return {
            codigo: codigo || "",
            almacen: almacen || "",
            sum_general_litros: sum_general_litros || 0,
            percent: parseFloat(porcentajeMeta.toFixed(1)),
            percentRemaining: metaCumplida ? 0 : parseFloat(porcentajeFaltante.toFixed(1)),
            metaCumplida
          };
        });
    };


    const formatLiters = (value) => {
      if (isNaN(value)) return "0"; // Manejo de valores no numéricos
      return new Intl.NumberFormat("es-MX", {
        minimumFractionDigits: 1, // Siempre muestra un decimal
        maximumFractionDigits: 1, // No muestra más de un decimal
      }).format(value);
    };


    watch(
      () => props.dataWarehouse,
      async (newData) => {
        await showData(newData);
      },
      { immediate: true }
    );

    return {
      warehouseList,
      porcentajeTotal,
      formatLiters
    };
  }
});
</script>
<style scoped>
.card {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  flex: 1;
}

.warehouse-name {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 4K) */
@media (min-width: 2560px) {
  .title .span-title {
    font-size: 25px !important;
  }

  .title .span-sub {
    font-size: 23px !important
  }
}

/* ultra grandes (4K, 3840px o mayor) */
@media (min-width: 3840px) {
  .title .span-title {
    font-size: 25px !important;
  }

  .title .span-sub {
    font-size: 28px !important;
  }
}
</style>