import control from "./apiService";
import LocalStorageValue from "./localStorageService";

export default class Actualizar {
  async ListClientData() {
    const rol = await new LocalStorageValue().getRol();
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-clientes",
      {
        "uRol": rol[0].uuidRol,
      }
    );
  }

  async DeleteClient(data) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/desactivar-cliente",
      data
    );
  }
  async infoClientes(uuid) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/info-cliente",
      uuid
    );
  }

  async ListCompanyName() {
    const rol = await new LocalStorageValue().getRol();
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-razon-social",
      {
        "uRol": rol[0].uuidRol,
      }
    );
  }

  async saveCompany(uuid, uuidCustomer) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/guardar-cliente-razon-social",
      {
        "uuidRazon": uuid,
        "arrayClientes": uuidCustomer
      }
    );
  }
}
