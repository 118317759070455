<template>
  <div class="card mb-25 border-0 rounded-0 bg-white welcome-support-desk-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 pt-xl-100 pb-xl-100 ps-xl-100 ps-xl-40 letter-spacing">
      <div class="row align-items-center">
        <div class="col-lg-8 col-md-8">
          <div class="content date">
            <span class="d-block fw-bold text-primary mb-5 fs-5" id="current-date">
              <!-- {{ formattedDate }} -->
            </span>
            <p class="text-black-emphasis text-info fs-md-18 fs-lg-20">
              Indicadores de ventas mensuales en tiempo real
            </p>
            <div class="row list justify-content-center">
              <div class="col-lg-4 col-sm-4">
                <div class="p-15 ind bg-f2f1f9 stats-box">
                  <span class="d-block mb-6 fw-medium text-black-emphasis fs-14 text-uppercase">
                    Litros vendidos
                  </span>
                  <h4 class="mb-0 fw-black text-primary fs-24">{{ formatLiters(sum_general_litros) || 0 }} Lts</h4>
                </div>
              </div>
              <div class="col-lg-4 col-sm-4">
                <div class="p-15 ind bg-faf7f7 stats-box">
                  <span class="d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase">
                    Número de facturas
                  </span>
                  <h4 class="mb-0 fw-black text-info">{{ sum_general_fact || 0 }}</h4>
                </div>
              </div>
              <div class="col-lg-4 col-sm-4">
                <div class="p-15 ind bg-ecf3f2 stats-box">
                  <span class="d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase">
                    Progreso restante
                  </span>

                  <span v-if="global_faltante > 0" class="fw-bold ms-11 fs-20 span-sub" :class="{
                    'text-danger': faltante >= 75,
                    'text-warning': faltante < 75 && faltante > 25,
                    'text-success': faltante <= 25
                  }">
                    {{ global_faltante.toFixed(1) }}%
                    <i class="flaticon-down-arrow fs-12 lh-1 position-relative top-1"></i>
                  </span>

                  <span v-else class="fw-bold ms-11 span-sub text-success">
                    Meta cumplida
                  </span>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="col-lg-4 col-md-4 text-center mt-15 mt-md-0">
          <div class="card-body title-alm p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <span class="d-block mb-7 fs-15 text-uppercase fw-medium fw-black">
              {{ nombre_almacen || "Villahermosa" }}
            </span>
            <h4 class="fw-black mb-8 lh-1 h4-price">{{ formatLiters(alm_sum_general_litros) || 0 }} Lts </h4>
            <span class="fw-medium text-dark-emphasis span-sub">

            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";

export default defineComponent({
  name: "WhatHappening",
  props: {
    dataArray: {
      type: Object,
      required: true,
    },
    dataWarehouse: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const formattedDate = ref("");
    const sum_general_fact = ref(0);
    const sum_general_total = ref(0);
    const sum_general_litros = ref(0);
    const nombre_almacen = ref("");
    const alm_sum_general_litros = ref("");
    const porcentaje = ref(0);
    const faltante = ref(0);
    const meta_litros = 221000;
    const currentProgress = ref(0);
    const meta_litro_tab = 2354.5;
    const global_faltante = ref(0);

    const showData = async (data) => {
      if (Array.isArray(data)) {
        const resultadoConCodigo = data.find(item => item.resultado && item.resultado.almacen === "ALM VILLAHERMOSA");

        if (resultadoConCodigo) {
          nombre_almacen.value = resultadoConCodigo.resultado.almacen;
          alm_sum_general_litros.value = resultadoConCodigo.resultado.sum_general_litros;

          const litrosActuales = resultadoConCodigo.resultado.sum_general_litros || 0;
          const porcentajeActual = (litrosActuales / meta_litro_tab) * 100;
          const porcentajeFaltante = 100 - porcentajeActual;

          porcentaje.value = parseFloat(porcentajeActual.toFixed(1));

          faltante.value = parseFloat(porcentajeFaltante.toFixed(1));
        }
      }
    }

    const showPercentage = () => {
      const progress = Math.min((sum_general_litros.value / meta_litros) * 100, 100);
      currentProgress.value = parseFloat(progress.toFixed(2));
      global_faltante.value = parseFloat((100 - currentProgress.value).toFixed(2));

      console.log("ey")
    };

    const formatCurrency = (amount: number): string => {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(amount);
    };

    const formatLiters = (value) => {
      if (isNaN(value)) return "0"; // Manejo de valores no numéricos
      return new Intl.NumberFormat("es-MX", {
        minimumFractionDigits: 1, // Siempre muestra un decimal
        maximumFractionDigits: 1, // No muestra más de un decimal
      }).format(value);
    };


    onMounted(() => {
      const months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      // Crear fecha con zona horaria específica
      const dateFormatter = new Intl.DateTimeFormat('es-MX', {
        timeZone: 'America/Mexico_City',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });

      const currentDate = new Date();
      // Formatear la fecha con la salida deseada
      const [day, month, year] = dateFormatter.formatToParts(currentDate).map(part => part.value);
      formattedDate.value = `${day} ${months[currentDate.getMonth()]}`;
    });

    watch(
      () => props.dataArray,
      async (newData) => {
        sum_general_fact.value = newData.sum_general_fact;
        sum_general_total.value = newData.sum_general_total;
        sum_general_litros.value = newData.sum_general_litros

        await showPercentage();
      },
      { immediate: true }
    );

    watch(
      () => props.dataWarehouse,
      async (newData) => {
        await showData(newData);
      },
      { immediate: true }
    );


    return {
      formattedDate,
      sum_general_fact,
      sum_general_total,
      sum_general_litros,
      nombre_almacen,
      alm_sum_general_litros,
      porcentaje,
      faltante,
      formatCurrency,
      currentProgress,
      meta_litros,
      formatLiters,
      meta_litro_tab,
      global_faltante
    };
  },
});
</script>

<style scoped>
.row.list {
  display: flex;
  align-items: stretch;
}

.stats-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 120px;
  text-align: center;
}

.welcome-support-desk-box .list h4 {
  font-size: 24px !important;
}

/* 4K) */
@media (min-width: 2560px) {
  .date .fw-bold {
    font-size: 1.6rem !important;
  }

  .ind span {
    font-size: 19px !important
  }

  .title-alm span {
    font-size: 25px !important;
  }
}

/* ultra grandes (4K, 3840px o mayor) */
@media (min-width: 3840px) {
  .date .fw-bold {
    font-size: 1.9rem !important;
  }

  .ind span {
    font-size: 21px !important
  }
}
</style>