<template>
    <DialogComponent v-model:visible="localVisible" :style="{ width: '35rem' }">
        <template #header>
            <div class="pt-20">
                <h5 class="card-subtitle fw-bold">Venta</h5>
            </div>
        </template>
        <div class="row">
            <div class="mb-3 col-12">
                <label for="fechaInicio" class="form-label fw-medium">Creado por:</label>
                <input type="text" class="form-control" id="fechaInicio" v-model="ventaData.creador" readonly />
            </div>



            <div class="mb-3 col-6">
                <label for="fechaInicio" class="form-label fw-medium">Fecha Inicio:</label>
                <input type="text" class="form-control" id="fechaInicio" :value="formattedFecha" readonly />
            </div>

            <div class="mb-3 col-6">
                <label for="horaInicio" class="form-label fw-medium">Hora Inicio:</label>
                <input type="text" class="form-control" id="horaInicio" :value="formattedHora" readonly />
            </div>

            <!-- 
            <div class="mb-3 col-6">
                <label for="fechaFin" class="form-label fw-medium">Fecha de Fin:</label>
                <input type="datetime-local" class="form-control" id="fechaFin" v-model="formattedFechaFin" />
            </div> -->

            <div class="mb-3">
                <label for="razonSocial" class="form-label fw-medium">Cliente:</label>
                <input type="text" class="form-control" id="razonSocial" v-model="ventaData.cliente" readonly />
            </div>

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Segmento:</label>
                <input type="text" class="form-control" id="cotizado" v-model="ventaData.segmento" readonly />
            </div>
            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Giro:</label>
                <input type="text" class="form-control" id="cotizado" v-model="ventaData.giro" />
            </div>
            <div class="text-center">
                <label class="form-label fw-medium">Proyección</label>
            </div>

            <div class="mb-3 col-4">
                <label for="cotizado" class="form-label fw-medium">Cotizado Lts:</label>
                <input type="text" class="form-control" id="cotizado" v-model="ventaData.cotizado" readonly />
            </div>
            <div class="mb-3 col-4">
                <label for="cotizado" class="form-label fw-medium">Venta Lts:</label>
                <input type="text" class="form-control" id="cotizado" v-model="ventaData.venta" />
            </div>

            <div class="mb-3 col-4">
                <label for="taskTags" class="form-label fw-medium">Tipo de compra:</label>
                <Dropdown v-model="selectedTipocompra" :options="compras" optionLabel="nombre" optionValue="uuid"
                    placeholder="Seleccione un tipo de compra" class="w-100" />
            </div>

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Souvenir:</label>
                <input type="text" class="form-control" id="cotizado" v-model="ventaData.souvenir_promocion" />
            </div>

            <div class="mb-3">
                <label for="localidad" class="form-label fw-medium">Localidad:</label>
                <input type="text" class="form-control" id="localidad" v-model="ventaData.localidad" readonly />
            </div>

            <div class="mb-3">
                <label for="usuarios" class="form-label fw-medium">Usuarios:</label>
                <MultiSelect v-model="selectedUser" :options="usuarios" optionLabel="nombre" optionValue="uuid"
                    placeholder="Seleccione usuarios" filter :maxSelectedLabels="30" class="form-select-multi w-100" />
            </div>


            <div class="mb-3" v-if="ventaData.archivos?.length">
                <label class="form-label">Archivos adjuntos existentes</label>
                <ul class="list-group">
                    <li v-for="(file, index) in ventaData.archivos" :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center">
                        {{ file.nombre }}
                        <button type="button" class="btn btn-primary btn-sm" @click="openFile(file.path)">
                            Abrir
                        </button>
                    </li>
                </ul>
            </div>
            <div class="mb-3">
                <label for="eventFiles" class="form-label fw-medium">Adjuntar archivos:</label>
                <input type="file" class="form-control" id="eventFiles" multiple @change="handleFileUpload" />
            </div>
            <div class="mb-3" v-if="uploadedFiles.length">
                <label class="form-label">Archivos cargados</label>
                <ul class="list-group">
                    <li v-for="(file, index) in uploadedFiles" :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center">
                        {{ file.name }}
                        <div>
                            <button type="button" class="btn btn-danger btn-sm" @click="removeFile(index)">
                                Eliminar
                            </button>
                        </div>
                    </li>
                </ul>
            </div>


            <div class="mb-3">
                <label for="observacion" class="form-label fw-medium">Observación:</label>
                <textarea class="form-control" id="observacion" v-model="ventaData.observacion" rows="3"
                    readonly></textarea>
            </div>

            <div class="col-md-12 pt-20 text-end">
                <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">
                    Cerrar
                </button>
                <button type="button" class="btn btn-sm btn-primary text-center ms-2" @click="sendEdit">
                    Guardar Cambios
                </button>
            </div>
            <commentsSection :uuidRuteo="ventaData.uuid_ruteo" :visible="localVisible" />
        </div>
        <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    </DialogComponent>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted } from "vue";
import { VentaData } from "@/interfaces/interfaces";
import Dropdown from "primevue/dropdown";
import { useToast } from "vue-toastification";
import createSocketService from "@/services/socketService";
import commentsSection from "./commentsSection.vue";
import MultiSelect from "primevue/multiselect";

export default defineComponent({
    name: "ModalContentEditVenta",
    components: { Dropdown, commentsSection, MultiSelect },
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        venta: {
            type: Object as () => VentaData,
            required: true,
        },
    },
    emits: ["close"],
    setup(props, { emit }) {
        const localVisible = ref(props.visible);
        const ventaData = ref({ ...props.venta });
        const compras = ref([]);
        const selectedTipocompra = ref<string | null>(null);
        const isLoading = ref(false);
        const toast = useToast();
        let socketService: any = null;
        const editInProgress = ref(false);
        const formattedFechaFin = ref("");
        const uploadedFiles = ref<File[]>([]);
        const filesPreview = ref<any[]>([]);
        const usuarios = ref([]);
        const selectedUser = ref<string[]>([]);


        // const formatToBackendDate = (datetime: string) => {
        //     if (!datetime) return "";
        //     const date = new Date(datetime);

        //     if (isNaN(date.getTime())) {
        //         throw new RangeError("Invalid date value provided");
        //     }

        //     return date.toISOString().replace("T", " ").slice(0, -1);
        // };

        const closeModal = () => {
            resetModalData();
            localVisible.value = false;
            emit("close");
        };


        const resetModalData = () => {
            uploadedFiles.value = [];
            filesPreview.value = [];
            ventaData.value = { ...props.venta };
        };


        const openFile = (path: string) => {
            const baseUrl = "https://defaultappyetox.blob.core.windows.net/yetoxblob/";
            const fullUrl = `${baseUrl}${path}`;
            window.open(fullUrl, "_blank");
        };


        const handleFileUpload = (event: Event) => {
            const target = event.target as HTMLInputElement;
            if (!target.files) return;

            Array.from(target.files).forEach((file) => {
                if (!uploadedFiles.value.some((f) => f.name === file.name)) {
                    uploadedFiles.value.push(file);

                    const reader = new FileReader();
                    reader.onload = () => {
                        filesPreview.value.push({
                            tipo: file.type.startsWith("image/") ? "imagen" : "pdf",
                            nombre: file.name,
                            base64: reader.result as string,
                            contentType: file.type,
                        });
                    };
                    reader.readAsDataURL(file);
                }
            });

            target.value = "";
        };

        const removeFile = (index: number) => {
            uploadedFiles.value.splice(index, 1);
            filesPreview.value.splice(index, 1);
            toast.info("Archivo eliminado.");
        };


        const formattedFecha = computed(() => {
            return ventaData.value.fecha_inicio
                ? ventaData.value.fecha_inicio.split("T")[0]
                : "";
        });

        const formattedHora = computed(() => {
            return ventaData.value.fecha_inicio
                ? ventaData.value.fecha_inicio.split("T")[1].slice(0, 5)
                : "";
        });

        onMounted(async () => {
            try {
                socketService = await createSocketService();
                socketService.connect();

                socketService.emit("listar-tipo-compra");

                socketService.on("listar-tipo-compra", (response: any) => {
                    compras.value = response.response || [];
                    selectedTipocompra.value = ventaData.value.uuid_tipo_compra;
                });
            } catch (error) {
                toast.error("Error al cargar los tipos de compra.");
                console.error(error);
            }
            try {
                socketService = await createSocketService();
                socketService.connect();
                socketService.emit("list-users");

                socketService.on("list-users", (response: any) => {
                    usuarios.value = response.response || [];

                    if (ventaData.value.integrantes?.length > 0) {
                        selectedUser.value = ventaData.value.integrantes[0].uuid;
                    }
                });
            } catch (error) {
                toast.error("Error al cargar los usuarios.");
                console.error(error);
            }
        });

        const sendEdit = async () => {
            if (editInProgress.value) return;
            editInProgress.value = true;

            const payloadVenta = {
                uuid_ruteo: ventaData.value.uuid_ruteo,
                proyeccion: {
                    venta: ventaData.value.venta,
                    uuid_tipo_compra: selectedTipocompra.value,
                },
                giro: ventaData.value.giro,
                souvenirPromocion: ventaData.value.souvenir_promocion,
                uuid_tipo_actividad: ventaData.value.uuid_tipo_actividad,
                // fecha_fin: formattedFechaFin.value,
                subir_archivo: filesPreview.value.map((archivo) => ({
                    base64: archivo.base64,
                    contentType: archivo.contentType,
                })),
                integrantes: selectedUser.value,
            };
            console.log(payloadVenta);

            try {
                isLoading.value = true;
                socketService.emit("edit-activity", payloadVenta);

                socketService.on("edit-activity", (response: any) => {
                    if (editInProgress.value) {
                        if (!response.error) {
                            toast.success("Tarea editada correctamente.");
                            resetModalData();
                            closeModal();
                        } else {
                            toast.error("Error al editar la tarea.");
                        }

                        editInProgress.value = false;
                        isLoading.value = false;
                    }
                });
            } catch (error) {
                toast.error("Error al procesar la edición.");
                console.error(error);
                editInProgress.value = false;
                isLoading.value = false;
            }
        };

        watch(
            () => props.visible,
            (newVisible) => {
                localVisible.value = newVisible;
            },
            { immediate: true }
        );

        watch(
            () => props.venta,
            (newVentaData) => {
                if (newVentaData) {
                    ventaData.value = { ...newVentaData };
                    selectedTipocompra.value = newVentaData.uuid_tipo_compra;
                    formattedFechaFin.value = newVentaData.fecha_fin
                        ? newVentaData.fecha_fin.replace(" ", "T")
                        : "";
                    selectedUser.value = Array.isArray(newVentaData.integrantes)
                        ? newVentaData.integrantes.map((integrante) => integrante.uuid)
                        : [];

                }
            },
            { immediate: true }
        );

        return {
            localVisible,
            ventaData,
            compras,
            selectedTipocompra,
            isLoading,
            closeModal,
            sendEdit,
            formattedFecha,
            formattedHora,
            filesPreview,
            handleFileUpload,
            removeFile,
            uploadedFiles,
            openFile,
            usuarios,
            selectedUser,
        };
    },
});
</script>
