<template>
  <div>
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />

    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-head box-shadow bg-white p-20 p-md-25 p-lg-30">
        <div class="row align-items-center">
          <div class="col-md-3">
            <div class="input-group">
              <PrimeCalendar v-model="dates" selectionMode="range" placeholder="Inserta un rango de fechas"
                class="w-100 shadow-none text-black rounded-0 border-0" :showIcon="true" appendTo="body"
                dateFormat="dd/mm/yy" />
            </div>
          </div>
          <div class="col-md-3 mt-10">
            <div class="input-group">
              <PrimeDropdown v-model="selectedUser" :options="usuarios" optionLabel="nombre" optionValue="uusuario"
                placeholder="Selecciona un usuario" checkmark :highlightOnSelect="false" :showClear="true"
                class="w-100" />
            </div>
          </div>

          <div class="col-md-3 mt-10">
            <div class="input-group">
              <PrimeDropdown v-model="selectedType" :options="typeOptions" optionLabel="name" placeholder="Tipo de actividad"
                checkmark :highlightOnSelect="false" :showClear="true" class="w-100" />
            </div>
          </div>
          <div class="col-md-3 mt-10">
            <div class="input-group">
              <span class="input-group-text">
                <i class="pi pi-search"></i>
              </span>
              <input type="text" class="form-control" v-model="searchTerm" placeholder="Buscar por titulo" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="(item, index) in filteredPaginatedTasks" :key="index" class="col-md-6 col-xxl-6 col-xxxl-4 mb-4">
        <div class="card mb-25 border-0 rounded-0 letter-spacing project-card h-100">
          <div class="card-body p-20 p-md-25 p-lg-30 d-flex flex-column justify-content-between">
            <div>
              <div class="row">
                <div class="col-md-8">
                  <h5 class="mb-0 fs-14 fs-md-16 fs-lg-20 fw-bold mt-8 mt-md-10">
                    {{ item.actividad }} a {{ item.cliente }}
                  </h5>
                </div>
                <div class="col-md-4 text-end">
                  <span class="badge" :style="{ backgroundColor: item.color }">
                    -
                  </span>
                </div>
              </div>

              <ul class="info ps-0 mb-15 mb-md-20 mb-lg-30 list-unstyled">
                <li v-if="item.giro" class="text-secondary fs-md-15 fs-md-16 fw-semibold">
                  <span class="text-muted fs-12 fs-md-13 text-uppercase fw-medium">
                    Descripción:
                  </span>
                  {{ item.giro }}
                </li>
                <li class="text-secondary fs-md-15 fs-md-16 fw-semibold">
                  <span class="text-muted fs-12 fs-md-13 text-uppercase fw-medium">
                    Responsable:
                  </span>
                  {{ item.nombre_creador }}
                </li>
                <li class="text-secondary fs-md-15 fs-md-16 fw-semibold">
                  <span class="text-muted fs-12 fs-md-13 text-uppercase fw-medium">
                    Fecha de inicio:
                  </span>
                  {{ formatDate(item.fecha_inicio) }}
                </li>
                <!-- <li class="text-secondary fs-md-15 fs-md-16 fw-semibold">
                  <span class="text-muted fs-12 fs-md-13 text-uppercase fw-medium">
                    Fecha final:
                  </span>
                  {{ formatDate(item.fecha_fin) }}
                </li> -->
              </ul>

              <!-- <div v-if="item.tags && item.tags.length > 0" class="mb-3">
                <span v-for="tag in item.tags" :key="tag.uuid" class="badge bg-primary me-1">
                  {{ tag.tag }}
                </span>
              </div> -->
            </div>
            <div class="row">
              <div class="text-left col-6">
                <button class="btn btn-primary btn-sm" @click="handleCardClick(item)">
                  Ver más
                </button>
              </div>
              <div class="text-right col-6">
                <span class="text-secondary fs-md-15 fs-md-16 fw-semibold">
                  {{ item.numero_comentario }}
                  <span class="text-muted fs-12 fs-md-13 text-uppercase fw-medium">comentario(s)</span>
                </span>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

    <ModalContentEdit v-model:visible="modalVisible" :task="taskList" @close="closeModal" />
    <ModalContentEditEvent v-model:visible="modalEventVisible" :event="eventData" @close="closeEventModal" />
    <ModalContentEditRuteo v-model:visible="modalRuteoVisible" :ruteo="ruteoData" @close="closeRuteoModal" />
    <ModalContentEditVenta v-model:visible="modalVentaVisible" :venta="ventaData" @close="closeVentaModal" />
    <ModalContentEditCobranza v-model:visible="modalCobranzaVisible" :cobranza="cobranzaData"
      @close="closeCobranzaModal" />
    <ModalContentEditVentCobra v-model:visible="modalVentCobraVisible" :ventcobra="ventacobraData"
      @close="closeVentCobraModal" />
    <ModalContentEditProspecccion v-model:visible="modalProspeccionVisible" :prospeccion="prospeccionData"
      @close="closeProspeccionModal" />
    <ModalContentEditVisitaSeguimiento v-model:visible="modalVisitaSeguimientoVisible"
      :visitaSeguimiento="visitaSeguimientoData" @close="closeVisitaSeguimientoModal" />
    <ModalContentEditVisitaTecnica v-model:visible="modalVisitaTecnicaVisible" :visitaTecnica="visitaTecnicaData"
      @close="closeVisitaTecnicaModal" />
    <ModalContentEditVisitaReactivacion v-model:visible="modalVisitaReactivacionVisible"
      :visitaReactivacion="visitaReactivacionData" @close="closeVisitaReactivacionModal" />
    <ModalContentEditCapacitacion v-model:visible="modalCapacitacionVisible" :capacitacion="capacitacionData"
      @close="closeCapacitacionModal" />
    <ModalContentEditEntregaProducto v-model:visible="modalEntregaProductoVisible"
      :entregaProducto="entregaProductoData" @close="closeEntregaProductoModal" />
    <ModalContentEditInspeccion v-model:visible="modalInspeccionVisible" :inspeccion="inspeccionData"
      @close="closeInspeccionModal" />


    <PrimePaginator v-if="filteredTasks.length > rowsPerPage" :rows="rowsPerPage" :totalRecords="filteredTasks.length"
      :page="currentPage" @page="currentPage = $event.page" />

  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch, computed, nextTick } from "vue";
import createSocketService from "@/services/socketService";
import { TaskOrEvent } from "@/interfaces/interfaces";
import PrimePaginator from "primevue/paginator";
import PrimeDropdown from "primevue/dropdown";
import { useToast } from "vue-toastification";
import LocalStorageValue from "@/services/localStorageService";
import {
  ruteoData, ventaData, eventData, cobranzaData, ventacobraData, prospeccionData,
  visitaSeguimientoData, visitaTecnicaData, visitaReactivacionData, capacitacionData,
  entregaProductoData, inspeccionData
} from "@/components/Calendar/ConstReactivos";
import ModalContentEdit from "@/components/Calendar/ModalContentEdit.vue";
import ModalContentEditEvent from "@/components/Calendar/ModalContentEditEvent.vue";
import ModalContentEditRuteo from "@/components/Calendar/ModalContentEditRuteo.vue";
import ModalContentEditVenta from "@/components/Calendar/ModalContentEditVenta.vue";
import ModalContentEditCobranza from "@/components/Calendar/ModalContentEditCobranza.vue";
import ModalContentEditVentCobra from "@/components/Calendar/ModalContentEditVentCobra.vue";
import ModalContentEditProspecccion from "@/components/Calendar/ModalContentEditProspecccion.vue";
import ModalContentEditVisitaSeguimiento from "@/components/Calendar/ModalContentEditVisitaSeguimiento.vue";
import ModalContentEditVisitaTecnica from "@/components/Calendar/ModalContentEditVisitaTecnica.vue";
import ModalContentEditVisitaReactivacion from "@/components/Calendar/ModalContentEditVisitaReactivacion.vue";
import ModalContentEditCapacitacion from "@/components/Calendar/ModalContentEditCapacitacion.vue";
import ModalContentEditEntregaProducto from "@/components/Calendar/ModalContentEditEntregaProducto.vue";
import ModalContentEditInspeccion from "@/components/Calendar/ModalContentEditInspeccion.vue";
import User from "@/services/userService";

let socketService: any = null;

export default defineComponent({
  name: "ProjectsGrid",
  components: {
    PrimePaginator, PrimeDropdown,
    ModalContentEdit,
    ModalContentEditEvent,
    ModalContentEditRuteo,
    ModalContentEditVenta,
    ModalContentEditCobranza,
    ModalContentEditVentCobra,
    ModalContentEditProspecccion,
    ModalContentEditVisitaSeguimiento,
    ModalContentEditVisitaTecnica,
    ModalContentEditVisitaReactivacion,
    ModalContentEditCapacitacion,
    ModalContentEditEntregaProducto,
    ModalContentEditInspeccion
  },
  setup() {
    const tasks = ref<TaskOrEvent[]>([]);
    const searchTerm = ref("");
    const rowsPerPage = ref(6);
    const currentPage = ref(0);
    const toast = useToast();
    const selectedPriority = ref<{ name: string; code: string } | null>(null);
    const selectedType = ref<{ name: string; code: string } | null>(null);
    const isLoading = ref(true);
    const isDialogVisible = ref(true);
    const selectedComments = ref([]);
    const taskList = ref([]);
    const modalVisible = ref(false);
    const modalEventVisible = ref(false);
    const modalRuteoVisible = ref(false);
    const modalVentaVisible = ref(false);
    const modalCobranzaVisible = ref(false);
    const modalVentCobraVisible = ref(false);
    const modalProspeccionVisible = ref(false);
    const modalVisitaSeguimientoVisible = ref(false);
    const modalVisitaTecnicaVisible = ref(false);
    const modalVisitaReactivacionVisible = ref(false);
    const modalCapacitacionVisible = ref(false);
    const modalEntregaProductoVisible = ref(false);
    const modalInspeccionVisible = ref(false);
    const localStorageValue = new LocalStorageValue();
    const usuarios = ref([]);
    const selectedUser = ref<string | null>(null);



    const openInspeccionModal = async (inspeccionDataProp) => {
      isLoading.value = true;
      inspeccionData.value = { ...inspeccionDataProp };
      modalInspeccionVisible.value = false;

      await nextTick();
      modalInspeccionVisible.value = true;
      isLoading.value = false;
    };

    const closeInspeccionModal = () => {
      modalInspeccionVisible.value = false;
    };


    const openEntregaProductoModal = async (entregaProductoDataProp) => {
      isLoading.value = true;
      entregaProductoData.value = { ...entregaProductoDataProp };
      modalEntregaProductoVisible.value = false;

      await nextTick();
      modalEntregaProductoVisible.value = true;
      isLoading.value = false;
    };

    const closeEntregaProductoModal = () => {
      modalEntregaProductoVisible.value = false;
    };


    const openCapacitacionModal = async (capacitacionDataProp) => {
      isLoading.value = true;
      capacitacionData.value = { ...capacitacionDataProp };
      modalCapacitacionVisible.value = false;

      await nextTick();
      modalCapacitacionVisible.value = true;
      isLoading.value = false;
    };

    const closeCapacitacionModal = () => {
      modalCapacitacionVisible.value = false;
    };


    const openVisitaReactivacionModal = async (reactivacionDataProp) => {
      isLoading.value = true;
      visitaReactivacionData.value = { ...reactivacionDataProp };
      modalVisitaReactivacionVisible.value = false;

      await nextTick();
      modalVisitaReactivacionVisible.value = true;
      isLoading.value = false;
    };

    const closeVisitaReactivacionModal = () => {
      modalVisitaReactivacionVisible.value = false;
    };

    const openVisitaSeguimientoModal = async (seguimientoDataProp) => {
      isLoading.value = true;
      visitaSeguimientoData.value = { ...seguimientoDataProp };
      modalVisitaSeguimientoVisible.value = false;

      await nextTick();
      modalVisitaSeguimientoVisible.value = true;
      isLoading.value = false;
    };

    const closeVisitaSeguimientoModal = () => {
      modalVisitaSeguimientoVisible.value = false;
    };

    const openVisitaTecnicaModal = async (tecnicaDataProp) => {
      isLoading.value = true;
      visitaTecnicaData.value = { ...tecnicaDataProp };
      modalVisitaTecnicaVisible.value = false;

      await nextTick();
      modalVisitaTecnicaVisible.value = true;
      isLoading.value = false;
    };

    const closeVisitaTecnicaModal = () => {
      modalVisitaTecnicaVisible.value = false;
    };



    const openVentaModal = async (ventaDataProp) => {
      isLoading.value = true;
      ventaData.value = { ...ventaDataProp };
      modalVentaVisible.value = false;

      await nextTick();
      modalVentaVisible.value = true;
      isLoading.value = false;
    };

    const closeVentaModal = () => {
      modalVentaVisible.value = false;
    };

    const openCobranzaModal = async (cobranzaDataProp) => {
      isLoading.value = true;
      cobranzaData.value = { ...cobranzaDataProp };
      modalCobranzaVisible.value = false;

      await nextTick();
      modalCobranzaVisible.value = true;
      isLoading.value = false;
    };

    const closeCobranzaModal = () => {
      modalCobranzaVisible.value = false;
    };

    const openProspeccionModal = async (prospeccionDataProp) => {
      isLoading.value = true;
      prospeccionData.value = { ...prospeccionDataProp };
      modalProspeccionVisible.value = false;

      await nextTick();
      modalProspeccionVisible.value = true;
      isLoading.value = false;
    };

    const closeProspeccionModal = () => {
      modalProspeccionVisible.value = false;
    };

    const openVentCobraModal = async (ventcobraDataProp) => {
      isLoading.value = true;
      ventacobraData.value = { ...ventcobraDataProp };
      modalVentCobraVisible.value = false;

      await nextTick();
      modalVentCobraVisible.value = true;
      isLoading.value = false;
    };

    const closeVentCobraModal = () => {
      modalVentCobraVisible.value = false;
    };

    const openRuteoModal = async (ruteoDataProp) => {
      isLoading.value = true;
      ruteoData.value = { ...ruteoDataProp };
      modalRuteoVisible.value = false;

      await nextTick();
      modalRuteoVisible.value = true;
      isLoading.value = false;
    };

    const closeRuteoModal = () => {
      modalRuteoVisible.value = false;
    };

    const openModal = async (taskData) => {
      try {
        isLoading.value = true;
        if (taskData) {

          taskList.value = taskData;
          modalVisible.value = false;

          await nextTick();
          modalVisible.value = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false
      }

    };

    const closeModal = () => {
      modalVisible.value = false;
    };

    // const openEventModal = async (eventDataProp) => {
    //   isLoading.value = true;
    //   eventData.value = { ...eventDataProp };
    //   modalEventVisible.value = false;

    //   await nextTick();
    //   modalEventVisible.value = true;
    //   isLoading.value = false;

    // };

    const closeEventModal = () => {
      modalEventVisible.value = false;
    };

    const handleCardClick = async (task) => {
      try {
        isLoading.value = true;

        // const activityTypeMapping = {
        //   "ab122d2e-7bef-42b4-9dc7-14a956b035cd": "venta",
        //   "3458472d-4878-4840-ad96-e0b648886ca1": "cobranza",
        //   "aafaeef5-0ecf-42a8-acf8-48b86de5059b": "venta y cobranza",
        //   "fc928301-0905-4e22-8fdf-75fbe4c4e0f1": "visita seguimiento",
        //   "5370e430-3b61-4108-9647-c9bf1fac4400": "visita reactivacion",
        //   "f9d743ff-319a-4099-9529-030f396dce12": "toma de muestras",
        //   "52bf1cad-9f6e-48be-bc86-3d1a8bb69f3b": "inspeccion",
        //   "e37b128a-1a17-4ed1-9d56-b09e5cdf3e7c": "capacitacion",
        //   "40927a64-135b-43ea-b74b-8ec2818778dc": "entrega producto",
        //   "28bd80c5-82de-4f2e-ba0b-378101962b43": "visita tecnica",
        //   "4d977788-d541-40df-8e02-8d4cf598a25b": "prospeccion",
        // };

        const openModalByActivityType = async (taskData) => {
          // const activityType = activityTypeMapping[taskData.uuid_tipo_actividad];

          switch (taskData.uuid_tipo_actividad) {
            case "ab122d2e-7bef-42b4-9dc7-14a956b035cd":
              await openVentaModal(taskData);
              break;
            case "3458472d-4878-4840-ad96-e0b648886ca1":
              await openCobranzaModal(taskData);
              break;
            case "aafaeef5-0ecf-42a8-acf8-48b86de5059b":
              await openVentCobraModal(taskData);
              break;
            case "fc928301-0905-4e22-8fdf-75fbe4c4e0f1":
              await openVisitaSeguimientoModal(taskData);
              break;
            case "5370e430-3b61-4108-9647-c9bf1fac4400":
              await openVisitaReactivacionModal(taskData);
              break;
            case "f9d743ff-319a-4099-9529-030f396dce12":
              await openRuteoModal(taskData);
              break;
            case "52bf1cad-9f6e-48be-bc86-3d1a8bb69f3b":
              await openInspeccionModal(taskData);
              break;
            case "e37b128a-1a17-4ed1-9d56-b09e5cdf3e7c":
              await openCapacitacionModal(taskData);
              break;
            case "40927a64-135b-43ea-b74b-8ec2818778dc":
              await openEntregaProductoModal(taskData);
              break;
            case "28bd80c5-82de-4f2e-ba0b-378101962b43":
              await openVisitaTecnicaModal(taskData);
              break;
            case "4d977788-d541-40df-8e02-8d4cf598a25b":
              await openProspeccionModal(taskData);
              break;
            default:
              toast.warning("No hay un modal definido para esta actividad");
              break;
          }
        };
        socketService.emit("task-user", { uuid_ruteo: task.uuid_ruteo });


        socketService.once("task-user", async (response) => {
          if (!response.error) {

            await openModalByActivityType(response.response);
          } else {
            toast.error("Error al obtener los datos de la tarea");
          }
        });
      } catch (error) {
        console.error("Error al manejar el clic en la tarjeta:", error);
        toast.error("Error al abrir el modal");
      } finally {
        isLoading.value = true;
      }
    };

    const priority = ref([
      { name: "Todos", code: "ALL" },
      { name: "Urgente", code: "URG" },
      { name: "Alta", code: "ALTA" },
      { name: "Media", code: "MEDIA" },
      { name: "Baja", code: "BAJA" },
    ]);

    const typeOptions = ref([
      { name: "Todos", code: "all" },
      { name: "Venta", code: "ab122d2e-7bef-42b4-9dc7-14a956b035cd" },
      { name: "Cobranza", code: "3458472d-4878-4840-ad96-e0b648886ca1" },
      { name: "Venta y Cobranza", code: "aafaeef5-0ecf-42a8-acf8-48b86de5059b" },
      { name: "Prospecto", code: "4d977788-d541-40df-8e02-8d4cf598a25b" },
      { name: "Visita Seguimiento", code: "fc928301-0905-4e22-8fdf-75fbe4c4e0f1" },
      { name: "Visita Tecnica", code: "28bd80c5-82de-4f2e-ba0b-378101962b43" },
      { name: "Visita Reactivación", code: "5370e430-3b61-4108-9647-c9bf1fac4400" },
      { name: "Toma de muestras", code: "f9d743ff-319a-4099-9529-030f396dce12" },
      { name: "Inspeccion", code: "52bf1cad-9f6e-48be-bc86-3d1a8bb69f3b" },
      { name: "Capacitacion", code: "e37b128a-1a17-4ed1-9d56-b09e5cdf3e7c" },
      { name: "Entrega producto", code: "40927a64-135b-43ea-b74b-8ec2818778dc" },
    ]);

    const getCurrentMonthRange = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      return [firstDay, lastDay];
    };

    const dates = ref(getCurrentMonthRange());

    const filteredTasks = computed(() => {
      return filteredTasksByUser.value.filter((task) => {
        const matchesTitle =
          task.actividad?.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
          task.giro?.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
          task.cliente?.toLowerCase().includes(searchTerm.value.toLowerCase());

        const matchesPriority =
          !selectedPriority.value ||
          selectedPriority.value.code === "ALL" ||
          (task.prioridad && task.prioridad.toLowerCase() === selectedPriority.value.name.toLowerCase());

        const matchesType =
          !selectedType.value ||
          selectedType.value.code === "all" ||
          (task.actividad && selectedType.value.code === task.uuid?.toLowerCase());

        return matchesTitle && matchesPriority && matchesType;
      });
    });




    const filteredPaginatedTasks = computed(() => {
      const start = currentPage.value * rowsPerPage.value;
      const end = start + rowsPerPage.value;
      return filteredTasks.value.slice(start, end);
    });

    const openCommentsDialog = (comments) => {
      selectedComments.value = comments;
      isDialogVisible.value = true;
    };

    const fetchTasks = () => {
      isLoading.value = true;
      const [startDate, endDate] = dates.value;
      if (!startDate || !endDate) {
        console.error("Fechas no válidas.");
        isLoading.value = false;
        return;
      }

      const fechadata = {
        fecha_inicio: formatDateWithTime(startDate, "00:00:00.000000"),
        fecha_fin: formatDateWithTime(endDate, "23:59:59.000000"),
      };

      if (!fechadata.fecha_inicio || !fechadata.fecha_fin) {
        console.error("Error formateando las fechas.");
        isLoading.value = false;
        return;
      }
      socketService.emit("list-task-user", fechadata);
      socketService.once("list-task-user", (resp: any) => {

        if (!resp.error) {
          if (resp.response.length === 0) {
            toast.warning("No se encontró ningún dato");
          } else {
            tasks.value = resp.response;
          }
          currentPage.value = 0;
        } else {
          console.error(resp.message);
        }
        isLoading.value = false;
      });
    };

    const filteredTasksByUser = computed(() => {
      if (!selectedUser.value) {
        return tasks.value;
      }
      return tasks.value.filter(task => task.uuid_creador === selectedUser.value);
    });


    const formatDateWithTime = (date: Date | null, time: string) => {
      if (!date) {
        return null;
      }
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day} ${time}`;
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleString();
    };
    const getAsedorParnert = async () => {
      try {
        const roles = await localStorageValue.getRol();
        const user = await localStorageValue.getUserData();

        const unicorol = Array.isArray(roles) && roles.length > 0 ? roles[0] : null;
        const data = {
          uRol: unicorol?.uuidRol,
          key: user,
        };

        const response = await new User().listUserPartnert(data);

        usuarios.value = (response?.response || []).map((item) => ({
          nombre: item.nombre,
          uusuario: item.uusuario,
        }));
      } catch (error) {
        console.error("Error al traer las clasificaciones:", error);
        toast.error("Error al traer las clasificaciones");
      }
    };


    watch(dates, fetchTasks);

    return {
      tasks,
      dates,
      rowsPerPage,
      currentPage,
      filteredPaginatedTasks,
      priority,
      selectedPriority,
      selectedType,
      typeOptions,
      filteredTasks,
      searchTerm,
      fetchTasks,
      formatDate,
      isDialogVisible,
      selectedComments,
      openCommentsDialog,
      taskList,
      openModal,
      modalVisible,
      closeModal,
      modalEventVisible,
      eventData,
      closeEventModal,
      modalRuteoVisible,
      ruteoData,
      closeRuteoModal,
      modalVentaVisible,
      closeVentaModal,
      openVentaModal,
      ventaData,
      closeCobranzaModal,
      openCobranzaModal,
      modalCobranzaVisible,
      cobranzaData,
      openVentCobraModal,
      closeVentCobraModal,
      modalVentCobraVisible,
      ventacobraData,
      closeProspeccionModal,
      prospeccionData,
      modalProspeccionVisible,
      modalVisitaSeguimientoVisible,
      visitaSeguimientoData,
      openVisitaSeguimientoModal,
      closeVisitaSeguimientoModal,
      modalVisitaTecnicaVisible,
      visitaTecnicaData,
      openVisitaTecnicaModal,
      closeVisitaTecnicaModal,
      modalVisitaReactivacionVisible,
      visitaReactivacionData,
      openVisitaReactivacionModal,
      closeVisitaReactivacionModal,
      modalCapacitacionVisible,
      capacitacionData,
      openCapacitacionModal,
      closeCapacitacionModal,
      modalEntregaProductoVisible,
      entregaProductoData,
      openEntregaProductoModal,
      closeEntregaProductoModal,
      modalInspeccionVisible,
      inspeccionData,
      openInspeccionModal,
      closeInspeccionModal,
      handleCardClick,
      isLoading,
      getAsedorParnert,
      usuarios,
      selectedUser
    };
  },
  async mounted() {
    socketService = await createSocketService();
    socketService.connect();
    this.getAsedorParnert();

    this.fetchTasks();
  },
});
</script>
