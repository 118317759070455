<template>
    <div>
        <label class="form-label fw-medium">Comentarios:</label>
        <div class="comments-container mb-3" ref="commentsContainer">
            <ul v-if="comments.length" class="list-group">
                <li v-for="comment in comments" :key="comment.uuid" class="list-group-item">
                    <div>
                        <strong>{{ comment.nombre }}</strong>
                        <p>{{ comment.comentario }}</p>
                    </div>
                </li>
            </ul>
            <p v-else class="text-muted">No hay comentarios disponibles.</p>
        </div>
        <div class="input-group">
            <input
                type="text"
                class="form-control"
                placeholder="Escribe un comentario..."
                v-model="newComment"
                @keyup.enter="sendComment"
            />
            <button class="btn btn-primary btn-sm d-flex align-items-center" type="button" @click="sendComment">
                <span v-if="isCommenting" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                <span v-if="!isCommenting">Enviar</span>
                <span v-else>Enviando...</span>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, nextTick, onUnmounted } from "vue";
import { useToast } from "vue-toastification";
import createSocketService from "@/services/socketService";

export default defineComponent({
    name: "CommentsSection",
    props: {
        uuidRuteo: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const comments = ref([]);
        const newComment = ref("");
        const isCommenting = ref(false);
        const commentsContainer = ref<HTMLElement | null>(null);
        const toast = useToast();
        let socketService: any = null;
        const scrollToBottom = () => {
            if (commentsContainer.value) {
                commentsContainer.value.scrollTop = commentsContainer.value.scrollHeight;
            }
        };

        const loadComments = async () => {
            try {
                socketService = await createSocketService();
                socketService.connect();



                const payload = { uuid_ruteo: props.uuidRuteo };
                socketService.emit("list-comments-task", payload);
                const payloadtarea = { room:props.uuidRuteo };

                socketService.emit("join-room", payloadtarea);
                console.log("aqui se une desde el comentario ");
                

                socketService.on("list-comments-task", (response) => {
                    if (!response.error) {
                        comments.value = response.response || [];
                        console.log("lista de comentarios cargados", comments.value);
                        
                        nextTick(() => scrollToBottom());
                    } else {
                        toast.error("Error al cargar comentarios.");
                    }
                });
            } catch (error) {
                console.error(error);
                toast.error("Error al conectar con el servicio.");
            }
        };

        const sendComment = async () => {
            if (!newComment.value.trim()) return;
            isCommenting.value = true;

            try {
                const payload = {
                    uuid_ruteo: props.uuidRuteo,
                    comentario: newComment.value.trim(),
                };

                socketService.emit("comment-add", payload);
{
                    socketService.once("comment-add", (response) => {
                        if (!response.error) {
                            toast.success(response.message);
                            newComment.value = "";
                            nextTick(() => scrollToBottom());
                        } else {
                            toast.error("Error al agregar comentario.");
                        }
                        isCommenting.value = false;
                    });
                }
            } catch (error) {
                console.error(error);
                toast.error("Error al enviar el comentario.");
                isCommenting.value = false;
            }
        };

        const cleanListenersManually = () => {
            console.log("Cerrando el componente: limpiando listeners y recursos.");
        };

        watch(
            () => props.visible,
            (newVisible) => {
                if (newVisible) {
                    loadComments();
                    nextTick(() => scrollToBottom());
                } else {
                    console.log("aqui se cierra todo a su paso");
                    
                    cleanListenersManually();             
                }
            },
            { immediate: true }
        );

        onUnmounted(() => {
            const uuidProp =  props.uuidRuteo;
            const payload = { room: props.uuidRuteo };
            console.log("esto manda al salirse", payload);
            
            socketService.emit("leave-room", payload);
            console.log("Desmontando el componente: limpiando recursos finales.");
        });

        return {
            comments,
            newComment,
            sendComment,
            isCommenting,
            commentsContainer,
        };
    },
});
</script>

<style scoped>
.comments-container {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 1px;
    border-radius: 5px;
}

.comments-container .list-group-item {
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
}
</style>