<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing p-4">

        <div class="card-body p-15 p-sm-20 p-md-25">
            <div class="search mb-3">
                <InputText v-model="searchTerm" placeholder="Buscar por código o razón social" class="w-100" />
            </div>

            <div class="table-responsive">
                <DataTable ref="dt" tableStyle="min-width: 50rem" :value="filteredInventory" :paginator="true"
                    :rowsPerPageOptions="[5, 10, 20, 50]" :rows="10" :showGridlines="true" responsiveLayout="scroll"
                    :filters="filters" :filterDisplay="'row'">

                    <Column class="column-table" field="codigo_cliente" header="Código" :filter="true"
                        style="width: 10%" :showFilterMenu="false">
                    </Column>

                    <Column class="column-table" field="razon_social" header="Razón social" :filter="true"
                        style="width: 50%" :showFilterMenu="false">
                        <template #filter>
                            <InputText v-model="filters['razon_social'].value" placeholder="Buscar nombre"
                                class="p-column-filter column-table" />
                        </template>
                    </Column>

                    <Column class="column-table" field="rfc" header="RFC" :filter="true" style="width: 10%"
                        :showFilterMenu="false">
                        <template #filter>
                            <InputText v-model="filters['rfc'].value" placeholder="Buscar"
                                class="p-column-filter column-table" />
                        </template>
                    </Column>

                    <Column class="column-table" field="agente" header="Agente" :filter="true" style="width: 10%"
                        :showFilterMenu="false">
                        <template #filter>
                            <Dropdown v-model="filters['agente'].value" :options="uniqueAgents" optionLabel="label"
                                optionValue="value" placeholder="Seleccionar agente"
                                class="p-column-filter column-table" :filter="true" :showClear="true" />
                        </template>
                    </Column>
                    <Column class="column-table" field="cantidad" header="Cantidad" sortable :filter="true"
                        style="width: 10%" :showFilterMenu="false">
                        <template #filter>
                            <Dropdown v-model="filters['cantidad'].value" :options="singleAmount" optionLabel="label"
                                optionValue="value" placeholder="Cantidad" class="p-column-filter column-table"
                                :filter="true" :showClear="true" />
                        </template>

                    </Column>
                    <Column class="column-table" header="Opciones" style="width: 10%">
                        <template #body="slotProps">
                            <Button icon="pi pi-plus-circle" label="Ver" @click="seeDetails(slotProps.data.uuid)"
                                severity="primary" size="small"></Button>
                        </template>
                    </Column>
                    <template #footer>
                        Total {{ company ? company.length : 0 }} Productos.
                    </template>
                </DataTable>
            </div>
            <DialogComponent header="Lista de clientes" v-model:visible="displayDetailsDialog" :modal="true"
                :closable="true" :style="{ width: '50vw' }">
                <template v-if="clientesData">
                    <MultiSelect v-model="selectedClient" :options="clientesData" :optionLabel="getLabel"
                        optionValue="uuid" placeholder="Seleccione clientes" :filter="true" :multiple="true"
                        class="w-100" />
                    <div class="mt-3">
                        <h5>Clientes Seleccionados</h5>
                        <ul v-if="selectedClient && selectedClient.length > 0">
                            <li v-for="(client, index) in selectedClient" :key="index">
                                {{ getClientName(client) }}
                            </li>
                        </ul>
                        <p v-else>No hay clientes seleccionados.</p>
                    </div>
                    <div class="mt-10">
                        <button type="button" @click="saveCustomerData()"
                            class="btn btn-sm btn-primary ">Guardar</button>
                    </div>
                </template>
            </DialogComponent>
        </div>
    </div>

</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Actualizar from '@/services/actualizarService';
import { CompanyNameItem, clientesItem } from '@/interfaces/interfaces';
import { useToast } from "vue-toastification";

export default defineComponent({
    name: "UserDataTable",
    components: {
        DataTable,
        Column,
        InputText,
        Dropdown,
        Button,
    },
    setup() {
        const company = ref<CompanyNameItem[]>([]);
        const searchTerm = ref("");
        const isLoading = ref(false);
        const clientesData = ref<clientesItem[]>([]);
        const displayDetailsDialog = ref(false);
        const selectedClient = ref<string[]>([]);

        const uuidRazon = ref("");
        const toast = useToast();

        // Filtros predeterminados
        const filters = ref({
            codigo_cliente: { value: null, matchMode: "contains" },
            razon_social: { value: null, matchMode: "contains" },
            rfc: { value: null, matchMode: "contains" },
            empresa: { value: null, matchMode: "contains" },
            cantidad: { value: null, matchMode: "equals" },
            agente: { value: null, matchMode: "equals" },
        });

        const getCompanyName = async () => {
            try {
                const rspta = await new Actualizar().ListCompanyName();
                if (!rspta.error) {
                    company.value = rspta.response;
                    console.log(company.value)

                }


            } catch (error) {
                console.error("Error al obtener los clientes:", error);
            }
        };
        const seeDetails = (uuid: string) => {
            uuidRazon.value = uuid;
            selectedClient.value = [];
            displayDetailsDialog.value = true;
            initializeSelectedClients();
        };

        const initializeSelectedClients = () => {
            selectedClient.value = [];

            const assignedClients = company.value
                .filter(
                    (item) =>
                        item.uuid === uuidRazon.value &&
                        (item.cantidad ?? 0) > 0 &&
                        item.clientes
                )
                .flatMap((item) => item.clientes!.map((cliente) => cliente.uuid));

            selectedClient.value = assignedClients;

        };


        const singleAmount = computed(() => {
            const amount = company.value.map((item) => item.cantidad);
            const unique = [...new Set(amount)];
            return unique.map((agent) => ({ label: agent, value: agent }));
        });


        const uniqueAgents = computed(() => {
            const agents = company.value.map((item) => item.agente);
            const unique = [...new Set(agents)];
            return unique.map((agent) => ({ label: agent, value: agent }));
        });


        const getListClientData = async () => {
            try {
                const rspta = await new Actualizar().ListClientData();
                if (!rspta.error) {
                    clientesData.value = rspta.response;
                }

            } catch (error) {
                console.error("Error al obtener los clientes:", error);
            }
        }


        const filteredInventory = computed(() => {
            if (!searchTerm.value) {
                return company.value;
            }

            return company.value.filter(item =>
                item.razon_social?.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                item.codigo_cliente?.toLowerCase().includes(searchTerm.value.toLowerCase())
            );
        });


        const getClientName = (uuid) => {
            const client = clientesData.value.find((c) => c.uuid === uuid);

            return client ? client.razon_social : "Desconocido";
        };

        const getLabel = (client) => {
            if (!client) return "Desconocido";
            return `${ client.codigo_cliente ? client.codigo_cliente + "-": "" } ${ client.razon_social ? client.razon_social : client.nombre_establecimiento }`;
        };



        const saveCustomerData = async () => {
            try {
                const rspta = await new Actualizar().saveCompany(uuidRazon.value, selectedClient.value);

                if (!rspta.error) {
                    toast.success("Datos guardados correctamente");
                    displayDetailsDialog.value = false;
                    await getCompanyName();
                } else {
                    toast.error("No se puedo guardar correctamente")
                }
            } catch (error) {
                console.log(error)
            }
        }



        onMounted(async () => {
            try {
                isLoading.value = true;
                await getCompanyName();
                await getListClientData();
            } catch (error) {
                console.log("Se detecto un error" + error)
            } finally {
                isLoading.value = false;
            }
        });


        return {
            filters,
            filteredInventory,
            searchTerm,
            uniqueAgents,
            company,
            seeDetails,
            displayDetailsDialog,
            clientesData,
            selectedClient,
            getClientName,
            getLabel,
            saveCustomerData,
            singleAmount
        };
    },
});
</script>

<style scoped>
.datatable-demo {
    margin: 2rem;
}

h2 {
    margin-bottom: 1rem;
}

.p-column-filter {
    width: 100%;
}
</style>