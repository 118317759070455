<template>
    <div class="row">
        <div class="col-md-12">
            <InvoiceList />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import InvoiceList from "@/components/Collection/InvoiceList.vue";

export default defineComponent({
    name: "InventoryPage",
    components: {
        InvoiceList,
    },
});
</script>