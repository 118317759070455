<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
            <div class="row">
                <div class="pt-10 mb-3">
                    <div class="input-group w-100">
                        <label for="vehiculoDropdown" class="form-label fw-medium text-black">
                            Selecciona un vehículo:
                        </label>
                        <Dropdown v-model="tipovehiculos" :options="vehiculos" optionLabel="numero" optionValue="uuid"
                            placeholder="Seleccione un número de vehículo" filter class="w-100" />
                    </div>
                </div>
                <div class="mb-3">
                    <label for="fechaInicio" class="form-label fw-medium">Fecha Inicio:</label>
                    <input type="datetime-local" class="form-control" id="fechaInicio" v-model="fechaInicio" />
                </div>
                <userDropdown v-model="selectedUser" :userOptions="user" />
                <div class="pt-10 rounded-md">
                    <div class="mb-3">
                        <label for="clienteDropdown" class="form-label fw-medium">Selecciona un cliente:</label>
                        <Dropdown v-model="selectedClient" :filter="true" :options="clientes" optionLabel="razon_social"
                            optionValue="uuid" placeholder="Seleccione un cliente" class="w-100" />
                    </div>
                </div>
                <div class="mb-3">
                    <label for="ncliente" class="form-label fw-medium">Cliente:</label>
                    <input type="text" class="form-control" id="ncliente" v-model="nclientes"
                        placeholder="Escriba un cliente" />
                </div>

                <div class="mb-3">
                    <label for="localidad" class="form-label fw-medium">Localidad:</label>
                    <input type="text" class="form-control" id="localidad" v-model="localidad"
                        placeholder="Ingrese la localidad" />
                </div>
                <div class="mb-3">
                    <label for="giro" class="form-label fw-medium">Giro:</label>
                    <input type="text" class="form-control" id="giro" v-model="giro"
                        placeholder="Ingrese un giro de la empresa" />
                </div>
                <div class="pt-10 mb-3">
                    <div class="input-group w-100">
                        <label for="tipoActividadDropdown" class="form-label fw-medium text-black">
                            Selecciona tipo de actividad:
                        </label>
                        <Dropdown v-model="tipoactividad" :options="actividades" optionLabel="actividad"
                            optionValue="uuid" placeholder="Seleccione una actividad" filter class="w-100"
                            @change="updateDynamicFields" />
                    </div>
                </div>
                <div class="pt-10 mb-3">
                    <div class="input-group w-100">
                        <label for="segmentoDropdown" class="form-label fw-medium text-black">
                            Selecciona un segmento:
                        </label>
                        <Dropdown v-model="tiposegmento" :options="segmentos" optionLabel="segmento" optionValue="uuid"
                            placeholder="Seleccione un segmento" filter class="w-100" />
                    </div>
                </div>
                <div v-if="dynamicFields.includes('N° de Seguimiento')" class="mb-3 col-md-4">
                    <label for="seguimiento" class="form-label fw-medium">N° de Seguimiento:</label>
                    <input type="text" class="form-control" id="seguimiento" v-model="seguimiento"
                        placeholder="Ingrese el número de seguimiento" />
                </div>
                <div v-if="dynamicFields.includes('Cotizado Lts')" class="mb-3 col-md-4">
                    <label for="cotizadoLts" class="form-label fw-medium">Cotizado Lts:</label>
                    <input type="text" class="form-control" id="cotizadoLts" v-model="cotizadoLts"
                        placeholder="Ingrese lo cotizado en litros" />
                </div>
                <div v-if="dynamicFields.includes('Venta Lts')" class="mb-3 col-md-4">
                    <label for="ventaLts" class="form-label fw-medium">Venta Lts:</label>
                    <input type="text" class="form-control" id="ventaLts" v-model="ventaLts"
                        placeholder="Ingrese lo vendido en litros" />
                </div>
                <div v-if="dynamicFields.includes('Tipo Compra')" class="pt-10 mb-3">
                    <div class="input-group w-100">
                        <label for="tipoCompraDropdown" class="form-label fw-medium text-black">
                            Tipo de compra:
                        </label>
                        <Dropdown v-model="tipocompras" :options="compras" optionLabel="nombre" optionValue="uuid"
                            placeholder="Selecciona un tipo de compra" filter class="w-100" />
                    </div>
                </div>
                <div v-if="dynamicFields.includes('Cobranza')" class="mb-3">
                    <label for="cobranza" class="form-label fw-medium">Cobranza:</label>
                    <input type="text" class="form-control" id="cobranza" v-model="cobranza"
                        placeholder="Ingrese la cobranza" />
                </div>
                <div v-if="dynamicFields.includes('Souvenir')" class="mb-3">
                    <label for="souvenir" class="form-label fw-medium">Souvenir/Promoción:</label>
                    <input type="text" class="form-control" id="souvenir" v-model="souvenir"
                        placeholder="Ingrese algún souvenir" />
                </div>

                <div class="mb-3">
                    <label for="observacion" class="form-label fw-medium">Observación:</label>
                    <input type="text" class="form-control" id="observacion" v-model="observacion"
                        placeholder="Añade alguna observación" />
                </div>
                <div class="d-flex justify-content-end mt-4">
                    <button class="btn btn-primary" @click="submitForm">Enviar</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import moment from "moment";
import Dropdown from "primevue/dropdown";
import createSocketService from "@/services/socketService";
import { useToast } from "vue-toastification";

export default defineComponent({
    name: "NuevoModal",
    components: { Dropdown },
    setup() {
        const selectedUser = ref("");
        const segmentos = ref([]); const tipoactividad = ref<string | null>(null);
        const actividades = ref<Actividad[]>([]);

        const compras = ref([]);
        const tipovehiculos = ref<string | null>(null);
        const selectedClient = ref<string | null>(null);
        const tiposegmento = ref<string | null>(null);
        const cotizadoLts = ref<string>("");
        const ventaLts = ref<string>("");
        const vehiculos = ref<{ uuid: string; numero: string }[]>([]);
        const tipocompras = ref<string | null>(null);
        const cobranza = ref<string>("");
        const souvenir = ref<string>("");
        const seguimiento = ref<string>("");
        const localidad = ref<string>("");
        const giro = ref<string>("");
        const observacion = ref<string>("");
        const nclientes = ref<string>("");
        const dynamicFields = ref<string[]>([]);
        const toast = useToast();
        let socketService: any = null;

        interface Actividad {
            uuid: string;
            actividad: string;
            color: string;
        }


        const activityFieldMap: Record<string, string[]> = {
            venta: ["Cotizado Lts", "Venta Lts", "Tipo Compra", "Souvenir"],
            cobranza: ["Tipo Compra", "Cobranza"],
            "venta y cobranza": ["Cotizado Lts", "Venta Lts", "Tipo Compra", "Cobranza", "Souvenir"],
            prospecto: ["N° de Seguimiento"],
            "visita seguimiento": ["N° de Seguimiento", "Cotizado Lts", "Souvenir"],
            "visita tecnica": [],
            "visita reactivacion": ["Cotizado Lts", "Venta Lts", "Tipo Compra", "Souvenir"],
            "toma de muestras": [],
            inspeccion: [],
            capacitacion: [],
            "entrega producto": ["Venta Lts", "Tipo Compra"],
        };

        const updateDynamicFields = () => {
            const selectedActivity = actividades.value.find(
                (activity: Actividad) => activity.uuid === tipoactividad.value
            );
            if (selectedActivity) {
                dynamicFields.value = activityFieldMap[selectedActivity.actividad] || [];
            } else {
                dynamicFields.value = [];
            }
        };


        const submitForm = async () => {
            const formattedStartDate = moment().format("YYYY-MM-DD HH:mm:ss.SSSSSS");
            const payload = {
                uuid_asesor: selectedUser.value,
                uuid_unidad: tipovehiculos.value,
                fecha_inicio: formattedStartDate,
                uuid_cliente: selectedClient.value,
                nombre_cliente: nclientes.value || "",
                localidad: localidad.value,
                uuid_segmento: tiposegmento.value,
                proyeccion: {
                    seguimiento: dynamicFields.value.includes("N° de Seguimiento") ? seguimiento.value || "" : "",
                    cotizado: dynamicFields.value.includes("Cotizado Lts") ? cotizadoLts.value || "" : "",
                    cobranza: dynamicFields.value.includes("Cobranza") ? cobranza.value || "" : "",
                    venta: dynamicFields.value.includes("Venta Lts") ? ventaLts.value || "" : "",
                    uuid_tipo_compra: dynamicFields.value.includes("Tipo Compra") ? tipocompras.value || "" : null,
                },
                giro: giro.value,
                observacion: observacion.value,
                souvenirPromocion: dynamicFields.value.includes("Souvenir") ? souvenir.value || "" : "",
                uuid_tipo_actividad: tipoactividad.value,
                integrantes: [],
            };

            console.log("Payload enviado:", payload);

            try {
                socketService.emit("agregar-actividad-ruteo", payload);
                socketService.once("agregar-actividad-ruteo", (response) => {
                    if (response && !response.error) {
                        toast.success("Tarea enviada con éxito");
                    } else {
                        toast.error("Error al enviar la tarea. Verifique los datos.");
                    }
                });
            } catch (error) {
                console.error("Error al enviar el formulario:", error);
                toast.error("Ocurrió un error al enviar el formulario.");
            }
        };

        onMounted(() => {
            socketService = createSocketService();
            socketService.emit("listar-tipo-actividad");
            socketService.on("listar-tipo-actividad", (response: any) => {
                actividades.value = response.response || [];
            });
            socketService.emit("listado-segmento");
            socketService.on("listado-segmento", (response: any) => {
                segmentos.value = response.response || [];
            });

            socketService.emit("listado-vehiculo");
            socketService.on("listado-vehiculo", (response: any) => {
                vehiculos.value = response.response || [];
            });

            socketService.emit("listar-tipo-compra");
            socketService.on("listar-tipo-compra", (response: any) => {
                compras.value = response.response || [];
            });
        });

        return {
            tipoactividad,
            actividades,
            compras,
            tipovehiculos,
            selectedClient,
            tiposegmento,
            cotizadoLts,
            ventaLts,
            tipocompras,
            cobranza,
            souvenir,
            seguimiento,
            localidad,
            vehiculos,
            giro,
            observacion,
            segmentos,
            nclientes,
            dynamicFields,
            updateDynamicFields,
            submitForm,
        };
    },
});
</script>
