import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "mb-3 col-12" }
const _hoisted_3 = { class: "mb-3 col-6" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "mb-3 col-6" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = { class: "mb-3" }
const _hoisted_12 = { class: "mb-3" }
const _hoisted_13 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_14 = { class: "list-group" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "mb-3" }
const _hoisted_17 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_18 = { class: "list-group" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "mb-3" }
const _hoisted_21 = { class: "col-md-12 pt-20 text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_commentsSection = _resolveComponent("commentsSection")!
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!

  return (_openBlock(), _createBlock(_component_DialogComponent, {
    visible: _ctx.localVisible,
    "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localVisible) = $event)),
    style: { width: '35rem' }
  }, {
    header: _withCtx(() => _cache[12] || (_cache[12] = [
      _createElementVNode("div", { class: "pt-20" }, [
        _createElementVNode("h5", { class: "card-subtitle fw-bold" }, "Inspección")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[13] || (_cache[13] = _createElementVNode("label", {
            for: "fechaInicio",
            class: "form-label fw-medium"
          }, "Creado por:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "fechaInicio",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inspeccionData.creador) = $event)),
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.inspeccionData.creador]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[14] || (_cache[14] = _createElementVNode("label", {
            for: "fechaInicio",
            class: "form-label fw-medium"
          }, "Fecha Inicio:", -1)),
          _createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "fechaInicio",
            value: _ctx.formattedFecha,
            readonly: ""
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[15] || (_cache[15] = _createElementVNode("label", {
            for: "horaInicio",
            class: "form-label fw-medium"
          }, "Hora Inicio:", -1)),
          _createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "horaInicio",
            value: _ctx.formattedHora,
            readonly: ""
          }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[16] || (_cache[16] = _createElementVNode("label", {
            for: "razonSocial",
            class: "form-label fw-medium"
          }, "Cliente:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "razonSocial",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inspeccionData.cliente) = $event)),
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.inspeccionData.cliente]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[17] || (_cache[17] = _createElementVNode("label", {
            for: "localidad",
            class: "form-label fw-medium"
          }, "Localidad:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "localidad",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inspeccionData.localidad) = $event)),
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.inspeccionData.localidad]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[18] || (_cache[18] = _createElementVNode("label", {
            for: "giro",
            class: "form-label fw-medium"
          }, "Giro:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "giro",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inspeccionData.giro) = $event))
          }, null, 512), [
            [_vModelText, _ctx.inspeccionData.giro]
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[19] || (_cache[19] = _createElementVNode("label", {
            for: "cotizado",
            class: "form-label fw-medium"
          }, "Actividad:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "cotizado",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inspeccionData.actividad) = $event)),
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.inspeccionData.actividad]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[20] || (_cache[20] = _createElementVNode("label", {
            for: "cotizado",
            class: "form-label fw-medium"
          }, "Segmento:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "cotizado",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.inspeccionData.segmento) = $event)),
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.inspeccionData.segmento]
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[21] || (_cache[21] = _createElementVNode("label", {
            for: "usuarios",
            class: "form-label fw-medium"
          }, "Usuarios:", -1)),
          _createVNode(_component_MultiSelect, {
            modelValue: _ctx.selectedUser,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedUser) = $event)),
            options: _ctx.usuarios,
            optionLabel: "nombre",
            optionValue: "uuid",
            placeholder: "Seleccione usuarios",
            filter: "",
            maxSelectedLabels: 30,
            class: "form-select-multi w-100"
          }, null, 8, ["modelValue", "options"])
        ]),
        (_ctx.inspeccionData.archivos?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "Archivos adjuntos existentes", -1)),
              _createElementVNode("ul", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inspeccionData.archivos, (file, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    class: "list-group-item d-flex justify-content-between align-items-center"
                  }, [
                    _createTextVNode(_toDisplayString(file.nombre) + " ", 1),
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-primary btn-sm",
                      onClick: ($event: any) => (_ctx.openFile(file.path))
                    }, " Abrir ", 8, _hoisted_15)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_16, [
          _cache[23] || (_cache[23] = _createElementVNode("label", {
            for: "eventFiles",
            class: "form-label fw-medium"
          }, "Adjuntar archivos:", -1)),
          _createElementVNode("input", {
            type: "file",
            class: "form-control",
            id: "eventFiles",
            multiple: "",
            onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args)))
          }, null, 32)
        ]),
        (_ctx.uploadedFiles.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _cache[24] || (_cache[24] = _createElementVNode("label", { class: "form-label" }, "Archivos cargados", -1)),
              _createElementVNode("ul", _hoisted_18, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadedFiles, (file, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    class: "list-group-item d-flex justify-content-between align-items-center"
                  }, [
                    _createTextVNode(_toDisplayString(file.name) + " ", 1),
                    _createElementVNode("div", null, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-danger btn-sm",
                        onClick: ($event: any) => (_ctx.removeFile(index))
                      }, " Eliminar ", 8, _hoisted_19)
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_20, [
          _cache[25] || (_cache[25] = _createElementVNode("label", {
            for: "observacion",
            class: "form-label fw-medium"
          }, "Observación:", -1)),
          _withDirectives(_createElementVNode("textarea", {
            class: "form-control",
            id: "observacion",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.inspeccionData.observacion) = $event)),
            rows: "3",
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.inspeccionData.observacion]
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-sm btn-secondary text-center ms-2",
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, " Cerrar "),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-sm btn-primary text-center ms-2",
            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.sendEdit && _ctx.sendEdit(...args)))
          }, " Guardar Cambios ")
        ]),
        _createVNode(_component_commentsSection, {
          uuidRuteo: _ctx.inspeccionData.uuid_ruteo,
          visible: _ctx.localVisible
        }, null, 8, ["uuidRuteo", "visible"])
      ]),
      _createVNode(_component_VueLoading, {
        active: _ctx.isLoading,
        "is-full-page": true,
        loader: "bars",
        text: "Cargando..."
      }, null, 8, ["active"])
    ]),
    _: 1
  }, 8, ["visible"]))
}