<template>
  <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
  <div class="card mb-25 border-0 rounded-0 bg-white working-schedule-box">
    <div class="card-body card-events  p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div class="mb-15  mb-md-25 d-sm-flex align-items-center justify-content-between">
        <h5 class="card-title fw-bold mb-0">Próximos eventos</h5>
        <div class="card-buttons">
          <button type="button"
            class="default-btn transition text-white card-btn mt-20 mt-sm-0 d-inline-block fw-medium transition text-sm"
            @click="cargarDatosYMostrarModal">
            <i class="flaticon-plus"></i>
            Crear nuevo evento
          </button>
        </div>
      </div>

      <div v-if="counts.tasks === 0 && counts.events === 0" class="alert alert-info">
        No tienes tareas pendientes por hoy.
      </div>
      <ul v-else class="info-list ps-0 mb-0 list-unstyled">
        <li class="d-inline-block me-4">
          <div class="d-flex align-items-center">
            <span class="d-block w-10 h-10 bg-black rounded-circle me-8"></span>
            <span class="fw-bold">
              {{ counts.tasks }}
              <span class="text-muted fw-semibold">Tareas</span>
            </span>
          </div>
        </li>
        <li class="d-inline-block me-4">
          <div class="d-flex align-items-center">
            <span class="d-block w-10 h-10 bg-black rounded-circle me-8"></span>
            <span class="fw-bold">
              {{ counts.events }}
              <span class="text-muted fw-semibold">Eventos</span>
            </span>
          </div>
        </li>
      </ul>
      <div v-if="counts.tasks > 0 || counts.events > 0" class="schedule-list mt-15 mt-md-20"
        style="max-height: 500px; overflow-y: auto;">
        <div v-for="item in sortedItems" :key="getItemId(item)"
          class="list-item pt-15 pb-15 pt-md-20 pb-md-20 ps-20 pe-15 position-relative"
          :style="{ backgroundColor: item.color + '20' }">
          <div class="content">
            <div class="d-flex align-items-center mb-5">
              <h6 class="fw-medium mb-0">
                {{ formatDateTime(item.fecha_inicio) }}
                <!-- <span class="text-muted fs-12">{{ getTimePeriod(item.fecha_inicio) }}</span> -->
              </h6>
              <div class="ms-10 icon rounded-circle bg-white text-center position-relative"
                :style="{ color: item.color }">
                <i class="flaticon-bell-2"></i>
              </div>
            </div>
            <span class="fs-10 d-block text-black fw-semibold fs-md-15 mb-1">
              Actividad : {{ item.actividad }}
            </span>
            <span class="d-block text-black fw-semibold fs-md-12 mb-1">
              {{ item.cliente }}
            </span>
            <span v-if="isTask(item)" class="d-block text-muted fs-13 mb-1">
              {{ item.descripcion }}
            </span>
            <span class="d-block text-muted fs-12 fw-medium">creado por:
              <span :style="{ color: item.color }">{{ item.nombre_creador }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalContent v-model:visible="modalVisible" :partners="partners" :tags="tags" :priorities="priorities"
    @close="handleCloseModal" @guardarTarea="guardarTarea" />
</template>


<script lang="ts">
import { ref, onMounted, computed } from "vue";
import ModalContent from "./ModalContent.vue";
import createSocketService from "@/services/socketService";
import { Unidad, Tag, Event, Task } from "@/interfaces/interfaces";
import { useToast } from "vue-toastification";

type ScheduleItem = Event | Task;

interface Counts {
  tasks: number;
  events: number;
}

export default {
  name: "TodaysSchedule",
  components: {
    ModalContent,
  },
  setup() {
    const isLoading = ref(false);
    let socketService: any = null;
    const modalVisible = ref(false);
    const priorities = ref<Unidad[]>([]);
    const tags = ref<Tag[]>([]);
    const partners = ref<Unidad[]>([]);
    const items = ref<ScheduleItem[]>([]);

    const toast = useToast();

    const counts = computed(() => {
      const result = items.value.reduce(
        (acc, item) => {
          if (isTask(item)) {
            acc.tasks++;
          } else {
            acc.events++;
          }
          return acc;
        },
        { tasks: 0, events: 0 } as Counts
      );
      return result;
    });

    const sortedItems = computed(() => {
      return [...items.value].sort((a, b) =>
        new Date(a.fecha_inicio).getTime() - new Date(b.fecha_inicio).getTime()
      );
    });

    const isTask = (item: ScheduleItem): item is Task => {
      return 'uuid_tarea' in item;
    };

    const getItemId = (item: ScheduleItem): string => {
      return isTask(item) ? item.uuid_tarea : item.uuid_evento;
    };

    const formatTime = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: false,
        timeZone: 'America/Mexico_City' // Convertir explícitamente a la zona horaria deseada
      });
    };

    const formatDateTime = (dateString: string) => {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Mes comienza en 0
      const year = date.getUTCFullYear();

      return `${day}/${month}/${year}`;
    };

    const getTimePeriod = (dateString: string) => {
      const date = new Date(dateString);
      const hours = date.getHours();
      return hours >= 12 ? 'PM' : 'AM';
    };

    onMounted(async () => {
      socketService = await createSocketService();
      socketService.connect();

      socketService.emit("list-task-user-expiration");

      socketService.on("list-task-user-expiration", (response: any) => {
        if (!response.error && Array.isArray(response.response)) {
          console.log(response.response)
          items.value = response.response;
        } else {
          console.error("Formato inesperado en la respuesta:", response.response);
          items.value = [];
        }
      });
    });

    const handleCloseModal = () => {
      modalVisible.value = false;
    };

    const cargarDatosYMostrarModal = async () => {
      try {
        isLoading.value = true;

        if (!socketService) {
          socketService = await createSocketService();
          socketService.connect();
        }

        socketService.emit("list-users");
        socketService.emit("list-priorities");
        socketService.emit("list-tag-user");

        socketService.on("list-tag-user", (TagsData: { response: Tag[] }) => {
          tags.value = TagsData.response;
        });

        socketService.on("list-users", (partnertsData: { response: Unidad[] }) => {
          partners.value = partnertsData.response;
        });

        socketService.on("list-priorities", (prioritiesData: { unidades: Unidad[] }) => {
          priorities.value = prioritiesData.unidades;
        });

      } catch (error) {
        console.error(error);
      } finally {
        isLoading.value = false;
        modalVisible.value = true;
      }
    };

    const guardarTarea = (payload: any, status: any) => {
      try {
        if (status === 1) {
          socketService.emit("save-task-notification", payload);
          socketService.on("save-task-notification", (response: any) => {
            if (!response.error) {
              toast.success("Tarea guardada correctamente");
            } else {
              toast.error("Hubo un error al guardar la tarea");
            }
          });
        } else {
          socketService.emit("save-event-notification", payload);
          socketService.on("save-event-notification", (response: any) => {
            if (!response.error) {
              toast.success("Evento guardado correctamente");
            } else {
              toast.error("Hubo un error al guardar el evento");
            }
          });
        }
      } catch (error) {
        toast.error("Hubo un error en la solicitud");
      }
    };

    return {
      modalVisible,
      isLoading,
      cargarDatosYMostrarModal,
      priorities,
      tags,
      partners,
      items,
      sortedItems,
      counts,
      handleCloseModal,
      guardarTarea,
      formatTime,
      getTimePeriod,
      isTask,
      getItemId,
      formatDateTime
    };
  },
};
</script>

<style scoped>
.text-sm {
  font-size: 0.875rem;
}

.card-events {
  height: 730px !important;
  overflow: hidden;
}
</style>