import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25" }
const _hoisted_3 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_4 = { class: "search mb-3" }
const _hoisted_5 = { class: "table-responsive" }
const _hoisted_6 = { class: "d-flex justify-content-start" }
const _hoisted_7 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VueLoading, {
        active: _ctx.isLoading,
        "is-full-page": true,
        loader: "bars",
        text: 'Cargando...'
      }, null, 8, ["active"]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "d-sm-flex align-items-center" }, [
        _createElementVNode("h5", { class: "text-center" }, "Listado de clientes")
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputText, {
          modelValue: _ctx.searchTerm,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
          placeholder: "Buscar por código de cliente o razón social",
          class: "w-100"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_DataTable, {
          value: _ctx.filteredClients,
          showGridlines: "",
          tableStyle: "min-width: 50rem",
          paginator: "",
          rows: 10,
          rowsPerPageOptions: [5, 10, 20, 50],
          filters: _ctx.filters,
          filterDisplay: 'row'
        }, {
          footer: _withCtx(() => [
            _createTextVNode(" Total " + _toDisplayString(_ctx.filteredClients ? _ctx.filteredClients.length : 0) + " Clientes. ", 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              class: "column-table",
              header: "Opciones",
              style: {"width":"20%"}
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.canEdit)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        icon: "pi pi-pencil",
                        class: "p-button-sm p-button-warning me-1",
                        onClick: ($event: any) => (_ctx.editClient(slotProps.data.uuid))
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Button, {
                    icon: "pi pi-eye",
                    class: "p-button-sm p-button-info me-1",
                    onClick: ($event: any) => (_ctx.seeDetails(slotProps.data.uuid))
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_Button, {
                    icon: "pi pi-trash",
                    class: "p-button-sm p-button-danger me-1",
                    onClick: ($event: any) => (_ctx.openConfirmDialog(slotProps.data))
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "codigo_cliente",
              header: "Código Cliente",
              style: {"width":"20%"}
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_InputText, {
                  modelValue: _ctx.filters['codigo_cliente'].value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters['codigo_cliente'].value) = $event)),
                  placeholder: "Buscar nombre",
                  class: "p-column-filter column-table"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              sortable: "",
              field: "nombre_establecimiento",
              header: "Establecimiento",
              style: {"width":"35%"}
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_InputText, {
                  modelValue: _ctx.filters['nombre_establecimiento'].value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters['nombre_establecimiento'].value) = $event)),
                  placeholder: "Buscar nombre",
                  class: "p-column-filter column-table"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              sortable: "",
              field: "razon_social",
              header: "Razón Social",
              style: {"width":"55%"}
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_InputText, {
                  modelValue: _ctx.filters['razon_social'].value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters['razon_social'].value) = $event)),
                  placeholder: "Buscar nombre",
                  class: "p-column-filter column-table"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "nombre_agente_cq",
              header: "Agente",
              filter: true,
              style: {"width":"20%"},
              showFilterMenu: false
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.filters['nombre_agente_cq'].value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters['nombre_agente_cq'].value) = $event)),
                  options: _ctx.uniqueAgents,
                  optionLabel: "label",
                  optionValue: "value",
                  placeholder: "Seleccionar agente",
                  class: "p-column-filter column-table",
                  filter: true,
                  showClear: true
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              sortable: "",
              field: "uniclave",
              header: "Uniclave",
              style: {"width":"30%"}
            }),
            _createVNode(_component_Column, {
              header: "Estatus",
              style: {"width":"20%"},
              class: "column-table"
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("span", {
                  class: _normalizeClass({
                'text-success': slotProps.data.status === 1,
                'text-danger': slotProps.data.status === 0,
              })
                }, _toDisplayString(slotProps.data.status === 1 ? "Activo" : "Inactivo"), 3)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "filters"])
      ])
    ]),
    _createVNode(_component_DialogComponent, {
      visible: _ctx.confirmDialogVisible,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.confirmDialogVisible) = $event)),
      modal: true,
      closable: false,
      style: { width: '30vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, [
          _cache[8] || (_cache[8] = _createTextVNode(" ¿Estás seguro de que deseas eliminar al cliente ")),
          _createElementVNode("strong", null, _toDisplayString(_ctx.selectedClient?.razon_social), 1),
          _cache[9] || (_cache[9] = _createTextVNode("? "))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Button, {
            label: "No",
            icon: "pi pi-times",
            class: "p-button-text",
            onClick: _ctx.cancelDelete
          }, null, 8, ["onClick"]),
          _createVNode(_component_Button, {
            label: "Sí",
            icon: "pi pi-check",
            class: "p-button-danger",
            onClick: _ctx.confirmDelete
          }, null, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_DialogComponent, {
      visible: _ctx.clientInfoDialogVisible,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.clientInfoDialogVisible) = $event)),
      modal: true,
      closable: true,
      style: { width: '50vw' }
    }, {
      default: _withCtx(() => [
        (_ctx.selectedClientData)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[20] || (_cache[20] = _createElementVNode("h4", null, "Información del Cliente", -1)),
              _createElementVNode("p", null, [
                _cache[10] || (_cache[10] = _createElementVNode("strong", null, "uniclave:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.selectedClientData.uniclave), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[11] || (_cache[11] = _createElementVNode("strong", null, "Razón Social:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.selectedClientData.razon_social), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Vendedor:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.selectedClientData.vendedor), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[13] || (_cache[13] = _createElementVNode("strong", null, "Teléfono:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.selectedClientData.telefono), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[14] || (_cache[14] = _createElementVNode("strong", null, "RFC:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.selectedClientData.rfc), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[15] || (_cache[15] = _createElementVNode("strong", null, "Calle 1:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.selectedClientData.calle1), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Número exterior:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.selectedClientData.numero_ext), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[17] || (_cache[17] = _createElementVNode("strong", null, "Colonia:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.selectedClientData.colonia), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[18] || (_cache[18] = _createElementVNode("strong", null, "Municipio:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.selectedClientData.municipio), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[19] || (_cache[19] = _createElementVNode("strong", null, "Estado:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.selectedClientData.estado), 1)
              ])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}