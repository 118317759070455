import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25" }
const _hoisted_3 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_4 = { class: "search mb-3" }
const _hoisted_5 = { class: "table-responsive" }
const _hoisted_6 = { class: "d-flex justify-content-start" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "col-md-12 pt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VueLoading, {
        active: _ctx.isLoading,
        "is-full-page": true,
        loader: "bars",
        text: "Cargando..."
      }, null, 8, ["active"]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "d-sm-flex align-items-center" }, [
        _createElementVNode("h5", { class: "text-center" }, "Listado de partners")
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputText, {
          modelValue: _ctx.searchTerm,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
          placeholder: "Buscar por nombre o email",
          class: "w-100"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_DataTable, {
          value: _ctx.filteredUsers,
          showGridlines: "",
          tableStyle: "min-width: 50rem",
          paginator: "",
          rows: 10,
          rowsPerPageOptions: [5, 10, 20, 50]
        }, {
          footer: _withCtx(() => [
            _createTextVNode(" Total " + _toDisplayString(_ctx.filteredUsers ? _ctx.filteredUsers.length : 0) + " Usuarios. ", 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              class: "column-table",
              header: "Opciones",
              style: {"width":"20%"}
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    icon: "pi pi-pencil",
                    class: "p-button-sm p-button-info me-1",
                    onClick: ($event: any) => (_ctx.promptDelete(slotProps.data))
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "nombre",
              header: "Nombre",
              style: {"width":"30%"}
            }),
            _createVNode(_component_Column, {
              sortable: "",
              class: "column-table",
              field: "rol",
              header: "Rol",
              style: {"width":"30%"}
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _createVNode(_component_DialogComponent, {
        visible: _ctx.confirmDialogVisible,
        "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.confirmDialogVisible) = $event)),
        modal: true,
        closable: false,
        style: { width: '50vw' }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[6] || (_cache[6] = _createElementVNode("label", {
                for: "overviewEmail",
                class: "form-label fw-medium"
              }, "Usuarios:", -1)),
              _createVNode(_component_MultiSelect, {
                modelValue: _ctx.selectedPartner,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedPartner) = $event)),
                  _ctx.updateSelectedPartners
                ],
                options: _ctx.data,
                optionLabel: "nombre",
                optionValue: "uuid",
                placeholder: "Seleccione usuarios",
                filter: true,
                multiple: true,
                class: "w-100"
              }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-sm btn-primary text-center",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveSettings && _ctx.saveSettings(...args)))
              }, " Guardar "),
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-sm btn-secondary text-center ms-2",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
              }, " Cerrar ")
            ])
          ])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}