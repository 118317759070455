<template>
    <div class="row">
        <div class="col-md-12">
            <ListPartnersAdmin/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ListPartnersAdmin from "@/components/Users/ListPartnersAdmin.vue";

export default defineComponent({
    name: "PartnersListPageAdmin", 
    components: {
        ListPartnersAdmin,
    },
});
</script>
