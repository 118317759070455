<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing p-4">
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-25">Registrar Clientes</h5>
      <form class="row">

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Nombre del establecimiento:</label>
          <InputText class="w-100" type="text" v-model="form.nombre_establecimiento" />
        </div>

        <div class="col-md-6 col-md-6 mb-3">
          <div class="input-group has-validation">
            <label for="segmentoDropdown" class="form-label fw-medium text-black">
              Nivel de cliente (*)
            </label>
            <Dropdown v-model="form.uuid_nivel_cliente" :options="nivelesCliente" optionLabel="descripcion"
              optionValue="uuid" placeholder="Seleccione un nivel del cliente" filter class="w-100" />
          </div>
        </div>

        <div class="col-md-6 col-md-6 mb-3">
          <div class="input-group has-validation">
            <label for="segmentoDropdown" class="form-label fw-medium text-black">
              Agente (*)
            </label>
            <Dropdown v-model="form.uuid_vendedor" :options="agentes" optionLabel="nombre_agente_cq" optionValue="uuid"
              placeholder="Seleccione un agente" filter class="w-100" />
          </div>
        </div>


        <div v-show="visible" class="col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Razón social: (*)</label>
          <Dropdown v-model="form.uuidRazon" :options="company" optionLabel="razon_social" optionValue="uuid"
            placeholder="Selecciona un razón social" filter class="w-100" />
        </div>

        <div v-show="visible" class="col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Frecuencia de compra:</label>
          <Dropdown v-model="selectedFrecuen" :options="frecuencias" optionLabel="descripcion" optionValue="uuid"
            placeholder="Selecciona una frecuencia de compra" filter class="w-100" />
        </div>

        <div v-show="visible" class="col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Forma de pago:</label>
          <Dropdown v-model="form.uuid_forma_pago" :options="pagos" optionLabel="nombre" optionValue="uuid"
            placeholder="Selecciona una forma de pago" filter class="w-100" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Correo electrónico:</label>
          <InputText class="w-100" type="text" v-model="form.correo_electronico" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Telefono:</label>
          <InputText class="w-100" type="text" v-model="form.telefono" placeholder="Ingresar telefono" />
        </div>


        <div v-show="visible" class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">RFC:</label>
          <InputText :readonly="!!form.rfc" class="w-100" type="text" v-model="form.rfc" />
        </div>


        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Giro comercial:</label>
          <InputText class="w-100" type="text" v-model="form.giro_comercial" placeholder="Ingresar giro" />
        </div>

        <div class="col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Segmento (*):</label>
          <Dropdown v-model="form.uuid_clasificacion_cliente" :options="tiposCliente" optionLabel="nombre"
            optionValue="uuid" placeholder="Segmento del cliente" filter class="w-100" />
        </div>


        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Nombre de contacto:</label>
          <InputText class="w-100" type="text" v-model="form.contacto_nombre" />
        </div>


        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Teléfono de contacto:</label>
          <InputText class="w-100" type="text" v-model="form.telefono_contacto" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Calle 1:</label>
          <InputText class="w-100" type="text" v-model="form.calle1" />
        </div>


        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Calle 2:</label>
          <InputText class="w-100" type="text" v-model="form.calle2" />
        </div>


        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Número interior:</label>
          <InputText class="w-100 text-black" type="text" v-model="form.numero_int" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Número exterior:</label>
          <InputText class="w-100 text-black" type="text" v-model="form.numero_ext" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Colonia:</label>
          <InputText class="w-100 text-black" type="text" v-model="form.colonia" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Código Postal:</label>
          <InputText class="w-100 text-black" type="text" v-model="form.cp" />
        </div>


        <div class="col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Estado</label>
          <InputText class="w-100 text-black" type="text" v-model="form.estado" />
        </div>

        <div class="col-md-6 mb-3">
          <label for="taskTags" class="form-label fw-medium">Municipio</label>
          <InputText class="w-100 text-black" type="text" v-model="form.municipio" />
        </div>


        <div class="col-md-12 mb-3">
          <button type="button" @click="submitForm()" class="btn btn-primary text-center" :disabled="isSubmitting">
            Guardar
          </button>

          <button type="button" @click="resetForm()" class="btn btn-info text-center ms-10">
            Cerrar
          </button>
        </div>


      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import control from "@/services/mapService";
import { useToast } from "vue-toastification";
import Dropdown from "primevue/dropdown";
import InputText from 'primevue/inputtext';
import Actualizar from '@/services/actualizarService';

export default defineComponent({
  name: "FormMapsUpdate",
  components: {
    // Multiselect,
    Dropdown,
    InputText
  },
  props: {
    locationData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const form = ref({
      nombre_establecimiento: "",
      vendedor: "",
      uuidRazon: "",
      correo_electronico: "",
      telefono: "",
      rfc: "",
      giro_comercial: "",
      contacto_nombre: "",
      telefono_contacto: "",
      longitud: "",
      latitud: "",
      calle1: "",
      calle2: "",
      numero_ext: "",
      numero_int: "",
      colonia: "",
      cp: "",
      estado: "",
      municipio: "",
      uuid_estado: "",
      uuid_clasificacion_cliente: "",
      uuid_nivel_cliente: "",
      uuid_forma_pago: "",
      uuid_frecuencia_compra: "",
      uuid_vendedor: "",
    });

    const isSubmitting = ref(false);
    const tiposCliente = ref([]);
    const nivelesCliente = ref([]);
    const agentes = ref([]);
    const frecuencias = ref([]);
    const states = ref([]);
    const pagos = ref([]);
    const toast = useToast();
    const selectedAgent = ref(null);
    const selectedFrecuen = ref(null);
    const visible = ref(false);
    const company = ref([]);
    const isLoading = ref(false);

    const getStateList = async () => {
      try {
        const rspta = await new control().stateList();  
        if (!rspta.error) {
          states.value = rspta.datos;
        }

      } catch (error) {
        console.error("Error al obtener los estados:", error);
        toast.error("Error al cargar la lista de estados");
      }
    };

    const getTipoCliente = async () => {
      try {
        const response = await new control().ListClasifiClient();
        tiposCliente.value = response.datos;
      } catch (error) {
        console.error("Error al obtener los tipos de cliente:", error);
        toast.error("Error al cargar los tipos de cliente");
      }
    };

    const getLevelCliente = async () => {
      try {
        const response = await new control().ListLevelClient();
        nivelesCliente.value = response.datos;
      } catch (error) {
        console.error("Error al obtener los niveles de cliente:", error);
        toast.error("Error al cargar los niveles de cliente");
      }
    };

    const getAgentList = async () => {
      try {

        const rspta = await new control().agentList();
        if (!rspta.error) {
          agentes.value = rspta.datos;
        }
      } catch (error) {
        console.error("Error al obtener la lista de agentes:", error);
        toast.error("Error al cargar la lista de agentes");
      }
    };


    const getFrecuenciaCompra = async () => {
      try {
        const rspta = await new control().frecuenciaCompra();
        if (!rspta.error) {
          frecuencias.value = rspta.datos;
        }
      } catch (error) {
        console.error("Error al obtener la lista de frecuencias:", error);
        toast.error("Error al cargar la lista de frecuencias");
      }
    };

    const getFormaPago = async () => {
      try {
        const rspta = await new control().formaPagos();
        if (!rspta.error) {
          pagos.value = rspta.datos;
        }
      } catch (error) {
        console.error("Error al obtener la lista de frecuencias:", error);
        toast.error("Error al cargar la forma de pagos");
      }
    }

    const updateSelectedAgent = (selected) => {
      if (selected) {
        form.value.vendedor = selected.nombre_agente_cq;
        form.value.uuid_vendedor = selected.uuid;
      } else {
        form.value.vendedor = "";
        form.value.uuid_vendedor = "";
      }
    };

    const updateSelectedFrecuencia = (selectede) => {
      if (selectede) {
        form.value.uuid_frecuencia_compra = selectede.uuid;
      } else {
        form.value.uuid_frecuencia_compra = "";
      }
    };

    const removeSelectedAgent = () => {
      form.value.vendedor = "";
      form.value.uuid_vendedor = "";
      selectedAgent.value = null;
    };

    const removeSelectedFrecuencia = () => {
      form.value.uuid_frecuencia_compra = "";
      selectedFrecuen.value = null;
    };

    const resetForm = () => {
      Object.keys(form.value).forEach((key) => {
        form.value[key] = "";
      });
      selectedAgent.value = null;
    };


    const getCompanyName = async () => {
      try {
        const rspta = await new Actualizar().ListCompanyName();
        if (!rspta.error && Array.isArray(rspta.response)) {
          company.value = rspta.response;
        } else {
          console.error("La respuesta no contiene datos válidos:", rspta);
          company.value = [];
        }
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      }
    };


    const submitForm = async () => {
      if (isSubmitting.value) return;
      isSubmitting.value = true;
      console.log(form.value);
      try {
        const response = await new control().registerClient(form.value);
        if (!response.error) {
          toast.success("Cliente registrado correctamente");
          resetForm();
        } else {
          toast.warning("El registro no se ha realizado correctamente");
        }
      } catch (error) {
        toast.error("Error al registrar el cliente");
      } finally {
        isSubmitting.value = false;
      }
    };

    watch(
      () => props.locationData,
      (newValue) => {
        if (newValue && Object.keys(newValue).length > 0) {
          Object.keys(newValue).forEach((key) => {
            if (key in form.value && newValue[key] !== undefined) {
              form.value[key] = newValue[key];
            }
          });
        }
      },
      { deep: true }
    );

    watch(
      () => form.value.estado,
      (newState) => {
        const foundState = states.value.find(
          (state) => state.nombre === newState
        );
        form.value.uuid_estado = foundState ? foundState.uuid : "";
      }
    );


    watch(
      () => form.value.uuid_nivel_cliente,
      (newValue) => {
        if (!newValue) {
          return;
        }

        const visibleUUIDs = [
          '48aaf084-1730-49e4-a628-2a7bb056c2d5',
          'd2f9f31a-d665-40a6-b02b-e8b60aa2c3a9',
        ];

        const hiddenUUIDs = ['6ed7a912-cf0b-4872-ad1c-1fa7b9d36ce7'];

        if (visibleUUIDs.includes(newValue)) {
          visible.value = true;
        } else if (hiddenUUIDs.includes(newValue)) {
          visible.value = false;
        }
      }
    );

    watch(
      () => form.value.uuid_nivel_cliente,
      (newValue) => {
        if (newValue) {
          const selectedCompany = company.value.find(
            (item) => item.uuid === newValue
          );


          if (selectedCompany) {
            form.value.rfc = selectedCompany.rfc;
          } else {
            form.value.rfc = "";
          }
        } else {
          form.value.rfc = "";
        }
      }
    );



    onMounted(async () => {
      try {
        isLoading.value = true;
        await getStateList();
        await getTipoCliente();
        await getLevelCliente();
        await getAgentList();
        await getFrecuenciaCompra();
        await getFormaPago();
        await getCompanyName();
      } catch (error) {
        console.log(error)
      } finally {
        isLoading.value = false
      }

    });


    return {
      form,
      submitForm,
      tiposCliente,
      pagos,
      nivelesCliente,
      agentes,
      isSubmitting,
      selectedAgent,
      selectedFrecuen,
      updateSelectedAgent,
      updateSelectedFrecuencia,
      removeSelectedAgent,
      removeSelectedFrecuencia,
      frecuencias,
      visible,
      company,
      isLoading,
      resetForm
    };
  },
});
</script>
