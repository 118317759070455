import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-15 p-lg-30" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "pt-10 mb-3" }
const _hoisted_5 = { class: "input-group w-100" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "pt-10 rounded-md" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = { class: "mb-3" }
const _hoisted_12 = { class: "pt-10 mb-3" }
const _hoisted_13 = { class: "input-group w-100" }
const _hoisted_14 = { class: "pt-10 mb-3" }
const _hoisted_15 = { class: "input-group w-100" }
const _hoisted_16 = {
  key: 0,
  class: "mb-3 col-md-4"
}
const _hoisted_17 = {
  key: 1,
  class: "mb-3 col-md-4"
}
const _hoisted_18 = {
  key: 2,
  class: "mb-3 col-md-4"
}
const _hoisted_19 = {
  key: 3,
  class: "pt-10 mb-3"
}
const _hoisted_20 = { class: "input-group w-100" }
const _hoisted_21 = {
  key: 4,
  class: "mb-3"
}
const _hoisted_22 = {
  key: 5,
  class: "mb-3"
}
const _hoisted_23 = { class: "mb-3" }
const _hoisted_24 = { class: "d-flex justify-content-end mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_userDropdown = _resolveComponent("userDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[17] || (_cache[17] = _createElementVNode("label", {
              for: "vehiculoDropdown",
              class: "form-label fw-medium text-black"
            }, " Selecciona un vehículo: ", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.tipovehiculos,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tipovehiculos) = $event)),
              options: _ctx.vehiculos,
              optionLabel: "numero",
              optionValue: "uuid",
              placeholder: "Seleccione un número de vehículo",
              filter: "",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[18] || (_cache[18] = _createElementVNode("label", {
            for: "fechaInicio",
            class: "form-label fw-medium"
          }, "Fecha Inicio:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "datetime-local",
            class: "form-control",
            id: "fechaInicio",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fechaInicio) = $event))
          }, null, 512), [
            [_vModelText, _ctx.fechaInicio]
          ])
        ]),
        _createVNode(_component_userDropdown, {
          modelValue: _ctx.selectedUser,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedUser) = $event)),
          userOptions: _ctx.user
        }, null, 8, ["modelValue", "userOptions"]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[19] || (_cache[19] = _createElementVNode("label", {
              for: "clienteDropdown",
              class: "form-label fw-medium"
            }, "Selecciona un cliente:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedClient,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedClient) = $event)),
              filter: true,
              options: _ctx.clientes,
              optionLabel: "razon_social",
              optionValue: "uuid",
              placeholder: "Seleccione un cliente",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[20] || (_cache[20] = _createElementVNode("label", {
            for: "ncliente",
            class: "form-label fw-medium"
          }, "Cliente:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "ncliente",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.nclientes) = $event)),
            placeholder: "Escriba un cliente"
          }, null, 512), [
            [_vModelText, _ctx.nclientes]
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[21] || (_cache[21] = _createElementVNode("label", {
            for: "localidad",
            class: "form-label fw-medium"
          }, "Localidad:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "localidad",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localidad) = $event)),
            placeholder: "Ingrese la localidad"
          }, null, 512), [
            [_vModelText, _ctx.localidad]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[22] || (_cache[22] = _createElementVNode("label", {
            for: "giro",
            class: "form-label fw-medium"
          }, "Giro:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "giro",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.giro) = $event)),
            placeholder: "Ingrese un giro de la empresa"
          }, null, 512), [
            [_vModelText, _ctx.giro]
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _cache[23] || (_cache[23] = _createElementVNode("label", {
              for: "tipoActividadDropdown",
              class: "form-label fw-medium text-black"
            }, " Selecciona tipo de actividad: ", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.tipoactividad,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.tipoactividad) = $event)),
              options: _ctx.actividades,
              optionLabel: "actividad",
              optionValue: "uuid",
              placeholder: "Seleccione una actividad",
              filter: "",
              class: "w-100",
              onChange: _ctx.updateDynamicFields
            }, null, 8, ["modelValue", "options", "onChange"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[24] || (_cache[24] = _createElementVNode("label", {
              for: "segmentoDropdown",
              class: "form-label fw-medium text-black"
            }, " Selecciona un segmento: ", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.tiposegmento,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.tiposegmento) = $event)),
              options: _ctx.segmentos,
              optionLabel: "segmento",
              optionValue: "uuid",
              placeholder: "Seleccione un segmento",
              filter: "",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        (_ctx.dynamicFields.includes('N° de Seguimiento'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _cache[25] || (_cache[25] = _createElementVNode("label", {
                for: "seguimiento",
                class: "form-label fw-medium"
              }, "N° de Seguimiento:", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                id: "seguimiento",
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.seguimiento) = $event)),
                placeholder: "Ingrese el número de seguimiento"
              }, null, 512), [
                [_vModelText, _ctx.seguimiento]
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.dynamicFields.includes('Cotizado Lts'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _cache[26] || (_cache[26] = _createElementVNode("label", {
                for: "cotizadoLts",
                class: "form-label fw-medium"
              }, "Cotizado Lts:", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                id: "cotizadoLts",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.cotizadoLts) = $event)),
                placeholder: "Ingrese lo cotizado en litros"
              }, null, 512), [
                [_vModelText, _ctx.cotizadoLts]
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.dynamicFields.includes('Venta Lts'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _cache[27] || (_cache[27] = _createElementVNode("label", {
                for: "ventaLts",
                class: "form-label fw-medium"
              }, "Venta Lts:", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                id: "ventaLts",
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.ventaLts) = $event)),
                placeholder: "Ingrese lo vendido en litros"
              }, null, 512), [
                [_vModelText, _ctx.ventaLts]
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.dynamicFields.includes('Tipo Compra'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _cache[28] || (_cache[28] = _createElementVNode("label", {
                  for: "tipoCompraDropdown",
                  class: "form-label fw-medium text-black"
                }, " Tipo de compra: ", -1)),
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.tipocompras,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.tipocompras) = $event)),
                  options: _ctx.compras,
                  optionLabel: "nombre",
                  optionValue: "uuid",
                  placeholder: "Selecciona un tipo de compra",
                  filter: "",
                  class: "w-100"
                }, null, 8, ["modelValue", "options"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.dynamicFields.includes('Cobranza'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _cache[29] || (_cache[29] = _createElementVNode("label", {
                for: "cobranza",
                class: "form-label fw-medium"
              }, "Cobranza:", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                id: "cobranza",
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.cobranza) = $event)),
                placeholder: "Ingrese la cobranza"
              }, null, 512), [
                [_vModelText, _ctx.cobranza]
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.dynamicFields.includes('Souvenir'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _cache[30] || (_cache[30] = _createElementVNode("label", {
                for: "souvenir",
                class: "form-label fw-medium"
              }, "Souvenir/Promoción:", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                id: "souvenir",
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.souvenir) = $event)),
                placeholder: "Ingrese algún souvenir"
              }, null, 512), [
                [_vModelText, _ctx.souvenir]
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_23, [
          _cache[31] || (_cache[31] = _createElementVNode("label", {
            for: "observacion",
            class: "form-label fw-medium"
          }, "Observación:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "observacion",
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.observacion) = $event)),
            placeholder: "Añade alguna observación"
          }, null, 512), [
            [_vModelText, _ctx.observacion]
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("button", {
            class: "btn btn-primary",
            onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
          }, "Enviar")
        ])
      ])
    ])
  ]))
}