<template>
    <div class="row">
        <div class="col-md-12">
            <ListPartners/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ListPartners from "@/components/Users/ListPartners.vue";

export default defineComponent({
    name: "PartnersListPage", 
    components: {
        ListPartners,
    },
});
</script>
