<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div
            class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
            <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
            <div class="d-sm-flex align-items-center">
                <h5 class="text-center">Listado de partners</h5>
            </div>
        </div>
        <div class="card-body p-15 p-sm-20 p-md-25">
            <div class="search mb-3">
                <InputText v-model="searchTerm" placeholder="Buscar por nombre o email" class="w-100" />
            </div>

            <div class="table-responsive">
                <DataTable :value="filteredUsers" showGridlines tableStyle="min-width: 50rem" paginator :rows="10"
                    :rowsPerPageOptions="[5, 10, 20, 50]">
                    <Column class="column-table" header="Opciones" style="width: 20%">
                        <template #body="slotProps">
                            <div class="d-flex justify-content-start">
                                <Button icon="pi pi-pencil" class="p-button-sm p-button-info me-1"
                                    @click="promptDelete(slotProps.data)" />
                            </div>
                        </template>
                    </Column>
                    <Column class="column-table" field="nombre" header="Nombre" style="width: 30%"></Column>
                    <Column sortable class="column-table" field="rol" header="Rol" style="width: 30%"></Column>
                    <template #footer>
                        Total {{ filteredUsers ? filteredUsers.length : 0 }} Usuarios.

                    </template>

                </DataTable>
            </div>
            <DialogComponent v-model:visible="confirmDialogVisible" :modal="true" :closable="false"
                :style="{ width: '50vw' }">
                <div class="row">
                    <div class="mb-3">
                        <label for="overviewEmail" class="form-label fw-medium">Usuarios:</label>
                        <MultiSelect v-model="selectedPartner" :options="data" optionLabel="nombre" optionValue="uuid"
                            placeholder="Seleccione usuarios" :filter="true" :multiple="true"
                            @update:modelValue="updateSelectedPartners" class="w-100" />
                    </div>

                    <div class="col-md-12 pt-20">
                        <button type="button" class="btn btn-sm btn-primary text-center" @click="saveSettings">
                            Guardar
                        </button>
                        <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">
                            Cerrar
                        </button>
                    </div>
                </div>
            </DialogComponent>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { useToast } from "vue-toastification";
import User from "@/services/userService";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import VueLoading from "vue-loading-overlay";
import Button from "primevue/button";
import { UsuariosItem } from "@/interfaces/interfaces";

export default defineComponent({
    name: "ListadoUsuarios",
    components: {
        DataTable,
        Column,
        InputText,
        VueLoading,
        Button,
    },
    setup() {
        const isLoading = ref(true);
        const toast = useToast();
        const usuariosData = ref<UsuariosItem[]>([]);
        const searchTerm = ref("");
        const confirmDialogVisible = ref(false);
        const activateDialogVisible = ref(false);
        const selectedUser = ref<UsuariosItem | null>(null);
        const selectedUiid = ref<UsuariosItem | null>(null);
        const selectedPartner = ref("");
        const data = ref<any[]>([]);

        const getUsuariosData = async () => {
            try {
                isLoading.value = true;
                const response = await new User().partnerList();
                if (!response.error) {
                    usuariosData.value = response.datos;
                }
            } catch (error) {
                toast.error("Ocurrió un error al obtener la lista de usuarios");
            } finally {
                isLoading.value = false;
            }
        };

        const getSelectedAgents = async () => {
            try {

                const data = {
                    uRol: selectedUiid.value,
                    key: selectedUser.value,
                };

                const rspta = await new User().listUserPartnert(data);

                if (!rspta.error) {
                    selectedPartner.value = rspta.response.map((partner: any) => partner.uusuario);
                }
            } catch (error) {
                console.log(error)
            }
        }

        const promptDelete = async (user) => {
            selectedUser.value = user.uuid_usuario;
            selectedUiid.value = user.uuid;
            await getSelectedAgents();
            await getAgents();
            confirmDialogVisible.value = true;
        };

        const cancelDelete = () => {
            selectedUser.value = null;
            confirmDialogVisible.value = false;
        };

        const getAgents = async () => {
            try {
                const response = await new User().agentUserList();
                if (!response.error) {
                    data.value = response.datos
                }
            } catch (error) {
                console.log(error)
            }
        }


        const updateSelectedPartners = (newPartherns) => {
            selectedPartner.value = newPartherns;
            console.log(selectedPartner.value)
        };


        const saveSettings = async () => {
            if (!selectedPartner.value || selectedPartner.value.length === 0) {
                toast.warning('Por favor seleccione al menos una opción antes de guardar.');
                return;
            }

            const response = await new User().saveUserAgentRelationship(selectedPartner.value, selectedUser.value);

            if (!response.error) {
                toast.success("Datos guardados correctamente");
                confirmDialogVisible.value = false;
            } else {
                toast.error("No se puedo guardar correctamente")
            }
        };

        const closeModal = () => {
            confirmDialogVisible.value = false;
        };


        const filteredUsers = computed(() => {
            if (!searchTerm.value) {
                return usuariosData.value;
            }

            return usuariosData.value.filter((user) => {
                const nombreMatch = user.nombre
                    ? user.nombre.toLowerCase().includes(searchTerm.value.toLowerCase())
                    : false;
                const emailMatch = user.email
                    ? user.email.toLowerCase().includes(searchTerm.value.toLowerCase())
                    : false;

                return nombreMatch || emailMatch;
            });
        });

        onMounted(async () => {
            await getUsuariosData();
        });

        return {
            isLoading,
            searchTerm,
            filteredUsers,
            promptDelete,
            confirmDialogVisible,
            cancelDelete,
            activateDialogVisible,
            selectedUser,
            updateSelectedPartners,
            selectedPartner,
            data,
            saveSettings,
            selectedUiid,
            closeModal
        };
    },
});
</script>

<style>
.text-success {
    color: green;
}

.text-danger {
    color: red;
}

.form-switch {
    margin-top: 5px;
}

.dark .search input {
    background-color: #34334a;
    color: #fff;
}

.dark .column-table {
    background-color: #34334a;
    color: #fff;
}

.dark .p-datatable-footer {
    background-color: #34334a;
    color: #fff;
}

.dark .p-paginator {
    background-color: #34334a;
    color: #fff;
}
</style>